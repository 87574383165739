import { useState, useEffect } from 'react';
import { ethers } from 'ethers';

const useProvider = () => {
	const [provider, setProvider] = useState( null );
  
	useEffect( () => {
		// Run this effect only on the client-side
		async function initProvider() {
			try {
				if ( window.ethereum ) {
					const providerInstance = new ethers.BrowserProvider( window.ethereum );
					setProvider( providerInstance );
				} else {
					console.log( 'Please install MetaMask or another Ethereum wallet to interact with this app.' );
				}
			} catch ( error ) {
				console.error( 'Error initializing provider:', error );
			}
		}
        
		if ( typeof window !== 'undefined' ) {
			initProvider();
		}
	}, []);
  
	return provider;
};

  
export default useProvider;
