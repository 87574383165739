import React, { useEffect, useState } from 'react';
import styles from './InfoCard.module.scss';

interface Cta {
	text: string;
	link: string;
}

interface InfoCardProps {
	paragraph: string;
	subParagraph?: string;
	title?: string;
	titleFade?: boolean;
	cta: Cta;
	onCtaClick?: (id: string) => void;
	id: string;
	isCtaContained?: boolean;
	isParagraphBold?: boolean;
	status?: string;
}

export default function InfoCard({
	paragraph,
	subParagraph,
	title,
	titleFade,
	cta: { text },
	onCtaClick,
	id,
	isCtaContained,
	isParagraphBold,
	status,
}: InfoCardProps): JSX.Element {
	const [show, setShow] = useState(true);
	const hideCardsWithStatusOf = ['archived'];

	function evaluateShow() {
		if (hideCardsWithStatusOf.includes(status?.toLowerCase() || '')) {
			setShow(false);
		}
	}

	useEffect(() => {
		evaluateShow();
	}, []);

	return (
		<>
			{show && (
				<div className={styles['info-card']}>
					{title && (
						<div className={`${styles['info-card-title']} ${titleFade ? styles['info-card-title-fade'] : ''}`}>
							<div className={styles['info-card-title-circle']} />
							{title}
						</div>
					)}
					<div className={styles['info-card-lower-section']}>
						<div className={styles['info-card-text']}>
							<p className={`${styles['info-card-paragraph']} ${isParagraphBold ? styles['info-card-paragraph-bold'] : ''}`}>{paragraph}</p>
							<p className={styles['info-card-sub-paragraph']}>{subParagraph && subParagraph}</p>
						</div>
						{/* NOTE: we are temporarily excluding the Dismiss button for now */}
						<button
							onClick={() => onCtaClick && onCtaClick(id)}
							className={`${isCtaContained ? styles['info-card-cta-contained'] : ''} ${styles['info-card-cta']}`}
						>
							{text}
						</button>
					</div>
				</div>
			)}
		</>
	);
}
