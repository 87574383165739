import styles from './YourCollection.module.scss';
import commonDashBoardStyles from '../../dashboard-common.module.scss';
import useYourCollectionData from './useYourCollectionData';
import { YourCollectionCard } from 'src/ui/components';
import FolderIcon from 'src/icons/FolderIcon';
import LoadingOverlay from 'src/ui/components/LoadingOverlay';
import { useStore } from 'base/state';
import { useEffect, useState } from 'react';

export default function YourCollection() {
	const api = useStore( s => s.api );
	const [heightUpdated, setHeightUpdated] = useState( false );
	const { genesis, keys, odyssey, z1, zombies, apepeLoot, nextPage, loading } = useYourCollectionData();
	const collections = [{
		title: 'Rare Apepes',
		items: genesis,
	},
	{
		title: 'Zombie Apepes',
		items: zombies,
	}
	, {
		title: 'Apepe Odyssey',
		items: odyssey,
	},
	{
		title: 'Odyssey Keys',
		items: keys,
	},
	{
		title: 'Z1 Serum',
		items: z1,
	}, {
		title: 'Apepe Loot',
		items: apepeLoot,
	}];

	// start of hack to ensure page is not cut off
	useEffect( () => {
		if ( !loading && !heightUpdated ) {
			const mainElement = document.getElementById( 'main-content' );
			api.setViewport( null, mainElement.clientHeight );
			setHeightUpdated( true );
		}
	}, [loading, heightUpdated]);
	// end of hack to ensure page is not cut off

	const NFTCards = ({ title, cards }) => {
		return <div className={styles['your-collection-section']}>
			<h3 className={styles['your-collection-section-title']}>
				<span className={styles['your-collection-section-title-icon']}><FolderIcon /></span>
				{title}
				{' '}
			</h3>
			<div className={styles['your-collection-section-cards-container']}>
				{cards.map( ( nftMetadata, index ) => <YourCollectionCard key={index}
					{...nftMetadata} /> )}
			</div>
		</div>;
	};

	const HeaderItem = ({ title, collection, className }) => {
		return <h3 className={`${styles['your-collection-section-title']} ${className}`}>
			{title}
			{' '}
			:
			{` ${collection?.length ?? 0}`}

		</h3>;
	};

	return (
		<div className={styles['your-collection']}>

			<h2 className={commonDashBoardStyles['tab-title']}>Your Collection</h2>
			<div className='flex flex-wrap justify-between gap-y-3.5 max-[900px]:flex-col  max-[900px]:gap-y-7 max-[500px]:gap-y-2 max-[900px]:my-4'>
				{collections.map( ( collection ) => <h5 className='text-xl m-0 w-1/2 text-white bg-transparent max-[900px]:w-full max-[900px]:text-2xl'>
					{collection.title}
					{console.log( 'collection', collection )}
					:
					{` ${collection.items.length ?? 0}`}
				</h5> )
				}
			</div>

			<LoadingOverlay show={loading} />
			{collections.map( ( collection ) => <NFTCards title={collection.title}
				cards={collection.items} /> )}
		</div>
	);
}
