import { LORE_PIECE_STATUSES } from 'src/base/constants';

export function ensureMinimumEightPieces( transformedItems ) {
	const currentLength = transformedItems.length;
	if ( currentLength < 8 ) {
		const emptyPiece = {
			status: LORE_PIECE_STATUSES.PLACEHOLDER,
			image: '/images/odyssey-album-img_empty.png',
			name: '??',
		};
		return transformedItems.concat( Array( 8 - currentLength ).fill( emptyPiece ) );
	}
	return transformedItems;
}
