
//	Dependencies
import PropTypes from 'prop-types';
import { RecoilRoot } from 'recoil';
import Draggable, { DraggableCore } from 'react-draggable';
import React from 'react';

//	App
import { Layout as DefaultLayout } from 'ui/app';
import { Modal } from 'src/ui/components';


// import {
// 	ThirdwebProvider,
// 	metamaskWallet,
// 	walletConnect,
// } from '@thirdweb-dev/react';

import { ThirdwebProvider } from 'thirdweb/react';



//	Styles
import 'styles/index.scss';


export default function App({ Component, pageProps }) {
	const Layout = Component.Layout ?? DefaultLayout;
	const CanvasComponent = Component.CanvasComponent;


	return (
		<ThirdwebProvider>
			<RecoilRoot>
				<Layout pageProps={pageProps}
					canvasChildren={CanvasComponent ? <CanvasComponent /> : null}>

					<Component {...pageProps} />
				</Layout>
			</RecoilRoot>
		</ThirdwebProvider>
	);
}


App.propTypes = {
	Component: PropTypes.func,
	pageProps: PropTypes.object,
};
