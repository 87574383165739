import axios from 'axios';
import { API_BASE_URI_DEV, API_BASE_URI_LOCAL, API_BASE_URI_PROD, NETWORKS } from 'src/base/constants';

export async function fetchPublishedPieces( network = NETWORKS.ETH.name, chapterId ) {
	let baseUri = String( network ).trim() === 'ethereum' ? API_BASE_URI_PROD : API_BASE_URI_DEV;
	if ( window?.location?.hostname === 'localhost' ) {
		baseUri = API_BASE_URI_LOCAL;
	}
	const paramObj = { network, chapterId };

	const url = `${baseUri}/nfts/odyssey/tokens`;


	try { 
		const response = await axios.get( url, { params: paramObj });
		return response.data;
	} catch ( error ) {
		console.error( 'fetchPublishedPieces: ', error );
		return null;
	}
}
