// transform from loreBalances to full data
// full data contains 'userOwns' property which is used on Album Page and Piece Page
export function transformLoreBalances( piecesData, loreBalances ) {
	const items = piecesData;
	const transformedItems = items.map( ( item ) => ({ ...item }) ) ;
	loreBalances.forEach( ( loreBalance ) => {
		// find the corresponding piece in the balances array
		const targetItem = transformedItems.find( ( item ) => item.id === loreBalance.tokenId );
		if ( targetItem ) {
			targetItem.userOwns = Number( loreBalance.balance );
		}
	});
	return transformedItems;
}
