
//	Dependencies
import { useState, useRef, useEffect, useCallback } from 'react';


//
//	RAYC / UI / App / Hooks / Intersection
//


export default function useIntersection( props = {}) {
	const { root = null, rootMargin, threshold = 1 } = props;
	const [ entry, updateEntry ] = useState({});
	const [ node, setNode ] = useState( null );
	const io = useRef( null );

	const setRef = useCallback( el => {
		setNode( el );
	}, [ setNode ]);

	useEffect( () => {
		if ( io.current ) io.current.disconnect();
		io.current = new window.IntersectionObserver(
			([ entry ]) => {
				updateEntry( entry );
			},
			{ root, rootMargin, threshold },
		);
		if ( node ) io.current.observe( node );
		return () => io.current.disconnect();
	}, [ node, root, rootMargin, threshold ]);

	return [ setRef, entry, node ];
}

