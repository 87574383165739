import BaseModalStyles from './modal.module.scss';
import DelegateModalStyle from './DelegateModal.module.scss';
import clsx from 'clsx';
import Link from 'next/link';

export default function DelegateCashModal() {
	const Cta = ({ variation }) => {
		return (
			<Link href="https://delegate.cash">
				<span className={clsx( DelegateModalStyle['delegate-modal-cta'], DelegateModalStyle[`delegate-modal-cta-${variation}`])}>
          Go to delegate.cash
				</span>
			</Link>
		);
	};

	return (
		<div className={BaseModalStyles['modal-content-main']}>
			<div
				className={clsx(
					'-f-family-burbank',
					BaseModalStyles['modal-content-main-header'],
					DelegateModalStyle['delegate-modal-title'],
				)}
			>
        DELEGATE.CASH
			</div>

			<div className={DelegateModalStyle['delegate-modal-info-section']}>
				<div className={BaseModalStyles['modal-content-main-side']}>
					<p
						className={clsx(
							DelegateModalStyle['delegate-modal-paragraph'],
							BaseModalStyles['modal-content-main-paragraph'],
						)}
					>
            Delegate.Cash is a security solution that allows web3 users to
            minimize risk and protect valuable assets from bad links and
            malicious actors.
					</p>
					<Cta variation={'desktop'}/>
				</div>

				<div className={BaseModalStyles['modal-content-main-side']}>
					<p
						className={clsx(
							DelegateModalStyle['delegate-modal-paragraph'],
							BaseModalStyles['modal-content-main-paragraph'],
						)}
					>
            1. Go to delegate.cash and connect with your cold wallet.
					</p>
					<p
						className={clsx(
							DelegateModalStyle['delegate-modal-paragraph'],
							BaseModalStyles['modal-content-main-paragraph'],
						)}
					>
            2. Delegate either your whole wallet, or at the collection level, or
            at the token level.
					</p>
					<p
						className={clsx(
							DelegateModalStyle['delegate-modal-paragraph'],
							BaseModalStyles['modal-content-main-paragraph'],
						)}
					>
            3. Click on the address in the top right to select your delegated
            wallet (it will have the delegate.cash logo)
					</p>
					<Cta variation={'mobile'}/>
				</div>
			</div>
		</div>
	);
}
