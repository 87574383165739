import React, { useState, useEffect } from 'react';
import styles from './Home.module.scss';
import {
	Image,
	InfoCard as AnnouncementCard,
	DashboardCard,
	CtaBar,
	Link,
} from 'src/ui/components';
import { announcementCards, doYourStuffCards } from './data';

// icons
import SpeakerIcon from 'src/icons/SpeakerIcon';
import FireIcon from 'src/icons/FireIcon';
import useWallet from 'src/hooks/useWallet';
import { useRecoilState } from 'recoil';
import dimissedAnnouncementsIdsAtom from 'src/state/dimissedAnnouncementsIdsAtom';


const cardsWithoutEvaluateShow = doYourStuffCards.filter( x => x.evaluateShow === undefined );

export default function Home() {
	const [, address] = useWallet();
	const [announcementCardsToDisplay, setAnnouncementCardsToDisplay] = useState( announcementCards );
	const [dismissedAnnouncementIds, setDismissedAnnouncementIds] = useRecoilState( dimissedAnnouncementsIdsAtom );


	// TODO: get from (mock) API instead
	// filter to non-dismissed and active announcements
	// trello card: https://github.com/kountervailor/rareapepes.com/pull/51/files
	// const [announcementCards, setAnnouncementCards] = useState(
	// 	announcementCards
	// );


	const onDismissAnnouncementCardClick = ( id ) => {
		setDismissedAnnouncementIds([...dismissedAnnouncementIds, id]);
	};

	function filterAnnouncements() {
		removeDimissesAnnouncements();
	}

	function removeDimissesAnnouncements() {
		setAnnouncementCardsToDisplay( announcementCardsToDisplay.filter( ( card ) => !dismissedAnnouncementIds.includes( card.id ) ) );
	}

	useEffect( () => {
		filterAnnouncements();
	}, [dismissedAnnouncementIds]);


	// this is used to hide or unhide announcements section  as all remaining cards might archieved
	const hasNonArchivedItem = announcementCardsToDisplay.some( item => item.status !== 'Archieved' );

	return (
		<div className="w-full h-full">
			<div className="w-full">
				<Image
					key="apepe"
					alt="apepe"
					src="/images/ApepeDashboardDefaultImg.png"
					width={1025}
					height={161}
				/>
			</div>
			{hasNonArchivedItem && <div className='w-full h-auto mt-7'>
				<h1 className={`-f-family-burbank flex items-center font-bold uppercase text-lg leading-none m-0 mb-6 ${styles['home-tab-header']}`}>
					<span className={`mr-2.5 ${styles['home-tab-header-icon']}`}>
						<SpeakerIcon />
					</span>
					announcements
				</h1>
				{announcementCardsToDisplay?.map( ( card ) => (
					<AnnouncementCard
						key={card.id}
						{...card}
						title={card.date}
						titleFade={true}
						// TODO: re-enable below, the state should be saved locally
						onCtaClick={onDismissAnnouncementCardClick}
						isCtaContained={false}
					/>
				) )}
			</div>}
			<div className='w-full h-auto mt-8 mb-8'>
				<h1 className={`-f-family-burbank -f-family-burbank flex items-center font-bold uppercase text-lg leading-none m-0 mb-6 ${styles['home-tab-header']}`}>
					<span className={`mr-2.5 ${styles['home-tab-header-icon']}`}>
						<FireIcon />
					</span>
					DO STUFF WITH YOUR APEPE
				</h1>
				<div className={`flex gap-x-[30px] flex-wrap ${styles['home-tab-do-your-stuff-cards']}`}>
					{/* note: the filter is because when feature flagging, the item is null */}
					{doYourStuffCards?.filter( x => x ).map( ( card, index ) => {
						return <DashboardCard
							key={index}
							className={styles['home-tab-do-your-stuff-cards-item']}
							address={address}
							{...card}
						/>;

					})}
				</div>
			</div>
			<div className="w-full h-auto">
				<h1 className={`-f-family-burbank flex items-center font-bold uppercase text-lg leading-none m-0 mb-6  ${styles['home-tab-header']}`}>
					Quick Safety tip
				</h1>
				<CtaBar className={`max-[1000px]:flex-col max-[1000px]:items-start max-[1000px]:h-auto ${styles['home-tab-safety-tips-cta-bar']}`}>
					<div className='max-[600px]:w-auto w-1/4 flex items-center'>
						<Image
							src={'/images/delegateCashShieldTransparent.png'}
							width={66}
							height={54}
						/>
						<h3
							className='-f-family-burbank font-bold ml-3 text-2xl leading-none'
						>
							DELEGATE
							<br />
							.CASH
						</h3>
					</div>
					<div className={styles['home-tab-safety-tips-cta-bar-middle']}>
						Delegate.Cash is a security solution that allows web3 users to
						minimize risk and protect valuable assets from bad links and
						malicious actors. We have integrated delegate.cash into our website
					</div>
					<div className={styles['home-tab-safety-tips-cta-bar-right']}>
						<Link
							target="_blank"
							href="https://delegate.cash/"
							style={{ cursor: 'pointer' }}
							className={`max-[600px]:w-auto p-0 pt-1 pb-1 pr-2.5 pl-2.5 font-normal rounded-3xl ${styles['home-tab-safety-tips-cta-bar-right-button']}`}
						>
							Go to delegate.cash
						</Link>
					</div>
				</CtaBar>
			</div>
		</div>
	);
}
