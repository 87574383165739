import React from 'react';
import { Image } from 'src/ui/components';
import { councils, Council } from './data';
import CouncilCard from './CouncilCard';


export default function MeetTheCouncil() {
	return (
		<div className="relative w-full h-full py-10">
			{/* <div className="w-full">
        <Image
          key="apepe"
          alt="apepe"
          src="/images/apepe_council.png"
          width={600}
          height={600}
        />
      </div> */}
			<div className="z-[1] w-full h-full absolute top-0 left-0 bg-cover bg-center bg-[url('/images/apepe_council.png')]"></div>
			<div className="z-[2] w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.75)]"></div>

			<div className='relative z-[3] p-2 px-8'>
				<h1 className='m-0 p-0 text-[50px] max-[1200px]:text-[30px] max-[800px]:text-[25px]'>Meet The Inaugural Apepe Community Council!</h1>

				<p className='mt-4 mb-8 text-[20px] max-[800px]:text-[18px] max-[500px]:text-[15px]'>Imagine if the United Nations was run by frogs, and you're halfway there. These six council members handpicked from the wildest corners of our beloved pond, are here to help leap us into the future. They embody the collective community spirit & help to amplify the voice of the pond and help act as guardians of the Rare Apepes legacy, armed with the power to provide feedback, bring forth innovative ideas like mad scientists, and influence decisions with the wisdom of a thousand web3 sages. This initiative is our love letter to the community-centric progress we're all hopping mad about.
				</p>

				<div className='flex gap-y-12 flex-wrap justify-between'>
					{councils.map((council: Council, index: number) => (
						<CouncilCard
							key={index}
							imageUrl={council.imageSrc}
							bio={council.paragraph}
							name={council.name}
							headline={council.headline}
							onLearnMoreClick={() => window.open(council.cta.link, '_blank')}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
