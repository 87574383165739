import { useEffect, useState } from 'react';
import useCheckDelegations from 'src/hooks/useCheckDelegations';
import useWallet from 'src/hooks/useWallet';
import useUserCollections from 'src/hooks/useUserCollections';
import { CONTRACT_NAMES } from 'src/base/constants';

const useYourCollectionData = () => {
	const [connect, address] = useWallet();
	const [zombieApepe, setZombieApepe] = useState([]);
	const [rareApepe, setRareApepe] = useState([]);
	const [otherApepe, setOtherApepe] = useState([]);
	const [zombieApepePage, setZombieApepePage] = useState( 1 );
	const [rareApepePage, setRareApepePage] = useState( 1 );
	const [otherApepePage, setOtherApepePage] = useState( 1 );
	const pageLimit = 4;

	// TODO: make DRY since this block is used in usePieceSetup too
	// start of active address check. couldn't use useActiveAddress as it was buggy //
	const [activeAddress, setActiveAddress] = useState( null );
	const { selectedVault } = useCheckDelegations( address );
	useEffect( () => {
		if ( address === null ) return;
		if ( selectedVault !== null && selectedVault ) setActiveAddress( selectedVault );
		else setActiveAddress( address );
	}, [address, selectedVault]);
	// end of active address check //

	const collectionsBalances = useUserCollections( activeAddress );



	//   todo replace constant lore address with actual one
	const loreAddress = '0x31d45de84fde2fb36575085e05754a4932dd5170';

	// TODO: re-enable paging
	// const getZombieApepe = () =>
	// 	Array( pageLimit * zombieApepePage ).fill( apepes.zombie );
	// const getRareApepe = () =>
	// 	Array( pageLimit * rareApepePage ).fill( apepes.rare );
	// const getOtherApepe = () =>
	// 	Array( pageLimit * otherApepePage ).fill( apepes.other );

	function nextPage( key ) {
		switch ( key ) {
			case 'zombie':
				setZombieApepePage( ( page ) => page + 1 );
				break;
			case 'rare':
				setRareApepePage( ( page ) => page + 1 );
				break;
			case 'other':
				setOtherApepePage( ( page ) => page + 1 );
				break;
		}
	}

	// useEffect( () => {
	// 	setZombieApepe( getZombieApepe() );
	// 	setRareApepe( getRareApepe() );
	// 	setOtherApepe( getOtherApepe() );
	// }, [zombieApepePage, rareApepePage, otherApepePage]);

	return ({
		genesis: collectionsBalances[CONTRACT_NAMES.RAYC],
		zombies: collectionsBalances[CONTRACT_NAMES.ZAYC],
		odyssey: collectionsBalances[CONTRACT_NAMES.LORE],
		keys: collectionsBalances[CONTRACT_NAMES.KEY],
		z1: collectionsBalances[CONTRACT_NAMES.Z1],
		apepeLoot: collectionsBalances[CONTRACT_NAMES.LOOT],
		loading: collectionsBalances.loading,
		nextPage,
	});
};

export default useYourCollectionData;
