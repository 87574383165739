import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { getContractAbi, isSupportedNetworkName } from 'src/base/utils';
import { getContractAddresses } from 'src/base/utils';

// TODO: update contractName to CONTRACT_NAMES (narrow it down)
const useContract = ( provider : ethers.BrowserProvider, networkName : "ethereum" | "baseSepolia", contractName: string, readOnly = false ) => {
	const [contract, setContract] = useState( null );

	useEffect( () => {
		async function initContract() {
			
			try {
				if ( provider && !contractName ) {
					throw new Error( 'useContract: contractName is not defined' );
				}
				if ( provider && networkName ) {
					const networkIsSupported = isSupportedNetworkName( networkName );
					if ( !networkIsSupported ) {
						throw new Error( `Unsupported networkName: ${networkName}` );
					}
					const signer = await provider.getSigner();
					const abi = getContractAbi( contractName );
					const contractAddress = getContractAddresses( networkName )[`${contractName}Address`];
					if ( !contractAddress ) {
						throw new Error( `Contract address is not defined for ${contractName} on ${networkName}. Did you forget to add them to getContractAddresses in utils.js?` );
					}
					const providerOrSigner = readOnly ? provider : signer;
					const contractInstance = new ethers.Contract( contractAddress, abi, providerOrSigner );
					setContract( contractInstance );
				}

			} catch ( error ) {
				if (error.message?.includes("eth_requestAccounts")) {
					console.error('useContract: user metamask might be locked')
				} else {
					console.error( 'useContract: Error initializing contract:', error.message );
				}
				setContract( null );
			}
		}

		initContract();
	}, [provider, networkName, contractName, readOnly]);

	return contract;
};


export default useContract;
