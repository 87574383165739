import { CONTRACT_NAMES } from 'src/base/constants';
import { formatDate } from 'src/base/utils';
import { NULL_ADDRESS } from 'src/base/constants';

export function tokensUsedToTokensUsage( tokensUsed ) {
	if ( !tokensUsed ) return null;
	const raycObj = tokensUsed.find( ( token ) => token.name === CONTRACT_NAMES.RAYC );
	const zaycObj = tokensUsed.find( ( token ) => token.name === CONTRACT_NAMES.ZAYC );
	if ( !raycObj || !zaycObj ) return null;

	const result = {
		rayc: {
			used: raycObj.tokensUsed.filter( ( token ) => token.used ).map( ( token ) => token.tokenId ),
			available: raycObj.tokensUsed.filter( ( token ) => !token.used ).map( ( token ) => token.tokenId ),
		},
		zayc: {
			used: zaycObj.tokensUsed.filter( ( token ) => token.used ).map( ( token ) => token.tokenId ),
			available: zaycObj.tokensUsed.filter( ( token ) => !token.used ).map( ( token ) => token.tokenId ),
		},
	};

	// TODO: declare these in the result object above
	result.rayc.statement = `Rare Apepes: ${result.rayc.used.length} used, ${result.rayc.available.length} 
    available`;
	result.zayc.statement = `Zombie Apepes: ${result.zayc.used.length} used, ${result.zayc.available.length} available`;

	return result;
}

export function generatePieceInfoItemContents({ tokenConfigNotYetSet, tokenConfig, totalSupply, remainingCount, showRevealed, pieceMetadata, saleHasEnded, isLimited, saleHasStarted }) {
	const quantityItem = {
		title: ( () => {
			if ( isLimited ) return 'LIMITED EDITION';
			else return 'OPEN EDITION';
		})(),
		paragraph: {
			content: ( () => {
				// limited edition
				if ( isLimited ) {
					return `${totalSupply}/${remainingCount + totalSupply} Minted`;
				}
				if ( !isLimited ) {
					return `${totalSupply} Minted`;
				}
			})(),
			isBold: true,
		},
	};
	const datesItem = {
		title: 'DATES',
		paragraph: {
			content: ( () => {
				if( !saleHasStarted ) return 'Mint not started';
				if ( saleHasEnded ) return 'This piece\'s mint has ended';
				else return 'Minting';
			})(),
		},
	};
	const criteriaItem = {
		title: 'CRITERIA',
		paragraph: {
			content: ( () => {
				if ( tokenConfigNotYetSet ) return 'To be announced';
				if ( tokenConfig?.openToPublic ) {
					return 'This piece is open for minting to the public. Rare Apepes and Zombie Apepes holders will be able to mint at lower prices.';
				} else {
					return 'This piece is open for minting to holders of Rare Apepes and Zombie Apepes.';
				}

			})(),
		},
	};
	const storyItem = {
		title: 'STORY',
		paragraph: {
			content: showRevealed ? pieceMetadata?.description : '???',
		},
	};
	const costItem = {
		title: 'COST',
		paragraph: {
			content: ( () => {
				if ( tokenConfigNotYetSet ) return 'To be announced';

				const raycHolderDisplayPrice = formatDisplayPrice( tokenConfig?.priceRayc );
				const zaycHolderDisplayPrice = formatDisplayPrice( tokenConfig?.priceZayc );
				const publicDisplayPrice = tokenConfig?.openToPublic ? `Public: ${formatDisplayPrice( tokenConfig?.pricePublic )}` : '';

				return ( <>
					<div style={{ marginBottom: 10 }}>
						Rare Apepes holder:
						{' '}
						{raycHolderDisplayPrice}
					</div>
					<div style={{ marginBottom: 10 }}>
						Zombie Apepes holder:
						{' '}
						{zaycHolderDisplayPrice}
					</div>
					<div>
						{publicDisplayPrice}
					</div>

				</> );

			})(),
			isBold: true,
		},
	};
	return { quantityItem, datesItem, criteriaItem, storyItem, costItem };
}

export function formatDisplayPrice( price ) {
	if ( price === '0.0' ) return 'FREE';

	return `${price} ETH`;
}

export function tokensUsageToTierToUse( tokensUsage ) {
	if ( !tokensUsage ) return 'public';
	if ( tokensUsage.rayc.available.length > 0 ) return 'rayc';
	if ( tokensUsage.zayc.available.length > 0 ) return 'zayc';
	return 'public';
}

function max50( value ) {
	if ( value > 50 ) return 50;
	return value;
}

export function getMaxMintCount( tokensUsage, tierToUse ) {
	if ( tierToUse === 'public' ) return 50; // 50 is the max mint count for mintMultiple
	// TODO: combine rayc and zayc
	if ( tierToUse === 'rayc' ) return max50( tokensUsage.rayc.available.length );
	if ( tierToUse === 'zayc' ) return max50( tokensUsage.zayc.available.length );
}

export function getMintParams({ tierToUse, raycAddress, zaycAddress, tokensUsage }) {
	const raycOrZaycAddress = () => {
		if ( !tierToUse ) return undefined;
		if ( tierToUse === 'rayc' ) return raycAddress;
		if ( tierToUse === 'zayc' ) return zaycAddress;
		if ( tierToUse === 'public' ) return NULL_ADDRESS;
	};
	const tokenIdToUse = () => {
		if ( !tierToUse ) return undefined;
		if ( tierToUse === 'rayc' ) return tokensUsage.rayc.available[0];
		if ( tierToUse === 'zayc' ) return tokensUsage.zayc.available[0];
		if ( tierToUse === 'public' ) return '0';
	};
	const tokenIdsToUse = () => {
		if ( !tierToUse ) return undefined;
		if ( tierToUse === 'rayc' ) return tokensUsage.rayc.available;
		if ( tierToUse === 'zayc' ) return tokensUsage.zayc.available;
		if ( tierToUse === 'public' ) return '0';
	};
	return ({ raycOrZaycAddress: raycOrZaycAddress(), tokenIdToUse: tokenIdToUse(), tokenIdsToUse: tokenIdsToUse() });
}
