//	Dependencies
import React, { useId, useRef, useCallback, useEffect } from 'react';
import { animated as a } from '@react-spring/web';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import clsx from 'clsx';
import useIsomorphicLayoutEffect from 'src/hooks/use-isomorphic-layout-effect';

//	App
import { useStore } from 'base/state';
import { useIsMobile, useScrollSpring, useIntersection } from 'src/hooks';

import styles from './image.module.scss';


//
//	RAYC / UI / Components / Image
//


const Image = React.memo(React.forwardRef(function _Image(props, ref) { // eslint-disable-line react/display-name
	const { className, parallaxClassName, src, height, width, alt, parallax, parallaxStartY = 0.5, size, sizes, quality, priority = true, layout, objectFit, wrappingElement, show, onClick, grayscale } = props;

	let imageSizes = sizes;
	const smallBreakpoint = 960;

	if (show === 'desktop') imageSizes = `(min-width: ${smallBreakpoint}px) 100vw`;
	if (show === 'mobile') imageSizes = `(max-width: ${smallBreakpoint}px) 100vw`;

	const _isParallax = typeof parallax === 'number';
	const classList = clsx(styles['c-image'], className, { [`-size-${size}`]: size, '-show-s': show === 'mobile', '-hide-s': show === 'desktop', [styles.grayscale]: grayscale });
	const ImageComponent = <NextImage
		src={src}
		alt={alt ?? ''}
		width={width}
		height={height}
		quality={quality ?? 95}
		sizes={imageSizes}
		layout={layout}
		objectFit={objectFit}
		onClick={onClick}
		loading='lazy'
	/>;

	if (_isParallax && src) {
		return <ParallaxImage className={classList}
			parallaxClassName={parallaxClassName}
			zOffset={parallax}
			yOffsetStart={parallaxStartY}>
			{ImageComponent}
		</ParallaxImage>;
	}

	const WrappingComponent = wrappingElement ?? 'div';


	return (
		<WrappingComponent ref={ref}
			className={classList}>
			{ImageComponent}
		</WrappingComponent>
	);
}));

export function CanvasImage({ src, parallax = 0, element = 'div', className }) {
	const id = useId();

	const Component = element;
	const coordsRef = useRef();
	const cachedViewportWidth = useRef(0);
	const setCanvasState = useRef();
	const api = useStore(s => s.api);
	const [ref, { isIntersecting }, node] = useIntersection({ threshold: 0 });
	const [, { getScrollY }] = useScrollSpring();

	const recalculateLayout = useCallback(() => {
		if (!setCanvasState.current || !node) return;
		const scrollY = getScrollY();
		const { left, top, width, height } = node.getBoundingClientRect();
		const _top = Math.round(top);
		const _scrollY = Math.round(scrollY);
		const _height = Math.round(height);

		coordsRef.current = [left, _top + _scrollY, width, _height];
		const [x, y, w, h] = coordsRef.current;
		setCanvasState.current({ x, y, w, h });
	}, [node, getScrollY]);

	useIsomorphicLayoutEffect(() => {
		if (!node) return;

		api.registerPlane(id, {
			coords: coordsRef.current,
			src,
			parallax,
			el: node,
			onCreate: _setCanvasState => {
				setCanvasState.current = _setCanvasState;
				recalculateLayout();
			},
		});

	}, [id, api, node, src, parallax, recalculateLayout]);

	useEffect(() => {
		const _resizeHandler = () => {
			if (cachedViewportWidth.current === window?.innerWidth) return;
			recalculateLayout();
			cachedViewportWidth.current = window?.innerWidth;
		};

		window.addEventListener('resize', _resizeHandler, { passive: true });
		return () => window.removeEventListener('resize', _resizeHandler);
	}, [recalculateLayout]);

	return (
		<Component ref={ref}
			className={clsx(styles['c-image-canvas'], className)} />
	);
}

const ParallaxImage = React.memo(function _ParallaxImage(props) {
	const { children, zOffset, yOffsetStart = 0.5, className, parallaxClassName } = props;
	const viewport = useStore(s => s.viewport);
	const [st, { isDisabled, isRouting }] = useScrollSpring();
	const isMobile = useIsMobile();
	const coordsRef = useRef();
	const [ref, { isIntersecting }, node] = useIntersection({ threshold: 0 });
	const _zOffsetAdjusted = isMobile ? zOffset * 0.4 : zOffset;

	useIsomorphicLayoutEffect(() => {
		if (!node) return;
		const scrollY = useStore.getState().scrollY; //window.pageYOffset;
		const { top, left, width, height } = node.getBoundingClientRect();
		coordsRef.current = [left, (top + scrollY - viewport[1] * yOffsetStart), width, height];
	}, [node, viewport, isRouting, yOffsetStart]);

	return (
		<div ref={ref}
			className={clsx(styles['c-image-parallax'], parallaxClassName)}>
			<a.div className={className}
				style={{
					transform:
						isIntersecting && coordsRef.current ? st.to(_st => {
							const [left, top, w, h] = coordsRef.current;
							const y = (_st - top - (h * (yOffsetStart))) * _zOffsetAdjusted;
							return `translate3d(0,${y}px,0)`;
						}) : 'translate3d(0,0,0)',
				}}>
				{children}
			</a.div>
		</div>
	);
});

Image.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	alt: PropTypes.string,
	isLocalImage: PropTypes.bool,
	size: PropTypes.string,
	objectFit: PropTypes.string,
	layout: PropTypes.string,
	quality: PropTypes.number,
	sizes: PropTypes.string,
	wrappingElement: PropTypes.string,
};

export default Image;
