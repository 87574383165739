import React from 'react';
import PropTypes from 'prop-types';

// others
import clsx from 'clsx';
import { RARIBLE_LINKS, SOCIALS_LINKS } from 'src/base/constants';

// App
import { Icon, Image, Link, Logo } from 'ui/components';
import { DashboardFooter as DashboardFooterCards } from 'src/ui/views/Dashboard';

// Styles
import styles from './footer.module.scss';

//
//	RAYC / UI / Components / Footer
//

const CopyRights = () => {

	return (
		<p className='-my0'>
			&#169; 
			{' '}
			{new Date().getFullYear()}
			{' '}
Rare Apepes. All Rights Reserved.
		</p>
	);
};
const Socials = ({ iconsColor = 'black' }) => {
	return (
		<div className='flex items-center gap-4 mr-12  max-[500px]:mb-6 max-[500px]:mt-6'>
			<Link ariaLabel="discord"
				href="https://discord.gg/FN3GZqJ9w9"
				external>
				<Icon type="discord"
					colour={iconsColor} />
			</Link>
			<Link ariaLabel="rarible"
				href={RARIBLE_LINKS.GENESIS}
				external>
				<Icon type="rarible"
					colour={iconsColor} />
			</Link>
			<Link ariaLabel="twitter"
				href={SOCIALS_LINKS.TWITTER}
				external>
				<Icon type="twitter"
					colour={iconsColor} />
			</Link>
		</div>
	);
};

const DefaultFooter = React.memo( function DefaultFooter({ className, children }) {
	return (
		<footer
			className={clsx(
				'section',
				'-pt0 -pbs',
				styles['a-footer'],
				'-t-brand',
				'bg-[#B3FE00]',
				className,
			)}
		>
			<div className={styles['a-footer_slime']}>
				<Image src="/images/slime.png"
					width={3050}
					height={1196}
					alt="" />
			</div>
			{children}
			<div>
				<div className="grid -g-cols-2">
					<div>
						<h6 className="fhero2 -f-caps max-[500px]:mb-1.5">
							Rare
							<br />
							Apepes
						</h6>
						<div className='flex items-center max-[500px]:flex-col-reverse max-[500px]:items-start'>
							<Socials />
							<CopyRights />
						</div>
					</div>
					<div className='max-[500px]:order-first max-[500px]:mb-6'>
						<Logo className="-color-primary" />
					</div>
				</div>
			</div>
		</footer>
	);
});

const SecondaryFooter = React.memo( function SecondaryFooter({
	className,
	includeInPage,
}) {
	const defaultClasses = [styles['a-footer'], 'h-100px overflow-y-hidden bg-transparent max-[400px]-h-150px'];
	const secondaryFooterClasses = [
		...defaultClasses,
		includeInPage ? styles['a-footer_push-up'] : '',
	];
	return (
		<footer
			className={clsx(
				// "section",
				// "-pt0 -pbs",
				[...secondaryFooterClasses],
				className,
			)}
		>
			<div>
				<div className="grid -g-cols-2">
					<div>
						<div className='flex items-center max-[500px]:flex-col-reverse max-[500px]:items-start'>
							{/* Temporaril hide Socials for Key Forge */}
							<Socials iconsColor={'white'} />
							<CopyRights/>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
});

const DashboardFooter = React.memo( function DashboardFooter({ className }) {
	const children = <DashboardFooterCards/>;
	
	return (
		<DefaultFooter className={className}
			children={children}></DefaultFooter>
	);
});

const Footer = React.memo( function Footer({
	className,
	variation = 'primary',
	includeInPage,
}) {
	const handleFooterRendering = () => {
		if ( variation.toLowerCase() === 'secondary' ) {
			return (
				<SecondaryFooter className={className}
					includeInPage={includeInPage} />
			);
		}
		if ( variation.toLowerCase() === 'dashboard' ) {
			return <DashboardFooter className={className} />;
		} else return <DefaultFooter className={className} />;
	};

	return <>{handleFooterRendering()}</>;
});

Footer.propTypes = {
	className: PropTypes.string,
};

export default Footer;
