import React from 'react';

export default function CrossIcon({ className, onClick }){
	return <svg className={className}
		onClick={onClick}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0491 15.6652C14.4953 16.1115 15.219 16.1115 15.6653 15.6652C16.1116 15.2189 16.1116 14.4953 15.6653 14.049L9.61627 7.99998L15.6653 1.95099C16.1116 1.50467 16.1116 0.781059 15.6653 0.334747C15.219 -0.111565 14.4953 -0.111565 14.0491 0.334747L8.00003 6.38375L1.95099 0.334735C1.50467 -0.111578 0.781051 -0.111578 0.334737 0.334735C-0.111578 0.781045 -0.111578 1.50466 0.334737 1.95098L6.38378 7.99998L0.334735 14.049C-0.111578 14.4953 -0.111578 15.2189 0.334735 15.6652C0.781051 16.1116 1.50467 16.1116 1.95099 15.6652L8.00003 9.61622L14.0491 15.6652Z"
			fill="white"/>
	</svg>;
}
