import React from 'react';
import useCommunityProjects from 'src/hooks/useCommunityProjects';
import styles from './CommunityProjects.module.scss';
import commonDashBoardStyles from '../../dashboard-common.module.scss';
import { DashboardCard } from 'src/ui/components';

export default function CommunityProjects() {
	const { communityProjectCards } = useCommunityProjects();

	return (
		<div className={styles['community-projects']}>
			<h2 className={`${commonDashBoardStyles['tab-title']} ${styles['community-projects-title']}`}>Community Projects</h2>
			<p className={styles['community-projects-paragraph']}>
				Yup, we have one of the tightest NFT communities out there, check out some of the community projects built by your fellow Apepe friends! Looking to do a project?
				<span>Tweet</span>
				{' '}
				your idea at us.
			</p>
			<div className={styles['community-projects-cards']}>
				{communityProjectCards.map((card, index: number) => (
					<DashboardCard
						key={index}
						className={styles['community-projects-cards-item']}
						{...card}
					/>
				))}
			</div>
		</div>
	);
}
