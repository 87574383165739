import React from 'react';
import clsx from 'clsx';

import styles from './marquee.module.scss';

interface MarqueeProps {
	className?: string;
	theme?: string;
	size?: string;
	duration?: string;
	children?: React.ReactNode;
}

const Marquee: React.FC<MarqueeProps> = ({
	className,
	theme,
	size,
	duration = '150s',
	children,
}: MarqueeProps) => {
	if (!children) return null;

	const renderContent = (key: string) => {
		return (
			// @ts-expect-error: this is a custom style property that can not be defined in ts 
			<div className={styles['c-marquee_content']} style={{ '--marquee-duration': duration }}>
				{[...Array(10)].map((_, i) => {
					return (
						<div
							aria-hidden={key === 'first-loop' && i === 0 ? false : true}
							key={`${key}-${i}`}
						>
							{children}
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<div className={clsx(styles['c-marquee'], className, { [`-t-${theme}`]: theme, [styles[`-size-${size}`]]: size })}>
			<div className={styles['c-marquee_container']}>
				{renderContent('first-loop')}
				{renderContent('second-loop')}
			</div>
		</div>
	);
};

export default Marquee;
