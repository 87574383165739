
//	Dependencies
import { useState, useRef } from 'react';
import _debounce from 'lodash-es/debounce';
import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect';


//
//	RAYC / UI / App / Hooks / Breakpoint
//


function formatBreakpoint( bp ) {
	return bp.trim().replace( /^"|"$/g, '' );
}

function getBreakpoint() {
	if ( typeof document === 'undefined' ) return 'default';
	const bpKey = getComputedStyle( document.documentElement ).getPropertyValue( '--breakpoint' );
	if ( !bpKey ) return 'default';
	return formatBreakpoint( bpKey );
}

export default function useBreakpoint() {
	const [ breakpoint, setBreakpoint ] = useState( getBreakpoint() );
	const cachedViewportWidth = useRef( 0 );

	useIsomorphicLayoutEffect( () => {
		let isCancelled = false;
		const _resizeHandler = () => {
			if ( isCancelled || cachedViewportWidth.current === window.innerWidth ) return;
			setBreakpoint( getBreakpoint() );
			cachedViewportWidth.current = window.innerWidth;
		};
		if ( !isCancelled ) {
			window.addEventListener( 'resize', _debounce( _resizeHandler, 500 ), { passive: true });
			_resizeHandler();
		}
		return () => {
			isCancelled = true;
			window.removeEventListener( 'resize', _resizeHandler );
		};
	}, []);

	return breakpoint;
}

export function useIsMobile() {
	const breakpoint = useBreakpoint();
	const isMobile = breakpoint === 'small';
	return isMobile;
}
