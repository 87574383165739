import {
	atom,
} from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const selectedVaultAtom = atom({
	key: 'selectedVault', // unique ID (with respect to other atoms/selectors)
	default: null, // default value (aka initial value)
	effects_UNSTABLE: [persistAtom],
});

export default selectedVaultAtom;
