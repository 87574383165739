import React from 'react';

export default function TemplateIcon() {
	return (
		<svg width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd"
				clipRule="evenodd"
				d="M18 18V6C18 4.89543 17.1046 4 16 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18ZM21 18C21.5523 18 22 17.5523 22 17V7C22 6.44772 21.5523 6 21 6H20V18H21ZM2 7C2 6.44772 2.44772 6 3 6H4V18H3C2.44772 18 2 17.5523 2 17V7Z"
				fill="#C0C0C0"/>
		</svg>

	);
}
