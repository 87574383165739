//	Dependencies
import React, { useState, useMemo } from 'react';

// UI components
import { Image, Button } from 'src/ui/components';
import clsx from 'clsx';

// hooks

// others
import KeyForgeCommonStyles from '../KeyForge.module.scss';
import KeyForgeControllerStyles from './KeyForgeController.module.scss';

import CountManager from 'src/ui/components/CountManager';

export default function KeyForgeController({
	connect,
	connected,
	tocBalance,
	eligibleKeysCount,
	onClick,
	customButtonText,
}) {
	const [count, setCount] = useState( 0 );

	// static key section info items
	const keySectionInfoItems = useMemo( () => ([
		{
			title: 'CRITERIA',
			paragraph:
        'You require 5 Apepe Odyssey:  #1 The Original\'s Choice, to mint one Apepe Odyssey Key. The mint for these has closed, hopefully you got yours! ',
		},
		{
			title: 'REQUIREMENT',
			paragraph:
        'Connect your wallet to see if you are eligible for one or more keys.',
		},
	]), []);

	const keySectionInfoItemsFiltered = useMemo( () => {
		if ( connected ) {
			return keySectionInfoItems.filter( ( item ) => item.title !== 'REQUIREMENT' );
		} else {
			return keySectionInfoItems;
		}
	}, [connected, keySectionInfoItems]);

	const notConnectedKeyForgeControllerView = () => {
		return (
			<div
				className={
					KeyForgeControllerStyles['key-forge-key-overview-item-connected']
				}
			>
				<Button
					className='mt-[28px] max-[500px]:mt-[10px] max-[500px]:mb-[15px] max-[500px]:w-[95%] max-[500px]:text-[20px]'
					icon="foxLarge"
					iconPos="before"
					onClick={() => connect()}
				>
          Connect Wallet
				</Button>
			</div>
		);
	};

	// note: max handler should be in the MintCountManager component itself
	const increaseCount = () =>
		setCount( ( prev ) => {
			if ( eligibleKeysCount ) {
				// incase of max count passing
				if ( prev !== eligibleKeysCount ) return prev + 1;
				else return prev;
			}
			return prev;
		});

	const decreaseCount = () =>
		setCount( ( prev ) => {
			if ( prev > 0 ) return prev - 1;
			else return prev;
		});

	const getCountBtnClasses = ( countType ) => {
		const classes = [
			'cursor-pointer w-auto',
		];

		if ( countType === 'minus' && count === 0 ) {
			classes.push(
				'opacity-40',
			);
		}

		if (
			countType === 'plus' &&
      ( count === eligibleKeysCount || eligibleKeysCount === undefined )
		) {
			// plus btn will be disabled if no max mint count is passed
			classes.push(
				KeyForgeControllerStyles[
					'key-forge-key-overview-item-balance-info-count-manager-count-btn-disabled'
				],
			);
		}

		return [...classes];
	};
	function connectedKeyForgeControllerView() {
		return (
			<div
				className={
					KeyForgeControllerStyles['key-forge-key-overview-item-not-connected']
				}
			>
				<div
					className={`flex justify-between items-center  w-fit h-[120px] p-[16px] uppercase border border-solid border-[rgba(255, 255, 255, 0.2)] rounded-[12px] box-border ${KeyForgeControllerStyles['key-forge-key-overview-item-balance-info']}`}
				>
					<div
						className='h-full w-auto flex justify-start items-center max-[500px]:items-start'
					>
						<Image
							className='h-auto w-[100px] max-[500px]:hidden'
							src={'/images/SerumForgeKeySmall.png'}
							width={61}
							height={77}
							alt="Key Forge Informatic image"
						/>
						<div
							className='w-full ml-4'
						>
							<div
								className='text-[10px] opacity-80 max-[500px]:text-[9px]'
							>
                you have
							</div>
							<h3
								className='m-[0px]'
							>
								{tocBalance}
							</h3>
							<p
								className='font-normal text-[9px] leading-[140%] w-[82px] text-[#d9d9d9] opacity-60 m-0 max-[500px]:w-auto max-[500px]:text-[8px]'
							>
                #1 The Original&apos;s Choice
							</p>
						</div>
					</div>
					<div
						className='w-[1.5px] h-full opacity-20 bg-white mx-4 max-[500px]:mx-[10px]'
					/>
					<div
						className='h-full w-auto'
					>
						<div
							className='text-[10px] opacity-80 max-[500px]:text-[9px]'
						>
              Key ELIGIBility
						</div>
						<h3
							className={`m-[0px] ${KeyForgeControllerStyles['key-forge-key-overview-item-balance-info-header-neon']}`}
						>
							{eligibleKeysCount}
						</h3>
						<p
							className='font-normal text-[9px] leading-[140%] w-[82px] text-[#d9d9d9] opacity-60 m-0 max-[500px]:w-auto max-[500px]:text-[8px]'
						>
              Forge up to this many keys
						</p>
					</div>
				</div>
				{eligibleKeysCount > 0 ? copiesFoundView() : noCopiesView()}
				{ eligibleKeysCount > 0 && (
					<p>
						{ customButtonText === 'Approve' ? (
							<small>Approve the Odyssey Key Contract to burn copies of The Original&apos;s Choice</small>
						) : (
							<small>
								{count * 5}
								{' '}
copies of The Original&apos;s Choice will be burned to forge 
								{' '}
								{count}
								{' '}
key
								{count > 1 ? 's' : ''}
							</small>
						)
						}
					</p> )
				}
			</div>
		);
	}

	function noCopiesView() {
		return (
			<span
				className='-f-family-burbank  inline-block mt-5 text-[24px] p-[15px] uppercase bg-[#1f280d] w-[327px] text-center border-2 border-solid border-[#658d08] max-[1150px]:text-[22px] max-[1150px]:w-[300px] max-[900px]:text-[24px] max-[900px]:w-auto max-[580px]:text-[20px] max-[390px]:text-[15px] max-[390px]:w-[90%]'
			>
				<span
					className={
						KeyForgeControllerStyles[
							'key-forge-key-overview-item-balance-info-header-neon'
						]
					}
				>
          Sorry, Criteria not met
				</span>
			</span>
		);
	}

	function copiesFoundView() {
		const disableButton = count === 0 && customButtonText !== 'Approve';
		return (
			<div
				className='flex items-center mt-10 max-[500px]:flex-col'
			>
				<CountManager
					connected={connected}
					wrapperClassName='flex justify-start items-center w-auto mr-[36px]'
					countValueClassName={
						`font-bold text-[36px] leading-[130%] mx-[15px] text-center uppercase text-[#0c645f]  ${KeyForgeControllerStyles['key-forge-key-overview-item-balance-info-count-manager-info-value']}`
					}
					getCountBtnClasses={getCountBtnClasses}
					decreaseCount={decreaseCount}
					count={count}
					increaseCount={increaseCount}
				/>

				<Button
					className='w-1/2 text-[36px] max-[1200px]:text-[30px] max-[1000px]:text-[26px] max-[500px]:mt-[30px] max-[500px]:w-full'
					disabled={disableButton}
					style={{ cursor: disableButton ? 'unset' : 'pointer' }}
					iconPos="before"
					onClick={() => customButtonText === 'Approve' ? onClick() : onClick( count )}
					// onClick with count is for forging while onClick without is for approval
				>
					{ customButtonText ? customButtonText : 'FORGE KEY' }
				</Button>
			</div>
		);
	}

	return (
		<div
			className={`border-box flex p-[5%] max-[900px]:flex-col max-[900px]:mt-[25px] h-auto w-[77.5%] bg-[rgba(0,0,0,0.4)] rounded-[20px]   ${KeyForgeCommonStyles['key-forge-transparent-box']}`}
		>
			<div
				className={'mr-[5%] w-1/2 h-auto max-[900px]:w-full  max-[900px]:mb-5'}
			>
				<Image
					className={
						KeyForgeControllerStyles['key-forge-key-overview-item-img']
					}
					src={'/images/forgeKeyWalletSectionImg.png'}
					width={472}
					height={472}
					alt="Step Image"
				/>
			</div>
			<div
				className={`w-1/2 h-auto max-[900px]:w-full  max-[900px]:mb-5 ${KeyForgeControllerStyles['key-forge-key-overview-info']}`}
			>
				<h4
					className={`font-bold text-[48px] leading-[100%] uppercase text-white mb- ${KeyForgeControllerStyles['key-forge-key-overview-item-header']}`}
				>
          APEPE ODDYSSEY KEY
				</h4>
				{keySectionInfoItemsFiltered.map( ( section, index ) => {
					return (
						<div
							key={index}
							className='mb-6'
						>
							<div
								className='font-semibold text-[16px] leading-[140%] mb-4 text-[#b3fe00]'
							>
								{section.title}
							</div>
							<div
								className='font-normal text-[16px] leading-[140%]  text-[#d9d9d9]'
							>
								{section.paragraph}
							</div>
						</div>
					);
				})}
				{!connected && notConnectedKeyForgeControllerView()}
				{connected && connectedKeyForgeControllerView()}
			</div>
		</div>
	);
}
