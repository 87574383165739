import styles from './dashboard-view.module.scss';
import clsx from 'clsx';
import FolderIcon from 'src/icons/FolderIcon';
import TestTubeIcon from 'src/icons/TestTubeIcon';
import LinkIcon from 'src/icons/LinkIcon';
import DashboardLayoutIcon from 'src/icons/DashboardLayoutIcon';
import FactoryIcon from 'src/icons/FactoryIcon';
import ToolsIcon from 'src/icons/ToolsIcon';

import { useRouter } from 'next/router';

import Home from './Tabs/Home/Home';
import YourCollection from './Tabs/YourCollection/YourCollection';
import MemeGenerator from './Tabs/MemeGenerator/MemeGenerator';
import MeetTheCouncil from './Tabs/MeetCouncil/MeetCouncil';
import CommunityTools from './Tabs/CommunityTools/CommunityTools';
import UsefulLinks from './Tabs/UsefulLinks/UsefulLinks';

// internal component
import ConnectBar from './ConnectBar/ConnectBar.tsx';
import { Link } from 'src/ui/components';
import CouncilIcon from 'src/icons/Council';

// connect, address, connected, connectWallet, provider,


// this tabs will be used to define list of tabs including text icon and slug
// however tab navigation will occur at pages/dashboard/(filename||tabslug).js
const defaultTabs = [
	{
		slug: '',
		text: 'Home',
		icon: <DashboardLayoutIcon />,
		content: <Home />,
	},
	{
		slug: 'your-collection',
		text: 'Your Collection',
		icon: <FolderIcon />,
		content: <YourCollection />,
		requireWalletConnection: true,
	},
	// {
	// 	text: 'Community projects',
	// 	icon: <TestTubeIcon />,
	// 	content: <CommunityProjects />,
	// 	requireWalletConnection: false,
	// },
	{
		slug: 'meme-generator',
		text: 'Meme Generator',
		icon: <FactoryIcon />,
		content: <MemeGenerator />,
	},
	{
		slug: 'meet-council',
		text: 'Meet the council',
		icon: <CouncilIcon />,
		content: <MeetTheCouncil />,
	},
	{
		slug: 'community-tools',
		text: 'Community tools',
		icon: <ToolsIcon />,
		content: <CommunityTools />,
	},
	{
		slug: 'useful-links',
		text: 'useful links',
		icon: <LinkIcon />,
		content: <UsefulLinks />,
	},
];


export default function DashboardView({ selectedTab = defaultTabs[0], tabs = defaultTabs, connected, connectWallet }) {
	// Find the matching tab based on URL path
	const router = useRouter();
	const currentPath = router.pathname.split( '/' ).pop();
	
	// If no selectedTab is passed, find it from defaultTabs based on URL
	const activeTab = selectedTab?.slug ? selectedTab : 
		defaultTabs.find( tab => tab.slug === currentPath ) || defaultTabs[0];

	const isSelectedTab = ( tab ) => {
		return tab.slug === activeTab.slug;
	};

	return (
		<div className={styles.dashboard}>
			<div className={styles['dashboard-tabs']}>
				{tabs?.map( ( tab, index ) => {
					return (
						<Link
							key={index}
							className={`${isSelectedTab( tab ) ? styles['dashboard-tabs-item-selected'] : ''
							} ${styles['dashboard-tabs-item']}`}
							href={`/dashboard/${tab.slug}`}
						>
							<div className={styles['dashboard-tabs-item-icon']}>
								{tab.icon}
							</div>
							<div className={styles['dashboard-tabs-item-glow']} />

							<div
								className={clsx(
									'-f-family-burbank',
									styles['dashboard-tabs-item-text'],
								)}
							>
								{tab.text}
							</div>
						</Link>
					);
				})}
			</div>
			<div className={styles['dashboard-tab-content']}>
				{connected || !activeTab?.requireWalletConnection ? activeTab?.content : <ConnectBar onConnectWallet={connectWallet} />}
			</div>
		</div>
	);
}
