import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ text, color }) => {
	const style = {
		border: `1px solid ${color}`,
		color: color,
		display: 'inline-block',
		padding: '0.2em 0.6em',
		fontSize: '75%',
		fontWeight: '700',
		lineHeight: '1',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		verticalAlign: 'baseline',
		borderRadius: '0.25em',
		textTransform: 'uppercase',
	};

	return (
		<span style={style}>{text}</span>
	);
};

Label.propTypes = {
	text: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
};

export default Label;
