
export default function ChevronDownIcon(){
	return <svg width="24"
		height="24"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M20.72 23.78C20.5795 23.9207 20.3888 23.9999 20.19 24H19.81C19.6116 23.9977 19.4217 23.9189 19.28 23.78L14.15 18.64C14.0553 18.5462 14.0021 18.4184 14.0021 18.285C14.0021 18.1517 14.0553 18.0239 14.15 17.93L14.86 17.22C14.9521 17.126 15.0783 17.073 15.21 17.073C15.3417 17.073 15.4678 17.126 15.56 17.22L20 21.67L24.44 17.22C24.5339 17.1254 24.6617 17.0721 24.795 17.0721C24.9283 17.0721 25.0561 17.1254 25.15 17.22L25.85 17.93C25.9446 18.0239 25.9979 18.1517 25.9979 18.285C25.9979 18.4184 25.9446 18.5462 25.85 18.64L20.72 23.78Z"
			fill="white"/>
	</svg>;
}
