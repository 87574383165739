import React from 'react';

export default function TextIcon() {
	return (
		<svg width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd"
				clipRule="evenodd"
				d="M13 14V6H17.5C17.7761 6 18 5.77614 18 5.5V4.5C18 4.22386 17.7761 4 17.5 4H6.5C6.22386 4 6 4.22386 6 4.5V5.5C6 5.77614 6.22386 6 6.5 6H11V14H13ZM11.5 19C11.2239 19 11 18.7761 11 18.5V14H13V18.5C13 18.7761 12.7761 19 12.5 19H11.5Z"
				fill="#C0C0C0" />
			<path d="M6.5 7.5C6.22386 7.5 6 7.27614 6 7V5.5L7 4H8L8 7C8 7.27614 7.77614 7.5 7.5 7.5H6.5Z"
				fill="#C0C0C0" />
			<path d="M17.5 7.5C17.7761 7.5 18 7.27614 18 7L18 5.5L17 4H16L16 7C16 7.27614 16.2239 7.5 16.5 7.5H17.5Z"
				fill="#C0C0C0" />
		</svg>

	);
}
