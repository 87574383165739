import React, { useEffect, useState } from 'react';

const MintCountDown = ({ timestamp }) => {
	const [timeLeft, setTimeLeft] = useState( calculateTimeLeft() );
	

	useEffect( () => {
		const interval = setInterval( () => {
			setTimeLeft( calculateTimeLeft() );
		}, 1300 );

		// Clear the interval when the component is unmounted
		return () => clearInterval( interval );
	}, []);

	function calculateTimeLeft() {
		const now = new Date().getTime() / 1000; // Current time in seconds
		const timeRemaining = timestamp - now;

		if ( timeRemaining <= 0 ) {
			// Timer expired
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		const hours = Math.floor( ( timeRemaining % ( 60 * 60 * 24 ) ) / ( 60 * 60 ) );
		const minutes = Math.floor( ( timeRemaining % ( 60 * 60 ) ) / 60 );
		const seconds = Math.floor( timeRemaining % 60 );

		return { hours, minutes, seconds };
	}

	const hoursStatement = timeLeft.hours ? `${timeLeft.hours} hours,` : '';
	const minutesStatement = timeLeft.minutes ? `${timeLeft.minutes} minutes,` : '';

	useEffect( () => {
		if ( timeLeft <= 0 ) {
			window.location.reload();
		}
	}, [timeLeft]);

	return (
		<div
			className='text-center border border-white rounded p-5'
			style={{ border: '1px solid white' }}
		>
			<p>
Hang in there!
			</p>
			<p>
				{`${hoursStatement} ${minutesStatement} ${timeLeft.seconds} seconds`}
				{' '}
left till mint begins

			</p>
			<p>{ timeLeft === 0 ? 'Refresh the page to mint.' : '' }</p>
		</div>
	);
};

export default MintCountDown;
