import styles from './Ribbon.module.scss';
import clsx from 'clsx';
import { Image } from 'src/ui/components';

function Ribbon({ ribbonValue, ribbonLabel, className, ribbonWidth, ribbonHeight, numberFontSize, wordFontSize, variation = 'primary', numberClassName, wordClassName }) {
	const ribbonImageVariations = {
		primary:'ribbon.png',
		secondary:'ribbon-2.png',
	};
	return (
		<div className={clsx( styles['ribbon-container'], className )}>
			<Image src={`/images/${ribbonImageVariations[variation]}`}
				width={ribbonWidth || 102}
				height={ribbonHeight || 100}
				alt="Ribbon"/>
			<div className={`-f-family-burbank ${styles['ribbon-info']} ` }>
				<div style={{ fontSize:`${numberFontSize}px` }}
					className={`${styles['ribbon-info_count'] } ${numberClassName}`}
				>
					{ribbonValue}
				</div>
				<div style={{ fontSize:`${wordFontSize}px` }}
					className={` ${styles['ribbon-info_label']} ${wordClassName}`}
				>
					{ribbonLabel}
				</div>
			</div>
		</div>
	);
}

export default Ribbon;
