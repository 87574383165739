import { useState, useEffect, useMemo, useCallback } from 'react';
import { DelegateCash } from 'delegatecash';
import useNetwork from './useNetwork';
import { getContractAddresses } from 'src/base/utils';
import { useRecoilState } from 'recoil';
import selectedVaultAtom from 'src/state/selectedVaultAtom';
import { Address, Delegation } from 'src/base/types';

const dc = new DelegateCash();

const DELEGATION_TYPES = {
	ALL: 'ALL',
	CONTRACT: 'CONTRACT',
	TOKEN: 'TOKEN',
};

function delegationsIncludesAll( delegations: Delegation[] ) {
	return delegations.some( item => item.type === DELEGATION_TYPES.ALL );
}

function delegationsIncludesRayc( delegations: Delegation[], raycAddress : Address ) {
	return delegations.some( item => item.contract === raycAddress );
}

function delegationsIncludesZayc( delegations: Delegation[], zaycAddress : Address ) {
	return delegations.some( item => item.contract === zaycAddress );
}

function listVaults( delegations: Delegation[] ) {
	const vaults = delegations.map( item => item.vault );
	const uniqueVaults = [...new Set( vaults )] as string[];
	return uniqueVaults;
}

interface DelegationsMap {
	all: boolean,
	rayc: boolean,
	zayc: boolean,
	vaults: Address[],
}

function useCheckDelegations( address: Address ) {
	const delegationsInitialState : DelegationsMap = useMemo( () => ({
		all: null,
		rayc: null,
		zayc: null,
		vaults: [],
	}), []);
	const [delegations, setDelegations] = useState({ ...delegationsInitialState });
	const [selectedVault, setSelectedVault] = useRecoilState<Address>( selectedVaultAtom );
	const { network } = useNetwork();
	const { raycAddress, zaycAddress } = getContractAddresses( network );

	function selectVault( _address : Address ) {
		setSelectedVault( _address );
	}

	const removeSelectedVault = useCallback( () => {
		setSelectedVault( null );
	}, [setSelectedVault]);

	const resetDelegations = useCallback( () => {
		setDelegations({ ...delegationsInitialState });
		removeSelectedVault();
	}, [delegationsInitialState, removeSelectedVault]);

	useEffect( () => {
		if ( !address ) {
			resetDelegations();
			return;
		}

		async function getDelegations( delegate: Address ) {
			if ( !delegate ) {
				console.warn( 'No delegate address provided' );
			}
			return await dc.getDelegationsByDelegate( delegate );
		}

		if ( raycAddress !== null && zaycAddress !== null ) {
			getDelegations( address )
				.then( ( result ) => {
					setDelegations({
						all: delegationsIncludesAll( result ),
						rayc: delegationsIncludesRayc( result, raycAddress ),
						zayc: delegationsIncludesZayc( result, zaycAddress ),
						vaults: listVaults( result ),
					});
				})
				.catch( ( error ) => {
					console.error( 'Error in useCheckDeleagations', error );
				});
		}
	}, [address, raycAddress, zaycAddress, delegationsInitialState, resetDelegations ]);

	return { delegations, selectedVault, selectVault, removeSelectedVault, resetDelegations };
}

export default useCheckDelegations;
