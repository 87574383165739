import React from 'react';
import { Image, Button, Icon, Ribbon } from 'src/ui/components';
import KeyForgeAnoitedStyles from './KeyForgeAnoited.module.scss';
import KeyForgeCommonStyles from '../KeyForge.module.scss';
import clsx from 'clsx';

export default function KeyForgeAnoited({ linkToPiece, forgedKeysCount, onClickReplay }) {

	const shareOnTwitter = () => {
		const keyForgeLink = 'https://www.rareapepes.com/odyssey/1/key';

		const text = 'I\'ve just forged an Odyssey Key from Chapter 1 of the Apepe Odyssey! I\'m now an official Keymaster for the first ever chapter of the Apepe Odyssey!';
		const url = `Check out the Key Forge page yourself at ${keyForgeLink}`;
		const hashtags = '#RareApepes #OdysseyKey';

		const tweetText = `${text}\n\n${url}\n\n${hashtags}`;
		
		const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent( tweetText )}`;
	
		window.open( tweetUrl, '_blank' );
	};

	return (
		<div className={KeyForgeAnoitedStyles['key-forge-anoited']}
			id="key-forge-anoited">
			<div className={KeyForgeAnoitedStyles['key-forge-anoited-img-container']}>
				<Ribbon
					className={KeyForgeAnoitedStyles['key-forge-anoited-img-ribbon']}
					ribbonValue={forgedKeysCount}
					variation="secondary"
					ribbonLabel="Forged"
				/>
				<Image
					className={`${KeyForgeAnoitedStyles['key-forge-anoited-img']}`}
					src={'/images/forgeKeyWalletSectionImg.png'}
					width={472}
					height={472}
					alt="Step Image"
				/>
			</div>
			{/* note: every key has a unique link, so which link should we display? */}
			<div className={KeyForgeAnoitedStyles['key-forge-anoited-cta-container']}>
				{linkToPiece && (
					<a
						className={clsx(
							'-f-family-burbank',
							KeyForgeAnoitedStyles['key-forge-anoited-cta-rarible'],
						)}
						href={linkToPiece}
						target="_blank"
						rel="noreferrer"
					>
						<Icon
							type="rarible"
							className={
								KeyForgeAnoitedStyles['key-forge-anoited-cta-rarible-icon']
							}
						/>
            View on Rarible
					</a>
				)}
			</div>
			{ forgedKeysCount > 0 && ( <>
				<div
					className={KeyForgeAnoitedStyles['key-forge-anoited-header-container']}
				>
					<h4 className={KeyForgeAnoitedStyles['key-forge-anoited-header']}>
          you have now been anointed an
						<span
							className={`${KeyForgeAnoitedStyles['key-forge-anoited-header']} ${KeyForgeAnoitedStyles['key-forge-anoited-header-colored']} ${KeyForgeCommonStyles['key-forge-header']}`}
						>
            official Keymaster!
						</span>
					</h4>
				</div>
				<Button
					className={KeyForgeAnoitedStyles['key-forge-anoited-cta-tweet']}
					onClick={shareOnTwitter}
				>
			Tweet this
				</Button>
				<p onClick={onClickReplay}
					style={{ marginTop: 30, cursor: 'pointer' }}>
replay the forging animation video
				</p>
			</> )
			}
		</div>
	);
}
