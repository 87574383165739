import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
// components
// import { Image } from 'ui/components';
import MintButton from 'src/ui/components/MintButton';
import LoadingOverlay from 'src/ui/components/LoreLoadingOverlay';
import Ribbon from '../../../components/Ribbon/Ribbon';
import InfoItem from './InfoItem';
import { OdysseyButton, Icon } from 'src/ui/components';
import CountManager from 'src/ui/components/CountManager';
import AlbumStyles from '../Album/Album.module.scss';

// others
import clsx from 'clsx';
import styles from './Piece.module.scss';
import { OdysseyImageModal } from './imageModal';
import Image from 'next/image';
import Link from 'next/link';
import CarretRight from 'src/icons/CarretRight';
import { SALE_STATUSES } from 'src/base/constants';
import { getContractAddresses } from 'src/base/utils';


const MemoizedImage = React.memo( function MemoizedImage({ useSmall, pieceMetadata, pieceId }) {
	let imageSrc;
	
	imageSrc = pieceMetadata?.image || `https://odyssey-data.rareapepes.com/chapter-1/pre-reveal/${pieceId}.jpeg`;

	if ( useSmall ) {
		imageSrc = imageSrc.replace( '.jpeg', '_s.jpeg' );
	}
	const isRevealed = pieceMetadata?.status === 'revealed';
	if ( pieceId > 8 && !isRevealed ) {
		// note: after token ID 8, we don't have unique pre-reveal images
		// use the same pre-reveal image for all
		imageSrc = '/images/odyssey/card.jpeg';
	}
	return ( <Image
		src={imageSrc}
		width={384}
		height={516}
		alt="piece image"
	/> );
});

// not working yet
// const MemoizedImage = React.memo(function MemoizedImage({ imageSrc }) {
// 	return (<Image
// 		src={imageSrc}
// 		width={384}
// 		height={516}
// 		alt="piece image"
// 	/>);
// });


const MODES = {
	VIEW: 'view',
	MINT: 'mint',
};


export default function PieceFound({ network, chapterId, pieceId, isLoading, customLoadingMessage, showRevealed, pieceMetadata, hideMintButton, copiesOwned, infoItemsData, tierStatement, tokensUsage, address, setMintCount, tokenConfigNotYetSet, mintCount, maxMintCount, connect, onClickMint, saleStatus }) {

	const showInput = !hideMintButton;
	const showRaribleLink = showRevealed;
	const [mode, setMode] = useState( saleStatus === SALE_STATUSES.ACTIVE ? MODES.MINT : MODES.VIEW ); // view | mint
	const [openModal, setOpenModal] = React.useState( false );

	const router = useRouter();
	const navigateBackToAlbum = () => router.push( `/odyssey/${chapterId}` );
	let imageSrc = pieceMetadata?.image || `https://odyssey-data.rareapepes.com/chapter-1/pre-reveal/${pieceId}.jpeg`;
	const { loreAddress } = getContractAddresses( network );

	const lowerCaseLoreAddress = loreAddress?.toString().toLowerCase(); // i noticed that rarible expects contract address letter to be always small, if it was capped it returns an error
	

	const linkToPiece = `https://rarible.com/token/${lowerCaseLoreAddress}:${pieceId}`;
	const connected = address && address.length > 0;
	const showBackNavLink = true;
	const pieceFoundRef = useRef( null );
	const { quantityItem, datesItem, criteriaItem, storyItem, costItem } = infoItemsData;


	if ( !showRevealed ) {
		imageSrc = imageSrc.replace( '.jpeg', '_s.jpeg' );
	}

	useEffect( () => {
		if ( pieceFoundRef.current ) {
			document.body.style.minHeight = `${pieceFoundRef.current.offsetHeight + 675}px`;
		}
	}, [pieceFoundRef, infoItemsData]);

	const BackBtn = () => {
		if ( showBackNavLink ) {
			return (
				<button
					className={clsx(
						'-f-family-burbank absolute left-0 text-white font-bold text-[24px] max-[1000px]:static  max-[1000px]:mb-4 max-[1000px]:self-center',
						styles['apepe-odyssey_back-btn'],
					)}
					onClick={navigateBackToAlbum}
				>
					<Icon
						type="arrowLeft"
						className='mr-[10px]'
					/>
					Back to album
				</button>
			);
		}
	};

	const InfoItems = () => {
		if ( mode === 'mint' ) {
			return ( <>
				<InfoItem infoItem={quantityItem} />
				<InfoItem infoItem={datesItem} />
				<InfoItem infoItem={criteriaItem} />
				<InfoItem infoItem={storyItem} />
				<InfoItem infoItem={costItem} />
			</>
			);
		}
		return (
			<>
				<InfoItem infoItem={storyItem} />
			</>
		);
	};


	const previousPiece = () => {

		if ( pieceId === 0 ) return;
		else {
			const prevPieceUrl = `/odyssey/${chapterId}/${Number( pieceId ) - 1
			} `;
			router.replace( prevPieceUrl, undefined, { shallow: false });

		}

	};
	const nextPiece = () => {
		const nextPieceUrl = `/odyssey/${chapterId}/${Number( pieceId ) + 1
		} `;
		router.replace( nextPieceUrl, undefined, { shallow: false });
	};

	// note: max handler should be in the CountManager component itself 
	const increaseMintCount = () =>
		setMintCount( ( prev ) => {
			if ( maxMintCount ) { // incase of max count passing
				if ( prev !== maxMintCount ) return prev + 1;
				else return prev;
			}
			return prev;
		});
	const decreaseMintCount = () =>
		setMintCount( ( prev ) => {
			if ( prev > 0 ) return prev - 1;
			else return prev;
		});

	const getCountBtnClasses = ( countType ) => {
		const classes = [styles['apepe-odyssey_main_right_mint_count_btn']];

		if ( countType === 'minus' && mintCount === 0 ) { classes.push( styles['apepe-odyssey_main_right_mint_count_btn_disabled']); }

		if ( countType === 'plus' && ( mintCount === maxMintCount || maxMintCount === undefined ) ) // plus btn will be disabled if no max mint count is passed
		{ classes.push( styles['apepe-odyssey_main_right_mint_count_btn_disabled']); }

		return [...classes];
	};

	return (
		<>
			<LoadingOverlay show={isLoading}
				text={customLoadingMessage} />
			{openModal && <OdysseyImageModal imageUrl={imageSrc}
				onCloseClick={() => setOpenModal( false )} />}
			<div className='w-full'>
				<div className='min-[1700px]:mt-[30px] w-[85%] flex items-center justify-between max-[1300px]:flex-col  max-[1300px]:w-full '>
					<div className='flex flex-col items-start justify-start max-[1300px]:items-center'>
						<Link href={`/odyssey/${chapterId}`}>
							<Image src="/images/Apepe_Odyssey_Logo.webp"
								className='cursor-pointer'
								width={487}
								height={74}
								alt="Odyssey logo" />
						</Link>
						<Link href={`/odyssey/${chapterId}`}>
							<div className={`mt-2 ml-3 py-[5px] px-5 max-[1300px]:my-5 cursor-pointer  ${AlbumStyles['oddysey-album_header_wrapper']}`}>
								<div
									className={`-f-family-burbank text-center uppercase  inline-block text-[32px] bg-black  ${AlbumStyles['oddysey-album_header']}`}
								>
									CHAPTER
									{chapterId}
								</div>
							</div>
						</Link>
					</div>
					<span className='flex p-2.5 bg-[rgb(0,0,0)] rounded-[10px] max-[1300px]:mb-4 '
						style={{ border: '1px solid rgba(255,255,255,.4)' }}>
						<OdysseyButton className='mr-2'
							text="View Mode"
							isActive={mode === MODES.VIEW}
							onClick={() => setMode( MODES.VIEW )} />
						<OdysseyButton 
							text="Mint Mode"
							isActive={mode === MODES.MINT}
							textClassName="text-white"
							onClick={() => setMode( MODES.MINT )} />
					</span>
				</div>
			</div>
			<div ref={pieceFoundRef}
				className='w-full relative max-w-[1200px] min-h-[550px] max-h-auto mb-[40px] mt-[40px] mx-0 pt-[5%] flex justify-between max-[1300px]:flex-col max-[1300px]:items-center max-[1300px]:min-h-auto max-[1300px]:max-h-auto max-[1300px]:mt-0'>

				{/* only showing when not at last piece */}
				{Number( pieceId ) > 0 && <CarretRight
					onClick={previousPiece}
					className={styles['prev-carret']} />}
				{/* only showing when not at last piece */}

				<div className={styles['apepe-odyssey_main_left']}>
					<div className={styles['apepe-odyssey_main_left_img']}
						onClick={() => {
							if ( showRevealed ) setOpenModal( true );
						}}
						style={{ cursor: showRevealed ? 'pointer' : 'unset' }}>
						<MemoizedImage pieceId={pieceId}
							pieceMetadata={pieceMetadata}
							useSmall={showRevealed}
							/>
						<div suppressHydrationWarning>
							{/* ideally copiesOwned should be replaced with connected, but somehow that brings back hydration errors */}
							{copiesOwned !== null && (
								<Ribbon 
									ribbonValue={copiesOwned}
									ribbonLabel='Owned'
									ribbonWidth={132}
									ribbonHeight={124}
									numberFontSize={48}
									wordFontSize={18}
									className={styles['apepe-odyssey_main_left_img_ribbon']} 
								/>
							)}
						</div>
					</div>
					{
						showRaribleLink && (
							<a
								className={clsx(
									'-f-family-burbank',
									'mb-6',
									styles['apepe-odyssey_main_left_cta'],
								)}
								href={linkToPiece}
								target='_blank'
								rel="noreferrer"
							>
								<Icon
									type="rarible"
									className={styles['apepe-odyssey_main_left_cta_icon']}
								/>
								View on Rarible
							</a>
						)
					}

				</div>
				<div className='w-[642px] h-auto max-[1000px]:w-[500px]  max-[1000px]:mt-[44px] max-[500px]:w-[75vw]'>
					<div
						className={clsx(
							'-f-family-burbank text-[48px] text-white uppercase mb-6 max-[1000px]:text-[44px] max-[440px]:text-[32px]',
							styles['apepe-odyssey_main_right_title'],
						)}
					>
						{`${pieceMetadata?.name}`}
					</div>
					<div className={styles['apepe-odyssey_main_right_info']}>
						<InfoItems />
					</div>
					{tierStatement && ( <div className='mt-[40px]'>
						<div className="text-[18px] font-bold">
							Your Mint Tier
						</div>
						<p className='mb-0 mt-0'>{tokensUsage?.rayc.statement}</p>
						<p className='mb-0 mt-0'>{tokensUsage?.zayc.statement}</p>
						<p className='mb-0 mt-0'>{!tokenConfigNotYetSet && tierStatement}</p>
					</div> )}
					<div className={styles['apepe-odyssey_main_right_mint']}>
						{showInput &&
							// todo  updates st
							<CountManager
								wrapperClassName={styles['apepe-odyssey_main_right_mint_count-manager']}
								countValueClassName={styles['apepe-odyssey_main_right_mint_count']}
								connected={connected}
								getCountBtnClasses={getCountBtnClasses}
								decreaseCount={decreaseMintCount}
								count={mintCount}
								increaseCount={increaseMintCount}
							/>
						}
						<div className={styles['apepe-odyssey_main_right_mint_button']}>
							{!hideMintButton && <MintButton connected={connected}
								styles={styles}
								connect={connect}
								onClickMint={onClickMint
								} />}
						</div>
					</div>
				</div>

				{/* only hiding at last piece, currently its fixed at 9 so its hardcoded */}
				{Number( pieceId ) < 9 && <CarretRight onClick={nextPiece}
					className={styles['next-carret']} />}
				{/* only hiding at last piece, currently its fixed at 9 so its hardcoded */}

			</div>
			<div className=' w-full max-[800px]:w-[90%]'>
				<div className='w-full h-[1px] bg-[rgba(255,255,255,.4)] my-4 mb-[37px]' />
				<div className='w-full h-[120px] flex justify-between items-start pb-6'>
					<OdysseyButton text="Previous"
						onClick={previousPiece}
						isDisabled={Number( pieceId ) === 1} />
					<OdysseyButton text="Next"
						isDisabled={Number( pieceId ) === 9}
						onClick={nextPiece} />
				</div>
			</div>


		</ >
	);
}
