import React, { useEffect, useState } from 'react';
import styles from './TextSettings.module.scss';


const getFontSizeNumbers = () => {
	const numbers = [];
	const maxFontSize = 100;
	const minFontSize = 24;
	const incrementor = 8;

	for ( let i = minFontSize; i < maxFontSize; i = i + incrementor ) {
		numbers.push( i );
	}
	return numbers;
};


export default function TextSettings({ onFontSizeChange, defaultSize }) {
	const [textSize, setTextSize] = useState();


	useEffect( () => {
		if ( textSize ) {
			onFontSizeChange( textSize );
		}
	}, [textSize]);

	return (
		<div className={styles['text-settings']}>

			<div className={styles['text-settings-font-size-container']}>
				<span className={styles['text-settings-font-size-label']}>Font size</span>
				<select placeholder='Font Size'
					onChange={( e ) => {
						setTextSize( e.target.value );
					}}
					defaultValue={defaultSize}
					className={styles['text-settings-font-size-dropdown']}
					value={textSize}
					name="font-size"
					id="">
					<option value=""
						disabled
						selected>
Font Size
					</option>
					{getFontSizeNumbers().map( ( fontSize, index ) => {
						return <option className={styles['text-settings-font-size-dropdown-item']}
							key={index}
							value={fontSize}>
							{fontSize}
						</option>;
					})}
				</select>
			</div>
		</div>
	);
}
