import { WalletTypeValues } from "./types";
import { ZeroAddress } from "ethers";

export const isDev = process.env.NODE_ENV === 'development' as const;
export const isProd = process.env.NODE_ENV === 'production' as const;

export const isClient = typeof window !== 'undefined' as const;
export const isServer = !isClient;


// Note: also update the getContractAddresses() in base/utils.js
// Ethereum
export const RAYC_ADDRESS_PROD = '0x31d45de84fdE2fB36575085e05754a4932DD5170' as const;
export const ZAYC_ADDRESS_PROD = '0xf902A8BAF88793DDF636a8791bd55A62B71c9ef4' as const;
export const Z1_ADDRESS_PROD = '0x37582c3b14ecbbe085bfdbf83f4bec1d82c1d355' as const;
export const LORE_ADDRESS_PROD = '0x1e50C58f9d26298a9C7C4f3050c43Eb29eA4A0F0' as const;
export const KEY_ADDRESS_PROD = '0xbd1ACC557Dfb6CbcBFEA6d3F83e08aeEBae3Fd14' as const;
export const KEYMASTERS_POT_ADDRESS_PROD = '0xFB1279F3a3bB07d45cfC88154a9386E597eB1d34' as const; // proxy
export const LOOT_MAIN_ADDRESS_PROD = '0x86440Bb01856c2F537498f3DE1413cA345d578F6' as const;
export const LOOT_MINTER_ADDRESS_PROD = '0xB4e6d7ccC8BC15E7e503D0Bb581C51d363AE6c27' as const; // proxy
export const LOOT_MINTER_2_ADDRESS_PROD = '0x4d0B1E913c003A5f23C297FD780189502905411e' as const;
export const LOOT_MINTER_3_ADDRESS_PROD = '0xd1613ECccb723db33965E1509E774B5432ce2A28' as const;
// TODO: extract to /shared and share amongst api and ui

// Base Sepolia
export const RAYC_ADDRESS_TEST = '0x89100C25423e2668b8CbE14B9F83BA6486524E40' as const;
export const ZAYC_ADDRESS_TEST = '0xf39ea3f161cb007d9f8aA31dc71DEA1081a637A7' as const;
export const LORE_ADDRESS_TEST = '0x79DE6Bb96ef4bEDfD309E253F22C2af215ca2b66' as const;
export const KEY_ADDRESS_TEST = ZeroAddress;
export const KEYMASTERS_POT_ADDRESS_TEST = ZeroAddress; // proxy
export const LOOT_MAIN_ADDRESS_TEST = '0x92e7d0C0725b5abB6b4d4F873b4d1F5594086F2a' as const;
export const LOOT_MINTER_ADDRESS_TEST = ZeroAddress; // proxy
// TODO: extract to /shared and share amongst api and ui
export const LOOT_MINTER_2_ADDRESS_TEST = '0x9DD2fa087EF5cA8A837aD89b4a2B182e41977b8E' as const;
export const LOOT_MINTER_3_ADDRESS_TEST = '0xEF6eeeFfa1F57F9b508B66dB152CCb7ca77AC38F' as const;

export const NETWORKS = {
	ETH: {
		name: 'ethereum',
		chainId: 1,
	},
	BASE_SEPOLIA: {
		name: 'baseSepolia',
		chainId: 84532,
	},
} as const;

export const SUPPORTED_NETWORKS = [
	NETWORKS.ETH,
	NETWORKS.BASE_SEPOLIA,
] as const;

export const CONTRACT_NAMES = {
	RAYC: 'rayc',
	ZAYC: 'zayc',
	Z1: 'z1',
	LORE: 'lore',
	KEY: 'key',
	KEYMASTERS_POT: 'keymastersPot',
	LOOT: 'loot',
	LOOT_MINTER: 'lootMinter',
	LOOT_MINTER_2: 'lootMinter2',
	LOOT_MINTER_3: 'lootMinter3',
	LOOT_MAIN:'lootMain',
} as const;

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000' as const;

export const LORE_PIECE_STATUSES = {
	REVEALED: 'revealed',
	MINTING: 'minting',
	UNRELEASED: 'unreleased',
	PLACEHOLDER: 'placeholder',
} as const;

// TODO: replace all instances of below with the new map
export const LORE_METADATA_BASE_URI_DEV = 'https://odyssey-data-dev.rareapepes.com' as const;
export const LORE_METADATA_BASE_URI_PROD = 'https://odyssey-data.rareapepes.com' as const;

export const IMAGE_BASE_URIS = {
	[CONTRACT_NAMES.RAYC]: 'https://data.rareapepes.com',
	[CONTRACT_NAMES.ZAYC]: 'https://data.zombieapepes.com',
} as const;

export const API_BASE_URI_DEV = 'https://api.rareapepes.com' as const;
export const API_BASE_URI_PROD = 'https://api.rareapepes.com' as const;
export const API_BASE_URI_LOCAL = 'http://localhost:8080' as const;

export const RARIBLE_LINKS = {
	GENESIS:'https://rarible.com/rareapepes/items',
	APEPE_LOOT:'https://rarible.com/apepe-loot/items',
	ZOMBIES:'https://rarible.com/zombie-apepes/items',
	ODYSSEY_KEY: 'https://rarible.com/odyssey-key',
	THE_ORIGINALS_CHOICE:'https://rarible.com/token/0x1e50c58f9d26298a9c7c4f3050c43eb29ea4a0f0:1',
	Z1_SERUM:'https://rarible.com/z1-serum-ra',
} as const;


export const SOCIALS_LINKS = {
	TWITTER: 'https://twitter.com/RareApepesNFT',
} as const;


export const MINT_STATES = {
	CHECKING: 'checking',
	EXCEEDED: 'exceeded',
	ELIGIBLE: 'eligible',
} as const;

export const WALLET_TYPES : Record<string, WalletTypeValues> = {
	METAMASK: 'metamask',
	WALLET_CONNECT: 'walletconnect',
} as const;


export const SALE_STATUSES = {
	NOT_STARTED: 'NOT_STARTED',
	ACTIVE: 'ACTIVE',
	ENDED: 'ENDED',
} as const;