import DirectedArrowIcon from 'src/icons/DirectArrowIcon';
import styles from './tooltip.module.scss';
import clsx from 'clsx';
import useWallet from 'src/hooks/useWallet';
import useCheckDelegations from 'src/hooks/useCheckDelegations';
import { useStore } from 'base/state';
import React from 'react';

export default function ToolTip({ pushDown }) {
	const [, address] = useWallet(); // empty comma is deliberate, as we are skipping the first value in the array (connect);
	const {
		delegations:{ vaults },
	} = useCheckDelegations( address );
	const userHasDelegations = vaults && vaults.length > 0;
	const api = useStore( s => s.api );
	const toolTipShown = useStore( s => s.isToolTipShown );
	const timesShown = useStore( s => s.toolTipTimesShown );





	function hideToolTipAfterFiveSeconds(){
		if( !toolTipShown ){
			setTimeout( ()=>{
				api.setToolTipShown( true );
				api.setToolTipTimesShown( timesShown + 1 );
			}, 5000 );
		}
   
	}

	return (
		( userHasDelegations && !toolTipShown ) && <div className={`${styles['tool-tip']} ${pushDown && styles['tool-tip-push-down']}`}>
			<DirectedArrowIcon className={styles['tool-tip-arrow']}/>
			{hideToolTipAfterFiveSeconds()}
			<div className={clsx( '-f-family-burbank', styles['tool-tip-text'])}>
        YOU CAN SELECT YOUR VAULT WALLET FROM THE DROPDOWN
			</div>
		</div>
	);
}
