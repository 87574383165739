export default function CouncilIcon() {

	return <svg xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		width="32px"
		height="32px"
		style={{ shapeRendering:'geometricPrecision', textRendering:'geometricPrecision', imageRendering:'optimizeQuality', fillRule:'evenodd' }} >
		<g>
			<path style={{ opacity: '0.579' }}
				fill="#c0c0c0"
				d="M 14.5,-0.5 C 15.1667,-0.5 15.8333,-0.5 16.5,-0.5C 16.6079,1.7834 17.4412,3.7834 19,5.5C 23.678,8.10455 25.8447,12.1045 25.5,17.5C 27.5,17.5 29.5,17.5 31.5,17.5C 31.5,22.1667 31.5,26.8333 31.5,31.5C 31.1667,31.5 30.8333,31.5 30.5,31.5C 30.665,28.1501 30.4983,24.8168 30,21.5C 29.502,24.4816 29.3354,27.4816 29.5,30.5C 28.5,30.5 27.5,30.5 26.5,30.5C 26.665,27.1501 26.4983,23.8168 26,20.5C 25.5014,24.1516 25.3347,27.8183 25.5,31.5C 23.1667,31.5 20.8333,31.5 18.5,31.5C 19.5527,28.1485 19.5527,24.8151 18.5,21.5C 17.513,24.7683 17.1796,28.1016 17.5,31.5C 16.1667,31.5 14.8333,31.5 13.5,31.5C 13.8204,28.1016 13.487,24.7683 12.5,21.5C 11.4473,24.8151 11.4473,28.1485 12.5,31.5C 10.1667,31.5 7.83333,31.5 5.5,31.5C 5.6653,27.8183 5.49863,24.1516 5,20.5C 4.50166,23.8168 4.33499,27.1501 4.5,30.5C 3.5,30.5 2.5,30.5 1.5,30.5C 1.66463,27.4816 1.49796,24.4816 1,21.5C 0.501655,24.8168 0.334989,28.1501 0.5,31.5C 0.166667,31.5 -0.166667,31.5 -0.5,31.5C -0.5,26.8333 -0.5,22.1667 -0.5,17.5C 1.5,17.5 3.5,17.5 5.5,17.5C 5.15533,12.1045 7.32199,8.10455 12,5.5C 13.5588,3.7834 14.3921,1.7834 14.5,-0.5 Z M 14.5,6.5 C 16.4233,6.79495 18.0899,7.62829 19.5,9C 16.8333,9.66667 14.1667,9.66667 11.5,9C 12.5707,8.14825 13.5707,7.31491 14.5,6.5 Z" />
		</g>
	</svg>;

}
