import React from 'react';
import styles from './CommunityTools.module.scss';
import commonDashBoardStyles from '../../dashboard-common.module.scss';
import { DashboardCard } from 'src/ui/components';
import { communityToolCards } from './data';

export default function CommunityTools() {
	return (
		<div>
			<h2 className={`${commonDashBoardStyles['tab-title']} ${styles['community-tools-title']}`}>Community Tools</h2>
			<p className={`font-normal text-sm leading-5 mt-8 mb-8 ${styles['community-tools-paragraph']}`}>
				Yup, we have one of the tightest NFT communities out there, check out some of the community projects build by your fellow Apepe friends! Looking to do a project?
				<span className='mr-1 ml-1'>Tweet</span>
				your idea at us.
			</p>
			<div className='flex gap-10 flex-wrap'>
				{communityToolCards.map((card:any, index: number) => (
					<DashboardCard
						key={index}
						className={styles['community-tools-cards-item']}
						{...card}
					/>
				))}
			</div>
		</div>
	);
}
