import React from 'react';
import styles from './ConnectBar.module.scss';
import { CtaBar, ActionButton } from 'src/ui/components';


interface ConnectBarProps {
	onConnectWallet: () => void;
}


export default function ConnectBar({ onConnectWallet }: ConnectBarProps) {
	return (
		<CtaBar className='p-10'>
			<div className={`${styles['connect-bar-text']} text-2xl text-white uppercase -f-family-burbank`}>
				<span>CONNECT&nbsp;</span>
				YOUR WALLET TO USE THE APEPE DASHBOARD!
			</div>
			<ActionButton className={`w-auto h-auto flex items-center leading-1 py-5 px-6 ${styles['connect-bar-button']}`}
				textContent="Connect wallet"
				onClick={onConnectWallet} />
		</CtaBar>
	);
}
