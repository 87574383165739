import React, { useState } from 'react';

const templates = [
	{
		title: 'Aliens',
		imageSrc: '/images/memes/Aliens.jpg',
	},
	{
		title: 'Ben',
		imageSrc: '/images/memes/Ben.jpg',
	},
	{
		title: 'Crying man book',
		imageSrc: '/images/memes/crying_man_book.jpg',
	},
	{
		title: 'Disappointed',
		imageSrc: '/images/memes/disappointed.jpg',
	},
	{
		title: 'Drake Meme',
		imageSrc: '/images/memes/drake_meme.jpg',
	},
	{
		title: 'Tell me more',
		imageSrc: '/images/memes/empty_WONKA.jpg',
	},
	{
		title: 'Farm',
		imageSrc: '/images/memes/Farm.jpg',
	},
	{
		title: 'Take my money',
		imageSrc: '/images/memes/take_my_money.jpg',
	},
	{
		title: 'Woman yelling at cat',
		imageSrc: '/images/memes/yelling_at_cat.jpg',
	},
	{
		title: 'Will Apepe',
		imageSrc: '/images/memes/willApepe.png',
	},
	{
		title: 'Willy Wonka',
		imageSrc: '/images/memes/willyWonka.png',
	},
	{
		title: 'Cheeky discussion',
		imageSrc: '/images/memes/apepeHat.png',
	},
	{
		title: 'The NemoApepe',
		imageSrc: '/images/memes/nemoapepe.png',
	},
	{
		title: 'The Ancient Race',
		imageSrc: '/images/memes/TurtleAndRabbit.png',
	},
];

export default function useMemeGenerator() {
	const [memes, setMemes] = useState( templates );
	const [ModalMemeImageElement, setModalMemeImageElement] = useState();
	const colorPickerPresetColors = ['#ffffff', '#000000', '#905857', '#EB483F', '#EF8E34', '#B3FE00', '#099227', '#56BCEE', '#3333F5', '#CB2BC6'];

	const updateMemeAttribute = ({ id, attributeKey, value }) => {
		const memeToBeUpdated = memes.find( ( meme ) => meme.id === id );
		if ( memeToBeUpdated ) {
			memeToBeUpdated[`${attributeKey}`] = value;
			updateMeme( memeToBeUpdated );
		}
	};

	const updateMeme = ( updatedMemeTemplate ) => {
		const updatedMemesTemplates = memes.map( ( meme ) => {
			if ( meme.id === updatedMemeTemplate.id ) return updatedMemeTemplate;
			else return meme;
		});
		setMemes( updatedMemesTemplates );
	};



	const updateMemeTemplates = ( memes ) => setMemes( memes );


	return { memes, updateMemes: updateMemeTemplates, updateMemeAttribute, presetColors: colorPickerPresetColors, setModalMemeImageElement, ModalMemeImageElement };
}
