import React from 'react';
import { useRouter } from 'next/router';

export function useRouteTracker() {
	const router = useRouter();
	const [didRouteChange, setDidRouteChange] = React.useState( false );
	const [routerPath, setRouterPath] = React.useState( '/' );

  

	React.useEffect( () => {

		const handleRouteChange = ( url ) => {
			setRouterPath( url );
			setDidRouteChange( true );
		};

		router.events.on( 'routeChangeStart', handleRouteChange );

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off( 'routeChangeStart', handleRouteChange );
		};
	}, []);

	return [didRouteChange, routerPath];
}
