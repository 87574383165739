import { useState, useEffect } from 'react';
import { TEAM_ADDRESSES } from 'src/config';


export const useFeatureFlag = ({ globalFeatureFlag, address }) => {
	const [origin, setOrigin] = useState();

	useEffect( () => {
		setOrigin( window.location.origin );
	}, []);

	if ( typeof window !== undefined && origin !== undefined ) {
		const isPreviewOrigin = origin.includes( 'preview' ) && origin.includes( 'apepelab' );
		const isLocalOrigin = origin.includes( 'localhost' );

		// enabling if its local or preview
		if ( isPreviewOrigin || isLocalOrigin ) {
			return true;
		}
	}

	// if not go to team address check flow

	if ( !globalFeatureFlag ) {
		return true;
	}

	if ( !address ) {
		return globalFeatureFlag;
	}


	const isTeamAddress = TEAM_ADDRESSES.map( x => x.toLowerCase() ).includes( address.toLowerCase() );

	return globalFeatureFlag || isTeamAddress;
};
