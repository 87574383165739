
//	Dependencies
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

//	App
import { isClient } from './utils';


//
//	RAYC / Base / State
//


let viewport = [ 0, 0 ];

if ( isClient() ) {
	viewport = [ window.innerWidth, 0 ];
}

export const useStore = create( subscribeWithSelector( set => ({
	viewport,
	bodyHeight: null,
	planes: [],
	
	isMenuOpen: false,
	isLoaded: false,
	isViewportReady: false,
	isModalOpen: false,
	isToolTipShown:false,
	toolTipTimesShown:0,

	api: {
		setViewport: ( newViewport, bodyHeight ) => set( state => {
			const viewport = newViewport?.[1] > 0 ? newViewport : state.viewport;
			const isViewportReady = viewport[1] > 0;

			return {
				...isViewportReady && { viewport },
				bodyHeight,
				isViewportReady,
			};
		}),
		
		setIsLoaded: ( isLoaded ) => set( () => ({ isLoaded }) ),
		setIsModalOpen: ( isModalOpen ) => set( () => ({ isModalOpen }) ),
		setToolTipShown: ( isToolTipShown ) => set( () => ({ isToolTipShown }) ),
		setToolTipTimesShown: ( toolTipTimesShown ) => set( () => ({ toolTipTimesShown }) ),
		toggleMenuView: () => { set( ( state ) => ({ isMenuOpen: !state.isMenuOpen }) ); },

		registerPlane: ( id, plane ) => set( state => ({
			planes: new Map( state.planes ).set( id, plane ),
		}) ),
	},

}) ) );
