import { Image } from 'ui/components';

const CountManager = ({
	wrapperClassName,
	countValueClassName,
	connected,
	getCountBtnClasses,
	decreaseCount,
	count,
	increaseCount,
	iconSize,
}) => {
	if ( connected ) {
		return (
			<div className={wrapperClassName}>
				<Image
					src="/images/minus.png"
					className={`cursor-pointer${ getCountBtnClasses( 'minus' ).join( ' ' )}`}
					width={iconSize ?? 52}
					height={iconSize ?? 52}
					onClick={decreaseCount}
					alt="minus"
					style={{ cursor: 'pointer' }}
				/>
				<div className={countValueClassName}>
					{count}
				</div>
				<Image
					src="/images/plus.png"
					className={`cursor-pointer${ getCountBtnClasses( 'plus' ).join( ' ' )}`}
					width={iconSize ?? 52}
					height={iconSize ?? 52}
					onClick={increaseCount}
					alt="plus"
					style={{ cursor: 'pointer' }}
				/>
			</div>
		);
	}
};

export default CountManager;
