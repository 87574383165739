import React, { useEffect } from 'react';
import { Image } from 'ui/components';
import { gsap } from 'gsap';

export default function LoadingOverlay({ show, text }) {
	const styles = {
		overlay: {
			width: '100%',
			height: '100%',
			position: 'fixed',
			top: 0,
			left: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.8)',
			zIndex: 999,
		},
		innerContainer: {
			flexDirection: 'column',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '53rem',
			height: 'auto',
			position: 'absolute',
			top: '40%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
		text: {
			color: '#04af2c',
			textShadow:
        '-1px -1px 0 #29e85f, 1px -1px 0 #29e85f, -1px 1px 0 #29e85f, 1px 1px 0 #29e85f',
			fontSize: '3.0rem',
			margin: '1.5rem 0',
			animation: 'pulse 2s infinite',
			textTransform: 'uppercase',
			textAlign: 'center',
			width: '90vw',
		},
		gif: {
			display: 'block',
		},
		imageContainer: {
			maxWidth: '100vw',
		},
	};

	useEffect( () => {
		if ( show === true ) {
			gsap.to( window, { duration: 1, scrollTo: { y: '#LoreLoadingOverlay_ImageContainer', offsetY: 200 }});
		}
	}, [show]);

	return (
		show === true && (
			<div style={styles.overlay}
				id="LoreLoadingOverlay">
				<div style={styles.innerContainer}>
					<div style={styles.text}>{text || 'COMPUTING...'}</div>
					<div id="LoreLoadingOverlay_ImageContainer"
						style={styles.imageContainer}>
						<Image src="/images/odyssey/loading.gif"
							styles={styles.gif}
							alt="loadingGif"
							width={800}
							height={800} />
					</div>
				</div>
			</div>
		)
	);
}
