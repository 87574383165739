// AlbumHeader.js
import React from 'react';
import clsx from 'clsx';
import styles from './Album.module.scss';

function AlbumHeader({ chapterId }) {
	return (
		<>
			<div className={`mt-5 py-[5px] px-5  ${styles['oddysey-album_header_wrapper']}`}>
				<div
					className={`-f-family-burbank text-center uppercase  inline-block text-[32px] bg-black r ${styles['oddysey-album_header']}`}
				>
					CHAPTER
					{chapterId}
				</div>
			</div>
			<p className='text-center font-normal text-sm margin-[24px] w-[467px] leading-loose max-[500px]:text[13px] max-[500px]:w-[90%]'>
				Initially released as a meme, it has been discovered that there is more to Apepes than what meets the eye. The Apepe Odyssey is the chronicles of what we have learned so far from our journey to discover the truth about Apepes.
			</p>
		</>
	);
}

export default AlbumHeader;
