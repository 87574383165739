import { Button } from 'ui/components';

const MintButton = ({ connected, styles, connect, onClickMint }) => {

	if ( connected ) {
		return ( <Button className={styles['apepe-odyssey_main_right_mint_btn']}
			onClick={onClickMint}>
            Mint
		</Button> );
	}
	else {
		return ( <Button className={styles['apepe-odyssey_main_right_mint_btn']}
			onClick={connect}
			icon="fox"
			iconPos="before">
            Connect Wallet
		</Button> );
	}


};

export default MintButton;
