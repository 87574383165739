import React from 'react';
import styles from './DashboardFooter.module.scss';
import cards from './dummyCards.js';
import { Link } from 'src/ui/components';

interface CardProps {
  username: string;
  image: string;
  paragraph: string;
  cta: {
    link: string;
    text: string;
  };
}

function DashboardFooterCard({ username, image, paragraph, cta }: CardProps) {
  return (
    <div className={styles['dashboard-footer-cards-item']}>
      <div
        className={`w-full bg-cover bg-center ${styles['dashboard-footer-cards-item-upper']}`}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className={`text-white flex items-center p-4 ${styles['dashboard-footer-cards-item-upper-info']}`}>
          <div className={`rounded-full w-8 h-8 mr-3 ${styles['dashboard-footer-cards-item-avatar']}`} />
          <div className='font-semibold text-xs leading-4'>
            {username}
          </div>
        </div>
      </div>
      <div className={`w-full p-4 bg-black box-border ${styles['dashboard-footer-cards-item-lower']}`}>
        <p className='font-normal m-0 text-xs leading-4 text-white opacity-80 mb-4'>
          {paragraph}
        </p>
        <Link
          className={`py-1 px-2.5 capitalize text-xs ${styles['dashboard-footer-cards-item-cta']}`}
          href={cta.link || ''}
        >
          {cta.text}
        </Link>
      </div>
    </div>
  );
}

export default function DashboardFooter() {
  const showFeaturedSocial: boolean = false;

  if (!showFeaturedSocial) {
    return <></>;
  }

  return (
    <div className={`w-full h-auto ${styles['dashboard-footer']}`}>
      <h2 className='text-5xl max-[width:400px]:text-4xl max-[width:330px]:text-2xl'>
        Featured Social
      </h2>
      <div className={`w-auto h-auto flex justify-start items-center overflow-x-auto scroll-smooth pr-32 ${styles['dashboard-footer-cards']}`}>
        {cards.map((card: CardProps, index: number) => (
          <DashboardFooterCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
}
