import React from 'react';
import CrossIcon from 'src/icons/Cross';
import styles from './modal.module.scss';
import WalletModal from './WalletModal';
import DelegateCashModal from './DelegateCashModal';
import { useStore } from 'base/state';


const ModalRoutes = {
	BASE: '/',
	DELEGATE: '/delegate', 
};

export default function Modal() {
	const [modalRoute, setModalRoute] = React.useState( ModalRoutes.BASE );
	const updateModalVariantToDelegate = () => setModalRoute( ModalRoutes.DELEGATE );
	const isModalOpen = useStore( s => s.isModalOpen );
	const api = useStore( s => s.api );

	function closeModal() {
		api.setIsModalOpen( false );
	}

	const ModelVariant = () => {
		switch ( modalRoute ) {
			case ModalRoutes.BASE:
				return <WalletModal delegateCashOnClick={updateModalVariantToDelegate}
					closeModal={closeModal} />;
			case ModalRoutes.DELEGATE:
				return <DelegateCashModal />;
		}
	};

	return (
		isModalOpen && (
			<div className={styles['modal-container']}>
				<div
					className={styles['modal-overlay']}
					onClick={closeModal}
				>
					{/* stopping propgation so event does not go up to overlay and also trigger a close event */}
					<div className={styles['modal-content']}
						onClick={( e ) => e.stopPropagation()}>
						<div className={styles['modal-content-control-bar']}>
							<CrossIcon className={styles['modal-content-icon']}
								onClick={closeModal} />
							{modalRoute !== ModalRoutes.BASE && <button onClick={() => setModalRoute( ModalRoutes.BASE )}
								className={styles['modal-content-back-btn']}> 
								{' '}
								{'<'}
								{' '}
Back
							</button>}
						</div>
						<ModelVariant />
					</div>
				</div>
			</div>
		)
	);
}
