
import { useLockBodyScroll } from '@uidotdev/usehooks';
import CrossIcon from 'src/icons/Cross';
import gsap from 'gsap';

export const OdysseyImageModal = ({ imageUrl, onCloseClick }) => {
	useLockBodyScroll();

	gsap.to( window, {
		duration: 1,
		scrollTo: { y: '#odyyssey-piece-modal-image', offsetY: 100 },
	});

	return <div id='odyyssey-piece-modal-image'
		style={{ backgroundColor: 'rgba(0,0,0,.8)' }}
		className="w-screen h-screen  flex items-center justify-center absolute top-0 left-0  z-[2]">
		<div style={{ backgroundImage: `url(${imageUrl})` }}
			className='relative w-[700px] h-[600px]  bg-center bg-contain bg-no-repeat  bg-red'>
			<CrossIcon className="absolute right-0 top-0 cursor-pointer"
				onClick={onCloseClick} />
		</div>
	</div>;
};
