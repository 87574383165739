import clsx from 'clsx';
import styles from './Piece.module.scss';

const InfoItem = ({ infoItem }) => {
	const getInfoItemBoldClasses = () => {
		return [
			'-f-family-burbank',
			'font-bold text-[20px]',
		];
	};


	return (
		<div className={`text-[16px] max-[1000px]:text-[14px] ${styles['apepe-odyssey_main_right_info_item']}`}>
			<div className='font-semibold uppercase mb-[11px] text-[#b3fe00] '>
				{infoItem.title}
			</div>
			<div
				className={clsx(
					'text-[#d9d9d9] font-[400] max-w-[500px] ',
					infoItem.paragraph.isBold ? [...getInfoItemBoldClasses()] : '',
				)}
			>
				{infoItem.paragraph.content}
			</div>
		</div>
	);
};

export default InfoItem;
