import { useState, useEffect } from 'react';
import { chainIdToName } from 'src/base/utils';
import { NETWORKS } from 'src/base/constants';
import { ethers } from 'ethers';

function useNetwork() {
	const [network, setNetwork] = useState( null );
	const [error, setError] = useState( null );

	const switchToCorrectNetwork = async ( correctNetworkId = NETWORKS.ETH.chainId ) => {
		try {
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: `0x${correctNetworkId.toString( 16 )}` }],
			});
			location.reload();
		} catch ( switchError ) {
			console.error( switchError );
		}
	};

	useEffect( () => { 
		if ( typeof window === 'undefined' || !window.ethereum ) {
			return;
		}

		async function getNetwork() {
			try {
				const providerInstance = new ethers.BrowserProvider( window.ethereum );
				const networkObj = await providerInstance.getNetwork();
				if ( !networkObj ) throw new Error( 'useNetwork:No network object' );
				const chainId = networkObj.chainId;
				if ( !chainId ) throw new Error( 'useNetwork: No chainId' );
				const networkName = chainIdToName( Number( chainId ) );
				setNetwork( networkName );
			} catch ( err ) {
				console.error( 'useNetwork: Error getting network:', err );
				setError( err );
			}
		}

		// Define the handler outside so we can use it in both listener and cleanup
		const handleChainChange = ( newNetworkId ) => {
			setNetwork( chainIdToName( parseInt( newNetworkId, 10 ) ) );
		};

		// Add event listener for network change
		window.ethereum.on( 'chainChanged', handleChainChange );

		// Initial network check
		getNetwork();

		// Clean up event listener on component unmount
		return () => {
			if ( window.ethereum && window.ethereum.removeListener ) {
				window.ethereum.removeListener( 'chainChanged', handleChainChange );
			}
		};
	}, []);

	return { network, error, switchToCorrectNetwork };
}

export default useNetwork;
