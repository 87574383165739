import { useState, useEffect } from 'react';

// this is for finding out what tokenIds already exists for a given ERC1155
const useTokenIds = ( contract ) => {
	const [tokenIds, setTokenIds] = useState([]);
	const [loading, setLoading] = useState( true );
	const [error, setError] = useState( null );

	useEffect( () => {
		const fetchTokenIds = async () => {
			try {
				if ( !contract ) {
					setLoading( false );
					return;
				}

				const fetchedTokenIds = [];
				let tokenId = 0;
				let tokenExists = await contract.exists( tokenId );

				while ( tokenExists ) {
					fetchedTokenIds.push( tokenId );
					tokenId += 1;
					tokenExists = await contract.exists( tokenId );
				}

				setTokenIds( fetchedTokenIds );
				setLoading( false );
			} catch ( err ) {
				setError( err );
				setLoading( false );
			}
		};

		fetchTokenIds();
	}, [contract]);

	if ( error ) {
		console.error( 'useTokenIds error below' );
		console.error( error );
	}

	return { tokenIds, loading, error };
};

export default useTokenIds;
