import React from 'react';

export default function FireIcon() {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6 2.85404C11.7872 2.85344 11.9709 2.90528 12.13 3.00367C14.54 4.5099 20 8.60965 20 14.874C20 19.2812 16.4183 22.854 12 22.854C7.58172 22.854 4 19.2812 4 14.874C4 12.6137 4.86326 11.2593 5.66807 9.99657C6.28396 9.03029 6.86561 8.1177 7 6.89394C6.99739 6.73124 7.07612 6.57792 7.21 6.48496C7.34493 6.41572 7.50507 6.41572 7.64 6.48496C8.56773 6.88853 9.3993 7.48346 10.08 8.2306C10.2001 8.41614 10.4283 8.50075 10.6408 8.43855C10.8533 8.37635 10.9994 8.18215 11 7.96127V3.35279C11 3.07734 11.2239 2.85404 11.5 2.85404H11.6ZM8 16.8191C8 19.0228 9.79086 20.8091 12 20.8091C14.2091 20.8091 16 19.0228 16 16.8191C15.855 14.0331 13.9629 11.6419 11.28 10.854C11.1483 10.8357 11.0253 10.9234 11 11.0535C10.8887 11.6563 10.3917 12.2655 9.83326 12.9498C8.98713 13.9868 8 15.1966 8 16.8191Z"
			/>
		</svg>
	);
}
