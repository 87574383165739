//	Dependencies
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useTransition, animated as a } from '@react-spring/web';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { gsap } from 'gsap';
import clsx from 'clsx';
import _invoke from 'lodash-es/invoke';
import { RARIBLE_LINKS, SOCIALS_LINKS } from 'src/base/constants';

//	App
import { useStore } from 'base/state';
import {
	Link,
	Icon,
} from 'ui/components';
import { useIsMobile } from 'src/hooks';
import useWallet from 'src/hooks/useWallet';

import {
	SHOW_CONNECT_WALLET,
} from 'src/config';

// Styles
import styles from './menu.module.scss';
import MenuItems from './menuItems.js';

//
//	RAYC / UI / App / Menu
//

// Note: this is for mobile



export default function Menu({ variation = 'default' }) {
	const api = useStore( ( s ) => s.api );
	const _isMenuOpen = useStore( ( s ) => s.isMenuOpen );
	const menuRef = useRef();
	const isMobile = useIsMobile();
	const [connect, address, disconnect] = useWallet();
	const [showConnectButton, setShowConnectButton] = useState( false );
	useEffect( () => {
		setShowConnectButton( SHOW_CONNECT_WALLET && !address );
	}, [address]);

	const contextTransition = useTransition( _isMenuOpen, {
		from: { opacity: 0, x: 0, y: -50 },
		initial: { opacity: 1, x: 0, y: 0 },
		enter: { opacity: 1, x: 0, y: 0 },
		leave: { opacity: 0, x: 0, y: -50, position: 'absolute' },
		config: { mass: 0.1, friction: 20, tension: 210 },
	});

	useEffect( () => {
		if ( _isMenuOpen && !isMobile ) api.toggleMenuView();
	}, [isMobile, _isMenuOpen, api]);

	useEffect( () => {
		( _isMenuOpen ? disableBodyScroll : clearAllBodyScrollLocks )(
			menuRef.current,
			{ reserveScrollBarGap: true },
		);
	}, [_isMenuOpen]);






	const _handleNavClick = ( sectionKey ) => {
		api.toggleMenuView();
		gsap.to( window, {
			duration: 1,
			scrollTo: { y: `#${sectionKey}`, offsetY: 0 },
		});
	};

	return (
		<div
			role="dialog"
			ref={menuRef}
			className={clsx( `fixed top-0 right-0 bottom-0 left-0 z-[90] flex flex-col flex-nowrap items-center uppercase overflow-hidden pointer-events-none	 ${styles['a-menu']}`, { ['-is-active']: _isMenuOpen })}
		>
			{contextTransition &&
				contextTransition( ( styleProps, isOpen ) => {
					if ( !isOpen ) return null;
					return (
						<>
							<a.div
								tab-index="-1"
								className={`absolute top-0 right-0 left-0 z-[10] w-auto h-auto m-0 box-border flex flex-col flex-nowrap overflow-hidden  ${styles['a-menu_container']}`}
								style={{
									...styleProps,
									transform: _invoke(
										styleProps,
										'xto',
										( x ) => `translate3d(${x * -0.1}%,0,0)`,
									),
								}}
							>
								<div className="grid -g-cols-1">
									<div className={clsx( '-ha-center', ` flex flex-col items-center ${styles['a-menu_nav']}` )}>
										<div className='h-full flex flex-col items-center'>
											<MenuItems variation={variation}
												address={address}
												disconnect={disconnect}
												api={api}
												showConnectButton={showConnectButton} />

											<div className={`flex flex-nowrap items-center ${styles['a-menu_socials']}`}>
												<Link
													href="https://discord.gg/FN3GZqJ9w9"
													className="-style-headline"
													external
												>
													<Icon type="discord" />
												</Link>
												<Link
													href={RARIBLE_LINKS.GENESIS}
													className="-style-headline"
													external
												>
													<Icon type="rarible" />
												</Link>
												<Link
													href={SOCIALS_LINKS.TWITTER}
													className="-style-headline"
													external
												>
													<Icon type="twitter" />
												</Link>
											</div>
										</div>
									</div>
								</div>
							</a.div>
							<div
								className={styles['a-menu_overlay']}
								aria-hidden="true"
								onClick={() => api.toggleMenuView()}
								onTouchStart={() => api.toggleMenuView()}
							/>
						</>
					);
				})}
		</div>
	);
}
