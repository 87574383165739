
//	Dependencies
import React from 'react';
import clsx from 'clsx';

// Styles
import styles from './icon.module.scss';
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon';
import Fox from '../../../icons/fox';
import LogoutIcon from '../../../icons/Logout';
import ChevronDownIcon from '../../../icons/ChevronDown';
import FoxLarge from '../../../icons/foxLarge';
import Home from '../../views/Dashboard/Tabs/Home/Home';
import UsefulLinks from '../../views/Dashboard/Tabs/UsefulLinks/UsefulLinks';
import YourCollection from '../../views/Dashboard/Tabs/YourCollection/YourCollection';
import CommunityProjects from '../../views/Dashboard/Tabs/CommunityProjects/CommunityProjects';
import RaribleIcon from '../../../icons/RaribleIcon';


//
//	RAYC / UI / Components / Icon
//

interface IconMapType {
	[key: string]: JSX.Element;
}

export const IconMap: IconMapType = {
	'cross': <path fillRule="evenodd" clipRule="evenodd" d="M17.774 19.317a1.091 1.091 0 0 0 1.543-1.543L13.543 12l5.774-5.774a1.091 1.091 0 0 0-1.543-1.543L12 10.457 6.226 4.683a1.09 1.09 0 1 0-1.543 1.543L10.457 12l-5.774 5.774a1.09 1.09 0 0 0 1.543 1.543L12 13.543l5.774 5.774Z" />,
	'discord': <path d="M19.777 5.327a17.317 17.317 0 0 0-4.274-1.326.065.065 0 0 0-.069.033c-.184.328-.39.757-.532 1.094a15.987 15.987 0 0 0-4.8 0c-.16-.375-.341-.74-.541-1.094A.067.067 0 0 0 9.49 4a17.27 17.27 0 0 0-4.274 1.326.061.061 0 0 0-.028.024c-2.722 4.067-3.468 8.035-3.102 11.953a.072.072 0 0 0 .027.05 17.416 17.416 0 0 0 5.244 2.65.068.068 0 0 0 .074-.024c.405-.551.763-1.135 1.072-1.745a.066.066 0 0 0-.036-.093 11.544 11.544 0 0 1-1.638-.78.068.068 0 0 1-.034-.054.067.067 0 0 1 .027-.058c.11-.082.22-.168.326-.255a.067.067 0 0 1 .068-.009c3.436 1.57 7.157 1.57 10.553 0a.065.065 0 0 1 .07.008c.104.087.214.174.325.256a.067.067 0 0 1-.006.112 10.69 10.69 0 0 1-1.639.78.067.067 0 0 0-.035.093c.315.61.675 1.192 1.072 1.744a.066.066 0 0 0 .073.025 17.356 17.356 0 0 0 5.253-2.65.067.067 0 0 0 .027-.05c.437-4.529-.734-8.463-3.105-11.952a.053.053 0 0 0-.028-.025Zm-10.76 9.591c-1.034 0-1.886-.95-1.886-2.116 0-1.167.835-2.117 1.887-2.117 1.06 0 1.903.958 1.887 2.117 0 1.167-.836 2.116-1.887 2.116Zm6.979 0c-1.036 0-1.888-.95-1.888-2.116 0-1.167.836-2.117 1.888-2.117 1.06 0 1.903.958 1.887 2.117 0 1.167-.828 2.116-1.887 2.116Z" />,
	'menu': <path d="M3 8h18a1 1 0 000-2H3a1 1 0 000 2zm18 8H3a1 1 0 000 2h18a1 1 0 000-2zm0-5H3a1 1 0 000 2h18a1 1 0 000-2z" />,
	'twitter': <path d="M21.97 5.464a7.991 7.991 0 0 1-1.846 1.778v.55a12.19 12.19 0 0 1-3.621 8.69A12.178 12.178 0 0 1 7.773 20 12.217 12.217 0 0 1 2.33 18.74a.25.25 0 0 1-.15-.23v-.11a.26.26 0 0 1 .26-.26 8.844 8.844 0 0 0 5.112-1.837 4.363 4.363 0 0 1-3.884-2.558.26.26 0 0 1 .26-.36c.51.052 1.025.004 1.518-.14A4.355 4.355 0 0 1 2 9.44a.26.26 0 0 1 .36-.26c.497.22 1.033.335 1.577.34A4.277 4.277 0 0 1 2.23 4.585a.54.54 0 0 1 .91-.18 12.339 12.339 0 0 0 8.396 3.876 4 4 0 0 1-.12-1 4.352 4.352 0 0 1 7.499-2.937 8.674 8.674 0 0 0 2.387-.828.17.17 0 0 1 .18 0 .17.17 0 0 1 0 .18 4.365 4.365 0 0 1-1.758 1.998 8.563 8.563 0 0 0 1.997-.48.16.16 0 0 1 .18 0 .17.17 0 0 1 .07.25Z" />,
	'fox': <Fox />,
	'foxLarge': <FoxLarge />,
	'arrowLeft': <ArrowLeftIcon />,
	'logout': <LogoutIcon />,
	'chevronDown': <ChevronDownIcon />,
	'dashboardDefault': <Home />,
	'dashboardUsefulLinks': <UsefulLinks />,
	'dashboardYourCollection': <YourCollection />,
	'dashboardCommunityProject': <CommunityProjects />,
	'rarible': <RaribleIcon />,
};
interface IconProps {
	className?: string;
	style?: React.CSSProperties;
	type: string;
	size?: string;
	format?: string; // Add format prop to the interface
	colour?: string;
}


export default function Icon(props: IconProps) {
	const { className, style, type, size, format, colour = 'currentColor', ...restProps } = props;

	const iconPath = IconMap[type];
	const sizeClass = size && styles[`-size-${size}`] || null;
	const classes = clsx(styles['c-icon'], className, sizeClass);

	return (
		<span className={classes} {...restProps} style={style}>
			<svg xmlns="http://www.w3.org/2000/svg"
				fill={colour}
				stroke="none"
				viewBox="0 0 24 24"
				strokeLinecap="round"
				strokeLinejoin="round"
				preserveAspectRatio="xMidYMid meet">
				{iconPath && iconPath}
			</svg>
		</span>
	);
}
