
//	Dependencies
import React from 'react';

// UI components
import { Image } from 'src/ui/components';

// hooks

// others
import KeyForgeCommonStyles from '../KeyForge.module.scss';



export default function KeyForgeHero() {
	return <div className='flex justify-between items-center mt-[70px] mb-[74px] h-auto w-3/4 max-[900px]:flex-col max-[900px]:w-[55%] max-[900px]:mt-5 max-[900px]:mb-10 max-[700px]:w-[65%] max-[500px]:w-[75%]'>
		<div className='relative w-1/2 mr-[15px] max-[900px]:w-auto max-[900px]:h-auto max-[900px]:mb-[5px]'>
			<h4 className={`text-[85px] pt-[17.5px] leading-[110%] max-[1150px]:text-[75px] max-[1020px]:text-[67.5px] max-[900px]:mb-[30px] max-[700px]:text-[50px] max-[360px]:text-[45px] max-[320px]:text-[40px] ${KeyForgeCommonStyles['key-forge-header']}`}>Key Forge</h4>
			<Image
				className={KeyForgeCommonStyles['key-forge-img-key']}
				src="/images/key.png"
				width={110}
				height={133}
				alt="Key"
			/>
		</div>
		<div className='w-1/2 text-[#d9d9d9] font-normal text-[14px] max-w-[588px] leading-[140%] h-[80%] max-[900px]:w-full max-[900px]:text-center '>
            The Odyssey Key is an NFT that gives its holders the exclusive status of Keymasters for Odyssey Chapter 1. Keymasters are entitled to a share of the Keymasters&apos; Pot. The Keymasters&apos; Pot is distributed to Keymasters in proportion to the number of Keys they hold. The more Keys one hold, the more they will receive.
		</div>
	</div>;
}
