import { useState, useEffect } from 'react';

// note: this was created before the API suported getting balances. avoid using this

const useERC1155Balances = ( address, contract, tokenIds ) => {
	const [balances, setBalances] = useState([]);
	const [loading, setLoading] = useState( true );
	const [error, setError] = useState( null );

	useEffect( () => {
		const fetchBalances = async () => {
			
			try {

				if ( !address || !contract || !tokenIds || tokenIds.length === 0 ) {
					setLoading( false );
					return;
				}

				const fetchedBalances = await Promise.all(
					tokenIds.map( async ( tokenId ) => {
						const balance = await contract.balanceOf( address, tokenId );
						return { tokenId, balance: balance.toString() };
					}),
				);

				setBalances( fetchedBalances );
			} catch ( err ) {
				setError( err );
			} finally {
				setLoading( false );
			}
		};

		fetchBalances();
	}, [address, contract, tokenIds]);

	if ( error ) {
		console.log( 'useERC1155Balances error :', error );
	}

	return { balances, loading, error };
};

export default useERC1155Balances;
