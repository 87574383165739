import React from 'react';
import styles from './KeyForgeAnimation.module.scss';

const KeyForgeAnimation = () => {
	return (
		<div className={styles['key-forge-animation']}
			id='key-forge-animation'>
			<video autoPlay
				width="100%"
				height="100%">
				<source src="/animation/key-forging.mp4" />
			</video>
		</div>
	);
};

export default KeyForgeAnimation;
