import React from 'react';

export default function DashboardLayoutIcon() {
	return (
		<svg
			width="23"
			height="24"
			viewBox="0 0 23 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.25 2V22C10.25 22.6904 9.69036 23.25 9 23.25H1.5C0.809644 23.25 0.25 22.6904 0.25 22V2C0.25 1.30964 0.809644 0.75 1.5 0.75H9C9.69036 0.75 10.25 1.30964 10.25 2ZM14 8.25H21.5C22.1904 8.25 22.75 7.69036 22.75 7V2C22.75 1.30964 22.1904 0.75 21.5 0.75H14C13.3096 0.75 12.75 1.30964 12.75 2V7C12.75 7.69036 13.3096 8.25 14 8.25ZM21.5 23.25H14C13.3096 23.25 12.75 22.6904 12.75 22V12C12.75 11.3096 13.3096 10.75 14 10.75H21.5C22.1904 10.75 22.75 11.3096 22.75 12V22C22.75 22.6904 22.1904 23.25 21.5 23.25Z"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_3152_668"
					x1="11.5"
					y1="-8.55289"
					x2="-0.882015"
					y2="-7.84669"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="--var(-color-one)" />
					<stop offset="1"
						stopColor="--var(color-two)" />
				</linearGradient>
			</defs>
		</svg>
	);
}
