import { useState, useEffect } from 'react';
import useCheckDelegations from './useCheckDelegations';
import { Address } from 'src/base/types';

function useActiveAddress( address: Address ) {
	const [activeAddress, setActiveAddress] = useState( null );
	const { selectedVault } = useCheckDelegations( address );

	useEffect( () => {
		if ( address === null ) return;
		if ( selectedVault !== null && selectedVault ) setActiveAddress( selectedVault );
		else setActiveAddress( address );
	}, [address, selectedVault]);

	return activeAddress;

}

export default useActiveAddress;
