import React, { useState } from 'react';
import styles from './MemeGenerator.module.scss';
import commonDashBoardStyles from '../../dashboard-common.module.scss';
import useMemeGenerator from 'src/ui/views/Dashboard/Tabs/MemeGenerator/useMemeGenerator';
import TemplateIcon from 'src/icons/TemplateIcon';
import MemeCard from './MemeCard/MemeCard';
import MemeTemplateEditor from './MemeTemplateEditor/MemeTemplateEditor';
import MemeModal from './MemeModal/MemeModal';
import { gsap } from 'gsap';
import Label from 'src/ui/components/Label';
import LoadingOverlay from 'src/ui/components/LoadingOverlay';


// Note
// Please ensure that meme images width is set to 2000px
// you can scale image down while maintaing aspect ratio
// you can use https://www.reduceimages.com/

export default function MemeGenerator() {
	const { memes, presetColors, setModalMemeImageElement, ModalMemeImageElement } = useMemeGenerator();
	const [selectedMemeTemplate, setSelectedMemeTemplates] = useState();
	const [showMemeModal, setShowMemeModal] = useState( false );
	const [memeDimensions, setMemeDimensions] = useState();

	const onSave = ( dimensions ) => {
		setShowMemeModal( true );
		setMemeDimensions( dimensions );
	};

	const onModalClose = () => {
		setShowMemeModal( false );
	};

	
	const selectMemeCard = ( meme ) => {
		setSelectedMemeTemplates( meme );
		gsap.to( window, { duration: 1, scrollTo: { y: '#meme-editor', offsetY: 100 }});
	};

	return (
		<div className={styles['meme-generator']}>
			<h2 className={commonDashBoardStyles['tab-title']}>Meme Generator</h2>
			<div className="mb-4">
				<Label text="version 0"
					color='#b3fe00' />
			</div>
			<h3 className={styles['meme-generator-title']}>
				<span className={styles['meme-generator-title-icon']}><TemplateIcon /></span>
				Select template
			</h3>
			<div className={styles['meme-generator-template-container']}>
				{memes?.map( ( meme, i ) => {
					const isSelected = JSON.stringify( meme ) === JSON.stringify( selectedMemeTemplate );
					return <MemeCard isSelected={isSelected}
						onClick={() => selectMemeCard( meme )}
						key={i}
						{...meme} />;
				})}
			</div>

			<div className={styles['meme-generator-template-editor']}>
				{selectedMemeTemplate && <MemeTemplateEditor key={selectedMemeTemplate.title}
					meme={selectedMemeTemplate}
					onSave={onSave}
					saveModalMemeImage={setModalMemeImageElement}
					presetColors={presetColors} />}
			</div>
			<div>
				<p style={{ margin: 0 }}>
					<small>For the best experience, use on Desktop and on Google Chrome.</small>
				</p>
				<p style={{ margin: 0 }}>
					<small>To give feedback, post in the #general channel in our Discord.</small>
				</p>

			</div>
			{showMemeModal && <MemeModal memeDimensions={memeDimensions}
				ModalMemeImageElement={ModalMemeImageElement}
				onClose={onModalClose} />}
		</div>
	);
}
