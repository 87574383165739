import React from 'react';
import styles from './UsefulLinks.module.scss';
import commonDashBoardStyles from '../../dashboard-common.module.scss';
import { Link, InfoCard as UsefulLinkCard } from 'src/ui/components';
import LinkIcon from 'src/icons/LinkIcon';
import { raLinks, web3SafetyLinks } from './data';

export default function UsefulLinks() {
	const linkList = ( title, links ) => {
		return <div className={styles['useful-links-cards-container']}>
			<h3 className={`flex items-center uppercase font-bold text-lg leading-none mb-9 ${styles['useful-links-cards-container-title']}`}>
				<span className={styles['useful-links-cards-container-title-icon']}><LinkIcon className='mr-2' /></span>
				{title}
			</h3>
			{links.map( ( linkItem, index ) =>
				<Link href={linkItem.cta.link}
					external
					key={index}>
					<UsefulLinkCard key={index}
						isCtaContained={true}
						{...linkItem} />
				</Link>,

			)}
		</div>;
	};

	return (
		<div className={styles['useful-links']}>
			<h2 className={commonDashBoardStyles['tab-title']}>Useful links</h2>
			{raLinks && linkList( 'Rare Apepes links', raLinks )}
			{web3SafetyLinks && linkList( 'Web3 safety links', web3SafetyLinks )}
		</div>
	);
}
