import { useEffect, useState, useRef, useMemo } from 'react';
import { useStore } from 'base/state';
import { Button } from 'src/ui/components';
import LoadingOverlay from 'src/ui/components/LoadingOverlay';
import CountManager from 'src/ui/components/CountManager';
import styles from '../../views/ApepeOdyssey/Piece/Piece.module.scss';
import MintCountDown from 'src/ui/components/MintCountDown/MintCountdown';
import { MINT_STATES, SALE_STATUSES } from 'src/base/constants';
import TiltImage from '../TiltImage/TiltImage';


export default function MintPage({ onClickMint, mintCount, setMintCount, maxMintCount, address, nftName, NftMainMedia, saleStatus, startDate, alreadyMinted, isConnected, mintButtonText, loadingText, loading, isSuccess, nftParagraph, nftSuccessViewLink, tierStatement, mintPrice, nftImageUri, pricesList }) {
	const containerRef = useRef(null);
	const api = useStore(s => s.api);
	const mintState = useMemo(() => {

		if (loading || !address) return MINT_STATES.CHECKING;
		if (maxMintCount === 0) return MINT_STATES.EXCEEDED;
		return MINT_STATES.ELIGIBLE;

	}, [loading, address]);

	useEffect(() => {
		if (containerRef.current) {
			const scrollContainer = document.querySelector('.a-layout .scroll-container');
			if (scrollContainer) {
				// Set the overflow-y style property with !important
				scrollContainer.style.cssText += 'overflow-y: scroll !important;';
			}
		}
	}, [containerRef]);

	const increaseMintCount = () => {
		if (mintCount + 1 > maxMintCount) return;
		setMintCount(mintCount + 1);
	};

	const decreaseMintCount = () => {
		if (mintCount - 1 < 0) return;
		setMintCount(mintCount - 1);
	};

	// why is this here? shouldn't need to define the class every time we use the component
	const getCountBtnClasses = (countType) => {
		const classes = [''];

		if (countType === 'minus' && mintCount === 0) { classes.push(styles['apepe-odyssey_main_right_mint_count_btn_disabled']); }

		if (countType === 'plus' && (mintCount === maxMintCount || maxMintCount === undefined)) // plus btn will be disabled if no max mint count is passed
		{ classes.push(styles['apepe-odyssey_main_right_mint_count_btn_disabled']); }

		return [...classes];
	};

	const showConnectWalletButton = !isConnected && saleStatus !== SALE_STATUSES.ENDED;
	const showMintingSection = saleStatus === SALE_STATUSES.ACTIVE && !showConnectWalletButton;
	const showMintCountdown = saleStatus === SALE_STATUSES.NOT_STARTED && startDate;
	const showMintEndedStatement = saleStatus === SALE_STATUSES.ENDED && startDate;

	console.log('pricesList', pricesList)

	if (isSuccess) {
		return (
			<div className='flex flex-col '>
				<div className='max-w-2xl flex justify-center h-[500px] max-h-[500px]'>
					{/* eslint-disable-next-line @next/next/no-img-element */}
					{/* <img
						src={nftImageUri}
						alt="NFT Image"
						className="w-auto h-full mb-10"
					/> */}

					<TiltImage imageSrc={nftImageUri}
						className='w-auto h-full max-w-[400px] mb-10' />
				</div>
				<div>
					<div className="text-center" >
						<p className=" w-full my-10 text-2xl max-w-xl">
							Congratulations, you have minted
							{' '}
							{nftName}
							<br />
							<a target="_blank"
								href={nftSuccessViewLink}
							>
								View it now.
							</a>
						</p>
					</div>
				</div>
			</div>
		);
	}

	return <div

		className="w-full max-w-[1400px] h-auto flex flex-col items-center py-5">




		<h1 className="text-6xl max-[600px]:text-2xl -f-primary-gradient max-w-[95vw] text-center">{nftName}</h1>

		<LoadingOverlay show={loading}
			text={loadingText ?? 'Loading ...'} />



		{/* container of animation and right side */}
		<div ref={containerRef}
			className='flex justify-between items-start max-[600px]:items-center h-auto w-full max-[1500px]:w-[85%] max-[1200px]:flex-col  max-[1200px]:w-3/4 px-4 pt-14'>

			<div>
				{/* eslint-disable-next-line @next/next/no-img-element */}
				<TiltImage imageSrc={nftImageUri} />
			</div>

			<div className='w-full flex flex-col items-start pl-[75px] max-[1200px]:items-center max-[600px]:items-center  max-[1200px]:pt-5 max-[1200px]:pl-0 '>
				{!isSuccess &&
					<div className="max-w-3xl text-justify" >
						<span className='font-semibold uppercase  text-[#b3fe00] mb-5'>Story</span>
						<p className="mb-10 max-[600px]:mb-5 mt-5">
							{nftParagraph}
						</p>
						<span className='font-semibold uppercase  text-[#b3fe00] mb-5'>Price</span>
						<p className="mb-10 max-[600px]:mb-5 mt-5">
						{mintPrice === '0.0' && 'FREE'}
						</p>

						{mintPrice !== '0.0' && pricesList?.map((priceCategory) => {
							return (<p className="mb-5 max-[600px]:mb-3 mt-2">
								{`${priceCategory.label}: ${priceCategory.value} ETH `}
							</p>)
						})}
					</div>
				}
				<div
					className='flex flex-col justify-center items-start max-[600px]:items-center'>
					{showMintCountdown ? <MintCountDown timestamp={startDate} /> : null}
					{showMintEndedStatement ? <p>The mint has ended</p> : null}

					{showConnectWalletButton && <Button className="mt-10 max-[600px]:mt-5"
						iconPos="before"
						icon="foxLarge"
						onClick={() => api.setIsModalOpen(true)}>
						Connect Wallet
					</Button>}

					{
						isConnected && !isSuccess && <div className='flex flex-col justify-start'>
							{/* allow mint */}
							{showMintingSection && <div className='flex flex-col justify-center items-start'>
								{/* count manager */}
								<CountManager
									wrapperClassName='flex items-center'
									countValueClassName='text-[62px] font-bold uppercase text-white mx-4 mx-[34px]'
									connected={isConnected}
									getCountBtnClasses={getCountBtnClasses}
									decreaseCount={decreaseMintCount}
									count={mintCount}
									iconSize={100}
									increaseCount={increaseMintCount}
								/>
								{/* claim button */}
								<div className={'relative mt-8 max-[600px]:w-[75%]'}>
									{!loading && <Button className="w-full z-1"
										iconPos="before"
										onClick={onClickMint}>
										{mintButtonText}
									</Button>}
								</div>
							</div>}

							{/* for some reason, setting tailwind classes alone did not make the border show up for the <p> below. Hence, the style={} declaration */}
							{tierStatement ? <p
								style={{ border: '1px solid white' }}
								className='border-2 border-white rounded p-5 my-10 text-xs'>
								{' '}
								{tierStatement}
								<br />
								{`You can mint up to ${maxMintCount} more at this pricing.`}
								{' '}
							</p> : null}

							{/* disAllow mint */}
							{mintState === MINT_STATES.EXCEEDED && <p className="w-full my-10 text-center">
								You have used up all your allocation.
							</p>}
						</div>
					}

				</div>
			</div>
		</div>

		<div className='h-20 w-10'></div>

	</div>;
}
