import { Button, Link, ActionButton } from 'ui/components';
import styles from './header.module.scss';
import clsx from 'clsx';
import { SHOW_TERMS, SHOW_LICENSE } from 'src/config';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const NavLink = ({ href, display, featureFlag }) => {
	const show = useFeatureFlag({ globalFeatureFlag: featureFlag });

	if ( !show && featureFlag !== undefined ) {
		return <></>;
	}

	return <Link
		href={href}
		className={`-f-family-burbank -hide-s uppercase text-2xl ${styles['a-header_text-link']}`}
	>
		{display}
	</Link>;
};

const navItems = [{
	key: 's-home',
	show: true,
	element: <NavLink href="/"
		display="Home" />,
},
{
	key: 's-dashboard',
	show: true,
	element: (
		<NavLink href="/dashboard"
			display="Dashboard" />
	),
},
{
	key: 's-terms',
	element: (
		<NavLink href="/terms"
			display="Terms"
			featureFlag={SHOW_TERMS} />
	),
},
{
	key: 's-license',
	element: (
		<NavLink href="/nft-license"
			display="NFT License"
			featureFlag={SHOW_LICENSE} />
	),
},
];

// TODO: retire the below
export const navVariationsBuilder = ( api ) => ({
	default: {
		navItems,
		connectWalletButton: <Button
			external
			icon="fox"
			iconPos="before"
			className={clsx( '-mxa -hide-s', 'w-[240px] h-[58px] text-lg' )}
			onClick={() => api.setIsModalOpen( true )}
		>
			Connect Wallet
		</Button>,
	},
	dashboardPublished: {
		navItems,
		connectWalletButton: <Button
			external
			icon="fox"
			iconPos="before"
			className={clsx( '-mxa -hide-s', 'w-[240px] h-[58px] text-lg' )}
			onClick={() => api.setIsModalOpen( true )}
		>
			Connect Wallet
		</Button>,
	},
	// dashboard navbar items
	dashboard: {
		navItems,
		connectWalletButton: <ActionButton textContent="connect wallet"
			onClick={() => api.setIsModalOpen( true )}
			className={clsx( '-mxa -hide-s', '' )} />,
	},
});

