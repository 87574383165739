//	Dependencies
import React from 'react';

// UI components
import { Image } from 'src/ui/components';

// others
import KeyForgeCommonStyles from '../KeyForge.module.scss';
import KeyForgeMasterStepsStyles from './KeyForgeMasterSteps.module.scss';
import { RARIBLE_LINKS } from 'src/base/constants';
import RaribleIcon from 'src/icons/RaribleIcon';


export default function KeyMasterSteps() {
	// static steps
	const keyForgeSteps = [
		{
			imageFileName: 'SerumForgeKey',
			title: '5 x The Original\'s Choice = one key',
			subTitle:
        'Burn 5 copies The Original\'s Choice to forge a key. The Original\'s; Choice is Piece #1 in the Apepe Odyssey Chapter 1.',
			link:
			RARIBLE_LINKS.THE_ORIGINALS_CHOICE,
			OverlayingIcon: <RaribleIcon/>,
		},
		{
			imageFileName: 'RaycForgeKey',
			title: 'The Original\'s Choice are burned',
			subTitle:
        'The copies of The Original\'s Choice are burned in the forging process. You must approve the Key contract to burn the copies.',
		},
		{
			imageFileName: 'BloodForgeKey',
			subTitle:
        'The Odyssey Key is minted, granting the holder the status of Keymaster for Apepe Odyssey Chapter 1.',
			title: 'become a keymaster & unlock rewards',
		},
	];

	return (
		<div
			className={`border-box mb-[3%] px-[3%] py-[2.5%]  h-auto w-[77.5%] bg-[rgba(0,0,0,0.4)] rounded-[20px]   ${KeyForgeCommonStyles['key-forge-transparent-box']}`}
		>
			<h4
				className={`font-bold text-[32px]  mb-[70px]  ${KeyForgeCommonStyles['key-forge-header']}`}
			>
        how to become a Keymaster!
			</h4>
			<div className='flex justify-between items-center max-[900px]:flex-col'>
				{keyForgeSteps.map( ( step, index ) => (
					<div
						key={index}
						className='relative h-auto w-[30%]  flex flex-col items-center text-center max-[900px]:w-full max-[900px]:mb-5'
					>
						<Image
							className='relative z-[1] mb-5'
							src={`/images/${step.imageFileName}.png`}
							width={200}
							height={200}
							alt="Step Image"
						/>
						{step.OverlayingIcon && (
							<a
								className={
									`absolute right-[20px] z-[2]  max-[1200px]:right-0 ${KeyForgeMasterStepsStyles['key-forge-master-steps-item-icon']}`
								}
								target="_blank"
								href={RARIBLE_LINKS.THE_ORIGINALS_CHOICE}
								rel="noreferrer"
							>	
								{step.OverlayingIcon}
							</a>
						)}
						<div
							className={`-f-family-chauncy font-bold text-[28px] leading-[110%] uppercase mb-2 text-center max-w-[270px] ${KeyForgeMasterStepsStyles['key-forge-master-steps-item-title']}`}
						>
							{step.title}
						</div>
						<div
							className='font-normal text-[14px] leading-[140%] max-w-[290px] text-[#d9d9d9]'
						>
							{step.subTitle}
						</div>
					</div>
				) )}
			</div>
		</div>
	);
}
