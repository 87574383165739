import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import useCheckDelegations from './useCheckDelegations';
import addressAtom from 'src/state/addressAtom';
import walletTypeAtom from 'src/state/walletTypeAtom';
// import { useDisconnect, useAddress } from '@thirdweb-dev/react';
import { Address, WalletTypeValues } from 'src/base/types';
import { WALLET_TYPES } from 'src/base/constants';


import { useActiveWallet, useDisconnect } from "thirdweb/react";

//
// Update this function to set an array of addresses
function setIsDisconnected(addressesArray: Address[]) {
	try {
		if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
			localStorage.setItem('isDisconnected', JSON.stringify(addressesArray));
		}
	} catch (error) {
		console.error('Error setting disconnected addresses:', error);
	}
}

// Update this function to read an array of addresses
function readIsDisconnected() {
	try {
		if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
			const item = localStorage.getItem('isDisconnected');
			if (item) {
				const parsed = JSON.parse(item);
				if (Array.isArray(parsed)) {
					return parsed;
				} else {
					setIsDisconnected([]);
				}
			} else {
				return [];
			}
		}
		return [];
	} catch (error) {
		console.error('Error reading disconnected addresses:', error);
		return [];
	}
}

interface ConnectFunction {
	(cb: Function, walletType: WalletTypeValues): void;
}

export default function useWallet(): [Function, Address, Function] {
	const [address, setAddress] = useRecoilState<Address>(addressAtom); // metamaskAddress
	const [walletType, setWalletType] = useRecoilState<WalletTypeValues>(walletTypeAtom); // metamaskAddress
	const { resetDelegations } = useCheckDelegations(address);
	const activeThirdWebWallet = useActiveWallet()
	// const [walletConnectAddress, setWalletConnectAddress] = useState(null); // separate address tracker

	// wallet connect vars
	const { disconnect: ThirdWebDisconnect } = useDisconnect();
	// this will ensure address is synchronized between ui and recoil store
	// const walletConnectAddress = useAddress(); // get connectedAddress


	// connect will be only set to metamask
	// wallet connect connection happens at wallet modal level

	const connect: ConnectFunction = async (cb = () => { }, walletType: WalletTypeValues) => {
		try {
			if (!(window as any).ethereum) {
				alert('No MetaMask detected. Please retry in a Metamask-enabled browser.');
			} else {
				const accounts = await (window as any).ethereum.request({ method: 'eth_accounts' });
				console.log('accounts', accounts);

				const addresses = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
				setAddress(addresses[0]);
				const disconnectedAddresses = readIsDisconnected();
				setIsDisconnected(disconnectedAddresses.filter(x => x !== addresses[0]));
				setWalletType(walletType);
				cb();
			}
		} catch (error) {
			if (error.code === 4001) {
				// EIP-1193 userRejectedRequest error
				console.log('Please connect to MetaMask.');
			} else {
				console.error(error);
			}
		}

	}


	// disconnection is different from connecting
	// this is generic for all wallets

	function disconnect(cb?: Function) {
		try {

			if (walletType === WALLET_TYPES.WALLET_CONNECT) {
				ThirdWebDisconnect(activeThirdWebWallet);
			}

			const disconnectedAddresses = readIsDisconnected();
			setIsDisconnected(disconnectedAddresses.concat(address));
			setAddress(null);

			if (cb) cb();
		} catch (error) {
			console.error(error);
		}
	}



	// read address if not disconnected
	useEffect(() => {
		try {
			if (walletType === WALLET_TYPES.WALLET_CONNECT) return;
		} catch (error) {
			console.error('Error in wallet type effect:', error);
		}
	}, [address, setAddress]);

	// remove delegations when all accounts are disconnected
	useEffect(() => {
		try {
			if (typeof (window as any) !== 'undefined' && (window as any).ethereum) {
				(window as any).ethereum.on('accountsChanged', (accounts) => {
					const noLongerConnected = accounts.length === 0;
					if (noLongerConnected) {
						setAddress(null);
						return;
					} else {
						const address = accounts[0];
						setAddress(address);
					}
					console.log('accountsChanged', accounts);
					resetDelegations();
				});
			}
		} catch (error) {
			console.error('Error setting up accounts changed listener:', error);
		}
	}, [setAddress, resetDelegations]);

	return [connect, address, disconnect];
}
