// useAlbumSetup.js
import { useState, useEffect, useMemo } from 'react';
import useWallet from 'src/hooks/useWallet';
import useProvider from 'src/hooks/useProvider';
import useContract from 'src/hooks/useContract';
import useNetwork from 'src/hooks/useNetwork';
import useActiveAddress from 'src/hooks/useActiveAddress';
import useTokenIds from 'src/hooks/useTokenIds';
import useERC1155Balances from 'src/hooks/useERC1155Balances';
import { fetchPublishedPieces } from 'src/services/odyssey';
import { getLorePieceInfo } from 'src/services/lorePieceInfo';
import { transformLoreBalances } from './albumPageUtils';
import { CONTRACT_NAMES } from 'src/base/constants';
import { ensureMinimumEightPieces } from './utils';

export default function useAlbumSetup( chapterId ) {
	const [connect, address] = useWallet();
	const provider = useProvider();
	const { network } = useNetwork();
	const activeAddress = useActiveAddress( address );
	const loreContract = useContract( provider, network, CONTRACT_NAMES.LORE, true );
	const { tokenIds } = useTokenIds( loreContract );
	const { balances: loreBalances } = useERC1155Balances( activeAddress, loreContract, tokenIds );
	const [showConnectWallet, setShowConnectWallet] = useState( false );
	const [items, setItems] = useState([]);
	const [pieceInfos, setPieceInfos] = useState({});
	const [loading, setLoading] = useState( false );

	const connected = useMemo( () => {
		return !!( address && provider );
	}, [address, provider]);

	useEffect( () => {
		async function getItems() {
			setLoading( true );
			const metadata = await fetchPublishedPieces( network, chapterId );
			if ( loreBalances && network && metadata !== null ) {
				const transformedItems = transformLoreBalances( metadata, loreBalances );
				setItems( ensureMinimumEightPieces( transformedItems ) );
			} else if ( metadata !== null ) {
				setItems( ensureMinimumEightPieces( metadata ) );
			} else {
				console.warn( 'Nothing' );
			}
			setLoading( false );
		}
		getItems();
	}, [loreBalances, tokenIds, network, chapterId]);

	useEffect( () => {
		async function getTokenConfigs() {
			const promises = items.map( async ( item ) => {
				return await getLorePieceInfo( network, chapterId, item.id );
			});
			const results = await Promise.all( promises );
			const res = {};
			results.forEach( ( result ) => {
				if ( result ) {
					res[result.pieceId] = result;
				}
			});
			setPieceInfos( res );
		}
		getTokenConfigs();
	}, [items]);
	useEffect( () => {
		if ( !address ) {
			setShowConnectWallet( true );
		}
	}, [address]);

	return { connect, connected, showConnectWallet, items, loading, pieceInfos };
}
