import React from 'react';
import styles from './DashboardCard.module.scss';
import { Image, Link } from 'src/ui/components';
import { STUFF_CARD_STATES } from 'src/ui/views/Dashboard/Tabs/Home/data';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';


export default function DashboardCard({
	imageSrc,
	overlayingImage,
	paragraph,
	cta: { text, link, isExternal },
	className,
	address,
	featureFlag,
	state,
	onClick,
}) {
	const isExpired = state === STUFF_CARD_STATES.EXPIRED;
	const buttonText = isExpired ? 'Ended' : text;
	const show = useFeatureFlag({ globalFeatureFlag: featureFlag, address });

	const buttonStyle = {};
	const imageStyle = {};

	if ( isExpired ) {
		buttonStyle.borderColor = 'grey';
		buttonStyle.color = 'grey';
		imageStyle.filter = 'grayscale(100%)';
	}

	if ( !show ) {
		return <></>;
	}

	return ( <Link disabled={isExpired}
		external={isExternal}
		className={` ${styles['dashboard-card']} ${className}`}
		href={link}
		style={{ cursor: isExpired ? 'unset' : 'pointer' }}>
		<div className={styles['dashboard-card-images-container']}>
			{imageSrc && <Image
				grayscale={isExpired}
				className={styles['dashboard-card-img']}
				src={imageSrc.includes( 'http' ) ? imageSrc : `/images/${imageSrc}`}
				layout="fill"
				objectFit="cover"
			/>}
			{overlayingImage && <Image
				grayscale={isExpired}
				className={styles['dashboard-card-overlaying-img']}
				src={`/images/${overlayingImage?.src}`}
				width={Number( overlayingImage?.width )}
				height={Number( overlayingImage?.height )}
			/>}
		</div>

		<div className={styles['dashboard-card-inner-container']}>
			<div className={styles['dashboard-card-paragraph']}>{paragraph}</div>
			<button disabled={isExpired}
				style={buttonStyle}
				className={styles['dashboard-card-cta']}>
				{buttonText}
			</button>
		</div>
	</Link>
	);
}
