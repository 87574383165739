import { useEffect, useState } from 'react';

const communityCard = {
	imageSrc: '',
	paragraph:
    'Lorem ipsum dolor sit amet consectetur. Pharetra sem consequat luctus amet nisl bibendum sem adipiscing elit. Molestie sed lobortis facilisis ultrices sit. ',
	cta: {
		text: 'Go to website',
		link: 'https://example.com',
		isExternal:true,
	},
};

export default function useCommunityProjects() {
	const [communityProjectCards, setCommunityProjectCards] = useState([]);
	const getCommunityProjectCards = () => Array( 6 ).fill( communityCard );

	useEffect( () => {
		setCommunityProjectCards( getCommunityProjectCards() );
	}, []);

	return { communityProjectCards };
}
