//	Dependencies
import React, { useRef, useMemo, useEffect, useState } from 'react';

// UI components
import { Page } from 'ui/app';
import { Image, Modal } from 'src/ui/components';

// hooks
import useWallet from 'src/hooks/useWallet';
import useCheckDelegations from 'src/hooks/useCheckDelegations';
import useProvider from 'src/hooks/useProvider';
import LoadingOverlay from 'src/ui/components/LoreLoadingOverlay';
// import useKeyForgeSetup from './useKeyForgeSetup'; // TODO: move to here
import useNetwork from 'src/hooks/useNetwork';
import useContract from 'src/hooks/useContract';
import useERC1155Balances from 'src/hooks/useERC1155Balances';
import useERC721SimpleBalances from 'src/hooks/useERC721SimpleBalances';

// others
import styles from './KeyForge.module.scss';
import { RARIBLE_LINKS } from 'src/base/constants';
import { CONTRACT_NAMES } from 'src/base/constants';

// sub-components
import KeyMasterSteps from './KeyForgeMasterSteps/KeyMasterSteps';
import KeyForgeController from './KeyForgeController/KeyForgeController';
import KeyForgeHero from './KeyForgeHero/KeyForgeHero';
import KeyForgeAnimation from './KeyForgeAnimation/KeyForgeAnimation';
import KeyForgeAnoited from './KeyForgeAnoited/KeyForgeAnoited';
import { gsap } from 'gsap';

//
//	RAYC / Pages / ApepeOdyssey / KeyForge page
//

const ForgingStates = {
	READY: 'ready',
	TRANSACTION_PROCESSING: 'TRANSACTION_PROCESSING',
	TRANSACTION_CONFIRMED: 'TRANSACTION_CONFIRMED',
	COMPLETED: 'COMPLETED',
};

const animationDurationMs = 22000; // in milliseconds

export default function KeyForgeView({ viewForged }) {
	const piecePageRef = useRef( null );
	const [connect, address] = useWallet(); // we can't use Delegate.Cash for Key Forging
	const provider = useProvider();
	const connected = useMemo( () => {
		return !!( address && provider );
	}, [address, provider]);
	const [forgingState, setForgingState] = useState( viewForged ? ForgingStates.COMPLETED : ForgingStates.READY );
	const [forgedKeysCount, setForgedKeysCount] = useState( 0 ); // forged in this session
	const [forgedPieceLink, setForgedPieceLink] = useState();
	const [addressHasApprovedKeyContract, setAddressHasApprovedKeyContract] = useState( null );
	const { selectedVault } = useCheckDelegations( address );
	const [ approvalIsProcessing, setApprovalIsProcessing ] = useState( false );
	const [ replayAnimationRequested, setReplayAnimationRequested ] = useState( false );

	// alert if using vault
	useEffect( () => {
		if ( selectedVault ) {
			alert( 'Forging does not support delegate.cash. Please transfer the necessary copies of The Original\'s Choice to a hot wallet, and connect using the hot wallet instead.' );
		}
	}, [selectedVault]);
	
	const { network } = useNetwork();
	const loreContract = useContract( provider, network, CONTRACT_NAMES.LORE );
	const keyContract = useContract( provider, network, CONTRACT_NAMES.KEY );
	const tokenIds = useMemo( () => [1], []);
	const { balances: loreBalances } = useERC1155Balances( address, loreContract, tokenIds );

	useEffect( () => {
		if ( !loreContract ) {
			return;
		}
		if ( loreContract && !keyContract?.address ) {
			throw new Error( 'Key contract not found' );
		}
		if ( !address ) {
			return;
		}
		const checkApproval = async () => {
			const isApproved = await loreContract.isApprovedForAll( address, keyContract.address );
			setAddressHasApprovedKeyContract( isApproved );
		};
		checkApproval();
	}, [loreContract, keyContract, address]);

	const tocBalance = useMemo( () => {
		if ( !loreBalances || loreBalances.length === 0 ) {
			return 0;
		}
		return loreBalances[0].balance;
	}, [loreBalances]);

	const eligibleKeysCount = useMemo( () => {
		return Math.floor( tocBalance / 5 ); // every 5 copies = 1 key
	}, [tocBalance]);


	// count is from ForgeKeyController
	const forgeKey = async ( count ) => {
		const tx = await keyContract.forgeKey( count );
		await tx.wait();
	};

	const playAnimation = () => {
		setForgingState( ForgingStates.TRANSACTION_CONFIRMED ); // this triggers the animation
		gsap.to( window, { duration: 1, scrollTo: { y: '#key-forge-animation', offsetY: 0 }});
		// return resolved promise after animation is done
		return new Promise( ( resolve ) => {
			setTimeout( () => {
				setForgingState( ForgingStates.COMPLETED ); // this ends the animation
				resolve();
			}, animationDurationMs );
		});
	};

	const replayAnimation = async () => {
		setReplayAnimationRequested( true );
		await playAnimation();
		setReplayAnimationRequested( false );
		gsap.to( window, { duration: 1, scrollTo: { y: '#key-forge-anoited', offsetY: 100 }});
	};

	const onClickForgeKey = async ( count ) => {
		// user clicks confirm on metamask
		setForgingState( ForgingStates.TRANSACTION_PROCESSING );
		setForgedPieceLink( RARIBLE_LINKS.ODYSSEY_KEY );
		try {

			await forgeKey( count );
			setForgedKeysCount( count ); // how many forged in this session
			await playAnimation();
			gsap.to( window, { duration: 1, scrollTo: { y: '#key-forge-anoited', offsetY: 100 }});	
		} catch ( e ) {
			console.error( e );
			setForgingState( ForgingStates.READY );
		}
	};

	const onClickApprove = async () => {
		setApprovalIsProcessing( true );
		try {
			const tx = await loreContract.setApprovalForAll( keyContract.address, true );
			await tx.wait();
			setApprovalIsProcessing( false );
			setAddressHasApprovedKeyContract( true );
		} catch ( error ) {
			setApprovalIsProcessing( false );
			console.error( error );
		}

	};

	// to fix screen getting vertically cut off
	// this is to counter what is being done in layout.js
	useEffect( () => {
		if ( piecePageRef.current ) {
			document.body.style.minHeight = `${piecePageRef.current.offsetHeight - 100}px`;
		}
	}, [piecePageRef]);


	const contractObjects = useMemo( () => {
		return [{ name: CONTRACT_NAMES.KEY, contract: keyContract }];
	}, [keyContract]);

	// get the user's balance of the key contract
	const { balances } = useERC721SimpleBalances( address, contractObjects, forgingState );
	const keyBalance = useMemo( () => {
		if ( !balances || balances.length === 0 ) {
			return 0;
		}
		return balances[0].balance;
	}, [balances]);

	const customButtonText = useMemo( () => addressHasApprovedKeyContract === true ? null : 'Approve', [addressHasApprovedKeyContract]);

	const shouldPlayAnimation = useMemo( () => {
		return forgingState === ForgingStates.TRANSACTION_CONFIRMED || replayAnimationRequested;
	}, [replayAnimationRequested, forgingState]);

	const KeyForgeLowerSection = () => {
		if ( viewForged || forgingState === ForgingStates.COMPLETED ) {
			return <KeyForgeAnoited forgedKeysCount={keyBalance}
				linkToPiece={forgedPieceLink}
				onClickReplay={replayAnimation} />;
		} else if ( forgingState === ForgingStates.READY || forgingState === ForgingStates.TRANSACTION_PROCESSING ) {
			return <>
				<KeyMasterSteps />
				<KeyForgeController connect={connect}
					connected={connected}
					tocBalance={tocBalance}
					eligibleKeysCount={eligibleKeysCount}
					onClick={addressHasApprovedKeyContract ? onClickForgeKey : onClickApprove}
					customButtonText={customButtonText}/>
			</>;
		} else if ( forgingState === ForgingStates.TRANSACTION_CONFIRMED ) {
			// animation will be playing
		} else {
			console.error( `Invalid state for Key Forge page. forgingState: ${forgingState}, viewForged: ${viewForged}, replayAnimationRequested: ${replayAnimationRequested} ` );
		}

	};

	const showLoadingOverlay = useMemo( () => {

		return forgingState === ForgingStates.TRANSACTION_PROCESSING || approvalIsProcessing;

	}, [forgingState, approvalIsProcessing]);

	return (
		<Page meta={{ ogImage: 'https://odyssey-data.rareapepes.com/chapter-1/key/key.jpeg' }}>
			<Modal />
			<section className='bg-cover bg-no-repeat bg-center pt-[10.75rem] pb-[160px] h-auto flex flex-col items-center justify-center bg-[url("/images/apepeOdysseybg.webp")]'
				ref={piecePageRef}>
				<LoadingOverlay show={showLoadingOverlay}
					text="Proccessing Transaction" />
				<Image
					className='mt-5'
					src="/images/Apepe_Odyssey_Logo.png"
					width={1220}
					height={280}
					alt="Apepe Odyssey Logo"
				/>
				<KeyForgeHero />
				{ shouldPlayAnimation && <KeyForgeAnimation /> }
				<div className='w-full flex h-auto flex-col justify-center items-center'>
					<KeyForgeLowerSection />
				</div>
			</section>
		</Page>
	);
}



