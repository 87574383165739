import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const dimissedAnnouncementsIdsAtom = atom({
	key: 'dimissedAnnouncementsIds',
	default: [],
	effects_UNSTABLE: [persistAtom],
});

export default dimissedAnnouncementsIdsAtom;
