import React from 'react';

export default function DirectedArrowIcon({ className }) {
	return (
		<svg
			width="79"
			height="120"
			viewBox="0 0 79 120"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M71 119V120H72V119H71ZM71.7071 0.292893C71.3166 -0.0976311 70.6834 -0.0976311 70.2929 0.292893L63.9289 6.65685C63.5384 7.04738 63.5384 7.68054 63.9289 8.07107C64.3195 8.46159 64.9526 8.46159 65.3431 8.07107L71 2.41421L76.6569 8.07107C77.0474 8.46159 77.6805 8.46159 78.0711 8.07107C78.4616 7.68054 78.4616 7.04738 78.0711 6.65685L71.7071 0.292893ZM0 120H71V118H0V120ZM72 119V1H70V119H72Z"
				fill="white"
			/>
		</svg>
	);
}
