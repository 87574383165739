import {
	atom,
} from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Address, WalletTypeValues } from 'src/base/types';

const { persistAtom } = recoilPersist();


const walletTypeAtom = atom<WalletTypeValues>({
	key: 'walletType', // unique ID (with respect to other atoms/selectors)
	default: null, // default value (aka initial value)
	effects_UNSTABLE: [persistAtom],
});

export default walletTypeAtom;
