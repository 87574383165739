//	Dependencies
import React, { useMemo, useEffect, useRef } from 'react';

// UI components
import { Page } from 'ui/app';
import { Headline } from 'ui/components';
import PieceFound from './PieceFound';

// hooks
import usePieceSetup from './usePieceSetup';
import useMintingFunctions from './useMintingFunctions';

// others
import styles from './Piece.module.scss';
import { SALE_STATUSES } from 'src/base/constants';

//
//	RAYC / Pages / ApepeOdyssey / MintPage
//

// TODO: move availableChapters logic up to parent component [pieceId]
// TODO: don't hardcode
const availableChapters = ['0', '1'];

export default function PieceView({ chapterId, pieceId }) {
	const {
		userIsHolderAndHasUsedAllTokensButPieceIsNotAvailableToPublic,
		userIsPublicAndTokenIsNotAvailableForPublic,
		tokenConfigNotYetSet,
		priceToUse,
		raycOrZaycAddress,
		selectedVault,
		setCustomLoadingMessage,
		showLoadingOverlay,
		setShowLoadingOverLay,
		loreContract,
		tierToUse,
		mintCount,
		tokenIdToUse,
		tokenIdsToUse,
		saleStatus,
		network,
		customLoadingMessage,
		showRevealed,
		pieceMetadata,
		tierStatement,
		copiesOwned,
		address,
		tokensUsage,
		connect,
		infoItemsData,
		setMintCount,
		maxMintCount,
		userBalance,
	} = usePieceSetup({ chapterId, pieceId });



	const { onClickMint } = useMintingFunctions({ pieceId, mintCount, setShowLoadingOverLay, setCustomLoadingMessage, priceToUse, raycOrZaycAddress, selectedVault, tokenIdToUse, tokenIdsToUse, tierToUse, loreContract, userBalance });


	const hideMintButton = useMemo( () => {
		if ( tokenConfigNotYetSet ) return true;
		if ( saleStatus !== SALE_STATUSES.ACTIVE ) return true;

		return userIsPublicAndTokenIsNotAvailableForPublic || userIsHolderAndHasUsedAllTokensButPieceIsNotAvailableToPublic;
	}, [userIsPublicAndTokenIsNotAvailableForPublic, userIsHolderAndHasUsedAllTokensButPieceIsNotAvailableToPublic, tokenConfigNotYetSet, saleStatus]);

	const ApepeOdysseyContent = () => {
		if ( !availableChapters.includes( chapterId ) ) return <PieceNotFound />;
		else {
			return <PieceFound network={network}
				chapterId={chapterId}
				pieceId={pieceId}
				isLoading={showLoadingOverlay}
				customLoadingMessage={customLoadingMessage}
				showRevealed={showRevealed}
				pieceMetadata={pieceMetadata}
				copiesOwned={copiesOwned}
				hideMintButton={hideMintButton}
				infoItemsData={infoItemsData}
				tierStatement={tierStatement}
				tokensUsage={tokensUsage}
				address={address}
				setMintCount={setMintCount}
				tokenConfigNotYetSet={tokenConfigNotYetSet}
				mintCount={mintCount}
				maxMintCount={maxMintCount}
				connect={connect}
				saleStatus={saleStatus}
				onClickMint={onClickMint}
				userBalance={userBalance} />;
		}
	};

	const piecePageRef = useRef( null );

	// to fix screen getting vertically cut off
	// this is to counter what is being done in layout.js
	useEffect( () => {
		if ( piecePageRef.current ) {
			document.body.style.minHeight = `${piecePageRef.current.offsetHeight - 100}px`;
		}
	}, [piecePageRef, showLoadingOverlay]);


	const PieceNotFound = () => {
		return <Headline className='absolute top-[30%] left-[10vw]'>Chapter is not Available</Headline>;
	};

	return (
		<Page>
			<section style={{ willChange: 'unset' }}
				className='bg-cover bg-center bg-no-repeat pt-[10.75rem] pb-[100px] flex flex-col justify-center items-center min-h-[100vh] max-h-auto bg-[url("/images/apepeOdysseybg.webp")]'
				ref={piecePageRef}>
				<div className='max-w-[1100px] min-w-[80vw] pt-[1rem] flex flex-col justify-center items-center'><ApepeOdysseyContent /></div>
			</section>
		</Page>
	);
}

// was 370 lines
