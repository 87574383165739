
//	Dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

//	App
import { Button, Image } from 'ui/components';

//	Styles
import styles from './flip-card.module.scss';


//
//	RAYC  / UI / Components / Flip Card
//


export default function FlipCard({ className, title, image, children, overlay }) {
	const [ isFlipped, setIsFlipped ] = useState( false );

	return (
		<div className={ clsx( styles[ 'c-flip-card' ], className, { [ styles[ '-is-flipped' ] ]: isFlipped }) }>
			<button className={ styles[ 'c-flip-card_container' ] }
				onClick={ () => setIsFlipped( !isFlipped ) }>
				<div className={ styles[ 'c-flip-card_front' ] }>
					{ image?.src && (
						<Image className={ styles[ 'c-flip-card_img' ] }
							src={ image?.src }
							width={ image?.width }
							height={ image?.height }
							alt={ image?.alt || '' } />
					)}
					{ title && <h3 className={ clsx( styles[ 'c-flip-card_title' ], '-f-family-chauncy -f-caps -mb0' ) }>{ title }</h3> }
					<Button className={ clsx( '-mxa -mt1 -style-round', styles[ 'c-flip-card_button' ]) }
						size="s"
						element="span">
						Read info
					</Button>
				</div>
				<div className={ styles[ 'c-flip-card_back' ] }>
					<div className={ styles[ 'c-flip-card_content' ] }>
						{ children && children }
					</div>
					<Button className={ clsx( '-mxa -style-round', styles[ 'c-flip-card_button' ]) }
						size="s"
						element="span">
						Flip back
					</Button>
				</div>
				{ overlay && overlay }
			</button>
		</div>
	);
}

FlipCard.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	image: PropTypes.object,
	children: PropTypes.node,
	overlay: PropTypes.node,
};
