import { SHOW_KEYMASTERS_POT, SHOW_ZOMBIE_PURGE } from 'src/config';

export const STUFF_CARD_STATES = {
	COMING_SOON: 'COMING_SOON',
	ACTIVE: 'ACTIVE',
	EXPIRED: 'EXPIRED',
};

export const announcementCards = [
	{
		id: 1,
		date: 'Jul 10, 2023',
		paragraph:
			'Welcome to the launch of the Apepe Dashboard. This will be the one-stop shop for holders in the Rare Apepes ecosystem. What you see right now is only the starting point. Expect more features in the future.',
		cta: {
			text: 'Dismiss',
		},
		status: 'Active',
	},
	{
		id: 2,
		date: 'Jul 12, 2023',
		paragraph:
			'Welcome to the launch of the Apepe Dashboard. This will be the one-stop shop for holders in the Rare Apepes ecosystem. What you see right now is only the starting point. Expect more features in the future.',
		cta: {
			text: 'Dismiss',
		},
		status: 'Archieved',
	},
];

// if there is a card that needs to be hidden first, give it an evaluateShow function that takes in an address argument
// eslint-disable-next-line no-sparse-arrays
export const doYourStuffCards = [
	{
		imageSrc: '/merch.png',
		paragraph:
			'Our Apepe Token Holders enjoyed an exclusive access period - but you are still in luck! Packs are available for general public (no NFT needed) - although you get very juicy discounts if you are an Apepe holder. Sorry not sorry.',
		cta: { text: 'Buy merch', link: 'https://merch.rareapepes.com/', isExternal: true },
	},
	{
		imageSrc: '/rak.png',
		paragraph:
			'Go on an epic Apepe 2D adventure on this demo where top bounty hunter Nubi hacks, slashs, and conquers secret artifacts of the Apepeverse -  are you frogish enough to beat the final boss?',
		cta: { text: 'go to demo', link: 'https://rak3022.rarelabs.xyz/', isExternal: true },
	},
	{
		imageSrc: '/ZombiePurge/zombie_burge_bg.jpg',
		overlayingImage: {
			src: '/ZombiePurge/purge_logo.png',
			width: '150',
			height: '64',
		},
		paragraph:
			'The battle between the genesis Apepes and the Zombie Apepes has erupted. If you are on the side of the genesis Apepes, the Zombie Purge is for you.',
		cta: { text: 'go to page', link: '/zombie-purge', isExternal: false },
		featureFlag: SHOW_ZOMBIE_PURGE,
	},
	{
		imageSrc: 'apepeOdysseybg.webp',
		overlayingImage: {
			src: 'ApepeOdsysseyWrapped.png',
			width: '150',
			height: '64',
		},
		paragraph:
			'The Apepe Odyssey is the chronicles of what we have learned so far from our journey to discover the truth about Apepes.',
		cta: { text: 'go to page', link: '/odyssey/1', isExternal: false },
	},
	{
		imageSrc: 'zombieApepeDefault.png',
		overlayingImage: {
			src: 'rayc-zombies.png',
			width: '180',
			height: '120',
		},
		paragraph:
			'If you are in possession of an unzombified Apepe and a Z1 serum, then head on over to the lab to spawn your Zombie Apepe.',
		cta: { text: 'go to page', link: 'https://zombies.rareapepes.com/spawn', isExternal: true },
	},
	{
		imageSrc: 'apepeOdysseybg.webp',
		overlayingImage: {
			src: 'dashboard/keymasters-chest.png',
			width: '206.25',
			height: '183.7',
		},
		paragraph:
			'There are only 224 Odyssey Keys in existence. If you are in the possession of any of them, you hold the right to a share of the Keymasters\'s Pot.',
		cta: { text: 'go to page', link: '/odyssey/1/keymasters-pot' },
		featureFlag: SHOW_KEYMASTERS_POT,
	},
	{
		imageSrc: 'coffinBg.png',
		overlayingImage: {
			src: 'MzaycCoffins.png',
			width: '118',
			height: '113',
		},
		paragraph:
			'10 chosen ones had become owners of mysterious coffins. Were you one of them ? Enter the graveyard to reveal your coffin.',
		cta: { text: 'go to page', link: 'https://zombies.rareapepes.com/coffins', isExternal: true },
	},
	,
	{
		imageSrc: 'ApepeDosBg.png',
		overlayingImage: {
			src: 'apepeDosText.png',
			width: '140',
			height: '20',
		},
		paragraph:
			'Eyeing a particular Apepe and wondering whether it has been zombified? Use the Apepe DOS to find out that information.',
		cta: { text: 'go to page', link: 'https://zombies.rareapepes.com/apepe-dos', isExternal: true },
	},
	{
		imageSrc: 'FireImage.png',
		overlayingImage: {
			src: 'keyForgeWithKey.png',
			width: '208',
			height: '60',
		},
		state: STUFF_CARD_STATES.EXPIRED,
		paragraph:
			'Holders were given the option to burn copies of The Original\'s Choice in order to become a Keymaster for the first chapter of the Odyssey.',
		cta: { text: 'go to page', link: 'https://www.rareapepes.com/odyssey/1/key', isExternal: true },
	},
	{
		imageSrc: 'greySerumDispensaryBg.png',
		overlayingImage: {
			src: 'SerumDispensary.png',
			width: '180',
			height: '45',
		},
		paragraph:
			'Every Rare Apepes (genesis Apepes) holder was entitled to receive a Z1 serum for each Apepe that they owned.',
		state: STUFF_CARD_STATES.EXPIRED,
		cta: { text: 'go to page', link: '#', isExternal: true },
	},
];
