// ConnectWalletButton.js
import React from 'react';
import { Button } from 'src/ui/components';
import styles from './Album.module.scss';
import { useStore } from 'base/state';


function ConnectWalletButton({ showConnectWallet, connect, connected }) {
	const api = useStore( s => s.api );

	if ( showConnectWallet ) {
		return (
			<div className='relative flex justify-between items-center mt-[54px] w-[77.5%] max-w-[1200px] max-[750px]:w-[85%] max-[600px]:w-[90%] max-[500px]:justify-center'>
				<div className='w-[262px] h-[2px] bg-white mr-[6%] max-[600px]:mr-[3%]' />
				{!connected && <Button
					className={styles['oddysey-album_btn']}
					icon="fox"
					iconPos="before"
					onClick={() => api.setIsModalOpen( true )}
				>
					Connect Wallet
				</Button>}
				{connected && <h2 className='text-[64px] uppercase'>Your collection</h2>}
				<div className='w-[262px] h-[2px] bg-white ml-[6%] max-[600px]:ml-[3%]' />
			</div>
		);
	}

	return (
		<div className={`-f-family-burbank relative flex items-center text-[64px] mt-[54px] max-[920px]:text-[50px] max-[700px]:text-[40px] max-[600px]:text-[30px] max-[500px]:text-[45px] ${styles['oddysey-album_your-collection']}`}>
			<div className='w-[202px] h-[2px] bg-white mr-[30px] max-[700px]:mr-[15px] max-[920px]:w-[125px] max-[700px]:w-[110px] max-[500px]:hidden' />
			Your Collection
			<div className='w-[202px] h-[2px] bg-white ml-[30px] max-[700px]:ml-[15px] max-[920px]:w-[125px] max-[700px]:w-[110px] max-[500px]:hidden' />
		</div>
	);
}

export default ConnectWalletButton;
