import React from 'react';
import styles from './ActionButton.module.scss';
import Icon from '../icon/icon';

interface ActionButtonProps {
	textContent: string;
	onClick: () => void;
	className?: string;
	iconType?: string;
	disabled?: boolean;
	iconClassName?: string;
}

export default function ActionButton({
	textContent,
	onClick,
	className = '',
	iconType,
	disabled = false,
	iconClassName,
}: ActionButtonProps): JSX.Element {
	return (
		<button
			className={`-f-family-burbank flex justify-center items-center font-bold uppercase ${disabled ? styles['action-button-disabled'] : ''
				} ${styles['action-button']} ${className}`}
			onClick={() => !disabled && onClick()}
			disabled={disabled}
		>
			{iconType && <Icon className={iconClassName || ''} type={iconType} />}
			<span className={styles['action-button-text']}>{textContent}</span>
		</button>
	);
}
