
//	Dependencies
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


//
//	RAYC / UI / Components / No SSR
//


export default function NoSSR({ children, fallback = null }) {
	const [ mounted, setMounted ] = useState( false );
	useEffect( () => setMounted( true ), []);
	if ( !mounted ) return fallback;
	return children;
}

NoSSR.propTypes = {
	children: PropTypes.node,
	fallback: PropTypes.node,
};
