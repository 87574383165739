import React, { useState, useRef, useEffect } from 'react';
import styles from './MemeTemplateEditor.module.scss';
import memeCommonStyles from '../MemeGenerator.module.scss';
import TextIcon from 'src/icons/TextIcon';
import { ActionButton } from 'ui/components';
import SettingsIcon from 'src/icons/SettingsIcon';
import Draggable from 'react-draggable';
import { BlockPicker } from 'react-color';
import TextSettings from './TextSettings/TextSettings';
import { gsap } from 'gsap';
import { useIsMobile } from 'src/hooks';


export default function MemeTemplateEditor({ meme, onSave, presetColors, saveModalMemeImage }) {
	const [memeTextInputs, setMemeTextInputs] = useState([]);
	const [colorPickerInfo, setColorPickerInfo] = useState({ show: false, targetTextIndex: 0 });
	const [textSettingsInfo, setTextSettingsInfo] = useState({ show: false, targetTextIndex: 0 });
	const [memeTextPositions, setMemeTextPositions] = useState({});
	const [memeFontTextSizes, setMemeTextFontSizes] = useState({});
	const defaultColorPickerColor = '#ffffff';
	const [memeTextColors, setMemeTextColors] = useState( Array( 3 ).fill( defaultColorPickerColor ) );
	const memeImageRef = useRef();
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	const [dimensionDivisionFactor, setDimensionDivisionFactor] = useState( 5 );
	const isMobile = useIsMobile();

	// start of hack for Safari not showing text shadow or text outline
	const [isSafari, setIsSafari] = useState( true );
	useEffect( () => {
		const userAgent = window.navigator.userAgent;
		const _isSafari = userAgent.includes( 'Safari' ) && !userAgent.includes( 'Chrome' );
		setIsSafari( _isSafari );
	}, []);
	// end of hack

	useEffect( () => {
		const img = new Image();
		img.src = meme?.imageSrc;
		img.onload = () => {
			setDimensions({ width: img.width, height: img.height });
		};
	}, [meme?.imageSrc]);

	const addTextInput = () => {
		setMemeTextInputs( ( prev ) => [...prev, '']);
	};

	const saveMeme = () => {
		const memeImage = <MemeTemplateEditorImage />;
		saveModalMemeImage( memeImage );
		onSave( dimensions );
	};

	const onUpdateTextColor = ( color ) => {
		const newColors = memeTextColors;
		const { targetTextIndex } = colorPickerInfo;
		newColors[targetTextIndex] = color.hex;
		setMemeTextColors( newColors );
		setColorPickerInfo( ( prev ) => {
			return { ...prev, show: false };
		});
	};

	const handleTextChange = ( value, idx ) => {
		const memeNewTextInputs = memeTextInputs.map( ( prevText, index ) => {
			if ( index === idx ) return value;
			else return prevText;
		});
		setMemeTextInputs( memeNewTextInputs );
	};

	const toggleColorPicker = ( index ) => setColorPickerInfo( ( current ) => ({ show: !current.show, targetTextIndex: index }) );
	const toggleTextSettings = ( index ) => setTextSettingsInfo( ( current ) => ({ show: !current.show, targetTextIndex: index }) );

	const onTextSizeChange = ( fontSize ) => {
		const newTextFontSizes = memeFontTextSizes;
		const { targetTextIndex } = textSettingsInfo;
		newTextFontSizes[targetTextIndex] = fontSize;
		setMemeTextFontSizes( newTextFontSizes );
		setTextSettingsInfo( ( prev ) => {
			return { ...prev, show: false };
		});
	};


	const onDraggableTextChange = ( event, dragElement, elementIndex ) => {
		const updatedMemeTextPositions = memeTextPositions;
		updatedMemeTextPositions[elementIndex] = { x: dragElement.x, y: dragElement.y };
		setMemeTextPositions( updatedMemeTextPositions );
	};

	useEffect( ()=>{
		if( screen.width <= 768 ){
			// increasing divison factor to ensure text placement still persist
			// if we kept at 5 , text position would differ at modal and image output
			setDimensionDivisionFactor( 7 );
		}
	}, []);

	const MemeTemplateEditorImage = () => {
		return <div className={styles['meme-template-editor-image-container']}
			id="meme-editor">
			<div ref={memeImageRef}
				className={styles['meme-template-editor-image']}
				style={{ backgroundImage:`url(${meme?.imageSrc})` }}>
				<img className={styles['meme-template-editor-image-clone']}
					src={meme.imageSrc}
					style={{ width:dimensions.width / dimensionDivisionFactor, height:dimensions.height / dimensionDivisionFactor }}/>
			</div>
			<div className={styles['meme-template-editor-image-container-texts']}
				style={{ maxWidth:dimensions.width / dimensionDivisionFactor, maxHeight:dimensions.height / dimensionDivisionFactor }}>
				{memeTextInputs?.map( ( text, index ) => {
					const memeTextColor = memeTextColors[index];
					const box = memeImageRef.current.getBoundingClientRect();
					const xCenter = ( box.width ) / 2;
					const yCenter = ( box.height ) / 2;
					const defaultPosition = memeTextPositions[`${index}`] ?? { x:xCenter, y:yCenter };
					// isSafari is a hack
					const textShadow = ( ( memeTextColor === '#ffffff' ) && !isSafari ) ? 'black 1px 1px 10px' : undefined;
					// refer to https://trello.com/c/lpfmL208/131-meme-generator-on-safari-text-shadow-is-not-showing
					return <Draggable key={index}
						onStop={( e, data ) => onDraggableTextChange( e, data, index )}
						defaultPosition={defaultPosition && defaultPosition} >
						<div className={styles['meme-template-editor-image-container-texts-item']}
						>
							{/* note: we are forcing bold and uppercase for the meantime */}
							{text.split( '\n' ).map( str => <p
								key={str}
								style={{ lineHeight:'100%', margin:0, color: memeTextColor, fontSize: `${memeFontTextSizes[`${index}`]}px`, fontWeight: 600, textTransform: 'uppercase', textShadow, letterSpacing:'150%' }}>
								{ str }
							</p> )}
						</div>
					</Draggable>;
				})}
			</div>
		</div>;
	};

	return (
		<div
			id="meme-template-editor"
			className={styles['meme-template-editor']}
		>
			<h3 className={memeCommonStyles['meme-generator-title']}>
				<span className={memeCommonStyles['meme-generator-title-icon']}><TextIcon /></span>
				{' '}
Add Text
			</h3>

			<div className={styles['meme-template-editor-controller']}>
				<MemeTemplateEditorImage />
				<div className={styles['meme-template-editor-text-container']}
					id="meme-text-inputs"
				>
					<h4 className={styles['meme-template-editor-text-container-title']}>{meme?.title}</h4>

					{memeTextInputs?.map( ( text, idx ) => {
						return <div key={idx}
							className={styles['meme-template-editor-text-container-text-box']}>
							<div className={styles['meme-template-editor-text-container-text-box-centered']}>
								<textarea onChange={( e ) => handleTextChange( e.target.value, idx )}
									defaultValue={text}
									onFocus={() => isMobile && gsap.to( window, { duration: 1, scrollTo: { y: '#meme-text-inputs', offsetY: 300 - ( 80 * idx ) }})}
									className={styles['meme-template-editor-text-container-text-box-input']}
									style={{ color: '#FFF', fontSize: 16 }} // font size needs to be 16 otherwise ios will force zoom
								/>
								<div className={styles['meme-template-editor-text-container-text-box-controller']}>
									<span onClick={() => toggleColorPicker( idx )}
										className={styles['meme-template-editor-text-container-text-box-color-selector']}
										style={{ backgroundColor: `${memeTextColors[idx]}` }}></span>
									<span onClick={() => toggleTextSettings( idx )}
										className={styles['meme-template-editor-text-container-text-box-settings']}>
										<SettingsIcon />
									</span>
								</div>
							</div>
						</div>;
					})}
					{/* TODO: move BlockPicker and TextSettings into the .map block aboveco */}
					{colorPickerInfo.show && <BlockPicker color={memeTextColors[colorPickerInfo.targetTextIndex]}
						className={styles['meme-template-editor-color-picker']}
						colors={presetColors}
						triangle='hide'
						onChangeComplete={onUpdateTextColor} />}
					{textSettingsInfo.show && <TextSettings defaultSize={memeFontTextSizes[textSettingsInfo.targetTextIndex] ?? '20'}
						onFontSizeChange={onTextSizeChange} />}
					{/* textContent, onClick, className */}
					<ActionButton textContent={'Add Text'}
						onClick={addTextInput}
						className={styles['meme-template-editor-button-add']} />
					<ActionButton textContent={'Save meme'}
						onClick={saveMeme}
						className={styles['meme-template-editor-button-save']} />

				</div>
			</div>

		</div>
	);
}
