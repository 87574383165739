import React from 'react';

interface OdysseyButtonProps {
	text: string;
	onClick: () => void;
	className?: string;
	isActive?: boolean;
	isDisabled?: boolean;
	textClassName?: string;
}

export const OdysseyButton: React.FC<OdysseyButtonProps> = ({ text, onClick, className, isActive, isDisabled, textClassName }) => {
	return (
		<button
			className={`w-[152px] max-[600px]:w-[120px] box-border text-[24px] max-[600px]:text-[18px] text-bold uppercase rounded-[8px] -f-family-burbank px-4 ${!isActive || isDisabled ? 'bg-black' : 'bg-[#B3FE00]'} ${className}`}
			onClick={onClick}
			disabled={isDisabled}
		>
			<p className={`block w-full ${!isActive || isDisabled ? 'text-white' : 'text-black'} ${textClassName}`}>{text}</p>
		</button>
	);
};
