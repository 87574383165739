
// Dependencies
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useSpring, animated as a, easings } from '@react-spring/web';

// App
import { useStore } from 'base/state';
import { Image } from 'ui/components';

//	Styles
import styles from './loading-view.module.scss';


//
//	RAYC / UI / Views / Loading
//


export default function LoadingView() {
	const isLoaded = useStore( s => s.isLoaded );

	const [ containerSpringStyles, containerSpringApi ] = useSpring( () => ({
		opacity: 1,
		config: { duration: 600 },
		delay: 600,
	}) );

	const [ contentSpringStyles, contentSpringApi ] = useSpring( () => ({
		y: '0%',
		opacity: 1,
		config: { easing: easings.easeInBack, duration: 400 },
	}) );

	useEffect( () => {
		( isLoaded ? clearAllBodyScrollLocks : disableBodyScroll )( document.body, { reserveScrollBarGap: true });
		if ( isLoaded ) {
			containerSpringApi.start({ opacity: 0 });
			contentSpringApi.start({ y: '-10%', opacity: 0 });
		}
	}, [ isLoaded, containerSpringApi, contentSpringApi ]);

	return (
		<a.div
			className={ clsx( styles[ 'v-loading' ], '-t-dark', { [ styles[ '-is-loaded' ] ]: isLoaded }) }
			tab-index="-1"
			style={ containerSpringStyles }
			id="loading-overlay"
		>
			<section>
				<a.div className="grid -g-cols-1 -a-center"
					style={ contentSpringStyles }>
					<Image className={ clsx( styles[ 'v-loading_img' ], '-ha-center' ) }
						src="/images/logo-glitch.mp4"
						width={ 422 }
						height={ 402 }
						alt="" />
					<h1 className="f2 -f-caps -f-primary-gradient -mb0">Loading...</h1>
					<p className="f8 -mt0">Still faster than the Ethereum network.</p>
				</a.div>
			</section>
		</a.div>
	);
}
