import { createTRPCProxyClient, httpBatchLink } from "@trpc/client"
import type { AppRouter } from "@api/trpc"
import { isDev, API_BASE_URI_LOCAL, API_BASE_URI_PROD } from "src/base/constants";


const transformer = {
    serialize: (data: any) => data,
    deserialize: (data: any) => data,
};

const trpcUrl = isDev ? `${API_BASE_URI_LOCAL}/trpc` : `${API_BASE_URI_PROD}/trpc`


export const trpcClient = createTRPCProxyClient<AppRouter>({
    links: [httpBatchLink({ url: trpcUrl })],
    transformer
})


export async function getGreeting() {
    const greetingMessage = await trpcClient.getGreeting.query()
    return greetingMessage
}