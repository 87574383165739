// exported as react component to allow fill overriding props and to having having big file at Icon folder
import React from 'react';

export default function FoxLarge() {
	return (
		<svg
			width="42"
			height="42"
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.6457 2.35925L11.0486 7.24063L12.2754 4.36388L17.6457 2.35925Z"
				fill="#E17726"
				stroke="#E17726"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M2.42932 2.35925L8.96767 7.28619L7.79965 4.36387L2.42932 2.35925Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M15.2706 13.6776L13.5153 16.3591L17.2738 17.394L18.3505 13.7362L15.2706 13.6776Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M1.7301 13.7362L2.80025 17.394L6.5523 16.3591L4.80351 13.6776L1.7301 13.7362Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M6.3503 9.14698L5.30627 10.7221L9.02568 10.8913L8.90172 6.88855L6.3503 9.14698Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M13.7239 9.14745L11.1334 6.84344L11.0486 10.8917L14.768 10.7225L13.7239 9.14745Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M6.55334 16.3585L8.80454 15.2716L6.86657 13.7616L6.55334 16.3585Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M11.2701 15.2716L13.5148 16.3585L13.2081 13.7616L11.2701 15.2716Z"
				fill="#E27625"
				stroke="#E27625"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M13.5148 16.3583L11.2701 15.2714L11.4528 16.7293L11.4332 17.3476L13.5148 16.3583Z"
				fill="#D5BFB2"
				stroke="#D5BFB2"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M6.55334 16.3583L8.64145 17.3476L8.62839 16.7293L8.80454 15.2714L6.55334 16.3583Z"
				fill="#D5BFB2"
				stroke="#D5BFB2"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M8.68033 12.7984L6.81409 12.2517L8.13219 11.6464L8.68033 12.7984Z"
				fill="#233447"
				stroke="#233447"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M11.3942 12.7984L11.9423 11.6464L13.2669 12.2517L11.3942 12.7984Z"
				fill="#233447"
				stroke="#233447"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M6.55211 16.3591L6.87839 13.6776L4.80334 13.7362L6.55211 16.3591Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M13.1949 13.6776L13.5147 16.3591L15.27 13.7362L13.1949 13.6776Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M14.768 10.7226L11.0486 10.8918L11.3944 12.7988L11.9426 11.6468L13.2672 12.2521L14.768 10.7226Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M6.81373 12.2521L8.13184 11.6468L8.67998 12.7988L9.02581 10.8918L5.3064 10.7226L6.81373 12.2521Z"
				fill="#CC6228"
				stroke="#CC6228"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M5.3064 10.7226L6.86595 13.7621L6.81372 12.2521L5.3064 10.7226Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M13.2673 12.252L13.2086 13.762L14.7682 10.7225L13.2673 12.252Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M9.02617 10.8905L8.6803 12.7975L9.11749 15.0494L9.21538 12.0816L9.02617 10.8905Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M11.0486 10.8905L10.8658 12.0751L10.9572 15.0494L11.3944 12.7975L11.0486 10.8905Z"
				fill="#E27525"
				stroke="#E27525"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M11.3948 12.7986L10.9576 15.0505L11.2709 15.2718L13.2089 13.7618L13.2676 12.2518L11.3948 12.7986Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M6.81409 12.2518L6.86632 13.7618L8.8043 15.2718L9.11753 15.0505L8.68033 12.7986L6.81409 12.2518Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M11.4343 17.3486L11.4538 16.7302L11.2842 16.587H8.79154L8.62839 16.7302L8.64145 17.3486L6.55334 16.3593L7.28417 16.958L8.76542 17.9799H11.3037L12.7915 16.958L13.5158 16.3593L11.4343 17.3486Z"
				fill="#C0AC9D"
				stroke="#C0AC9D"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M11.2703 15.2719L10.9571 15.0507H9.11694L8.80377 15.2719L8.62756 16.7299L8.79071 16.5867H11.2834L11.453 16.7299L11.2703 15.2719Z"
				fill="#161616"
				stroke="#161616"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M17.9259 7.55953L18.4806 4.86503L17.6453 2.35925L11.2701 7.07794L13.7236 9.14761L17.1885 10.1565L17.952 9.2648L17.6193 9.02395L18.1478 8.54236L17.7432 8.22993L18.2718 7.8264L17.9259 7.55953Z"
				fill="#763E1A"
				stroke="#763E1A"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M1.59338 4.86503L2.15456 7.55953L1.79567 7.8264L2.33074 8.22993L1.92617 8.54236L2.45472 9.02395L2.12193 9.2648L2.88539 10.1565L6.35034 9.14761L8.80382 7.07794L2.42862 2.35925L1.59338 4.86503Z"
				fill="#763E1A"
				stroke="#763E1A"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M17.189 10.1571L13.7241 9.14832L14.7682 10.7234L13.2086 13.7628L15.2706 13.7368H18.3505L17.189 10.1571Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M6.34998 9.14832L2.88508 10.1571L1.7301 13.7368H4.80351L6.86548 13.7628L5.30596 10.7234L6.34998 9.14832Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
			<path
				d="M11.049 10.8912L11.2709 7.07718L12.2758 4.36316H7.79944L8.80431 7.07718L9.02617 10.8912L9.11101 12.0888L9.11754 15.0501H10.9577L10.9642 12.0888L11.049 10.8912Z"
				fill="#F5841F"
				stroke="#F5841F"
				strokeWidth="0.0586369"
				strokeLinejoin="round"
				strokeLinecap='round'
			/>
		</svg>
	);
}
