export default function ArrowLeftIcon(){
	return <svg width="30"
		height="21"
		viewBox="0 0 30 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M30 9.74681L30 11.2468C30 11.661 29.6642 11.9968 29.25 11.9968L4.995 11.9968L11.67 18.6568C11.812 18.7976 11.8918 18.9893 11.8918 19.1893C11.8918 19.3893 11.812 19.581 11.67 19.7218L10.605 20.7718C10.4642 20.9138 10.2725 20.9937 10.0725 20.9937C9.87252 20.9937 9.68082 20.9138 9.54 20.7718L0.329995 11.5768C0.118956 11.366 0.000258359 11.0801 -2.92195e-06 10.7818L-2.87212e-06 10.2118C0.00345136 9.91418 0.121669 9.62938 0.329995 9.4168L9.54 0.221804C9.68082 0.0798208 9.87252 -4.37213e-05 10.0725 -4.37038e-05C10.2725 -4.36863e-05 10.4642 0.0798208 10.605 0.221804L11.67 1.2868C11.8111 1.42505 11.8906 1.61427 11.8906 1.81181C11.8906 2.00934 11.8111 2.19856 11.67 2.3368L4.995 8.9968L29.25 8.99681C29.6642 8.99681 30 9.33259 30 9.74681Z"
			fill="#B3FE00"/>
	</svg>;
    
}
