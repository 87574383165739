//	Dependencies
import PropTypes from 'prop-types';
import clsx from 'clsx';

//	App
import { CanvasImage } from 'ui/components';

//	Styles
import styles from './image.module.scss';


//
//	RAYC / UI / Sections / Image
//


export default function ImageSection({ src, className, theme }) {
	const _classTheme = theme && `-t-${ theme.toLowerCase() }`;

	return (
		<CanvasImage src={ src }
			element={ 'section' }
			className={ clsx( 'section -py0', styles[ 's-image' ], _classTheme, className ) } />
	);
}



ImageSection.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
