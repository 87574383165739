import React, { useEffect, useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';

interface TiltImageProps {
    imageSrc: string;
    className?: string
}

export default function TiltImage({ imageSrc, className }: TiltImageProps) {
    const tiltRef = useRef(null);

    useEffect(() => {
        VanillaTilt.init(tiltRef.current, {
            max: 25,
            speed: 1000,
            glare: true,
            transition: true,
            'max-glare': 0.5,
        });

        return () => tiltRef?.current?.vanillaTilt?.destroy();
    }, []);

    return (
        <div>
            <div className={`${className}`}>
                <div ref={tiltRef} className='card rounded-lg w-full h-full relative hover:shadow-xl'>
                    <h2 className='name text-gray-100 text-center text-xl font-medium uppercase transition-all duration-500 opacity-0 z-10 absolute top-0 left-0 w-full'>Cyber Punk</h2>

                    <a href='#' className='buy absolute bottom-0 bg-gray-600 text-white hover:text-black font-medium px-4 py-2 rounded-full transition-all duration-500 opacity-0 z-10'>Buy now</a>
                    <div className='image image-1'>
                        <img src={imageSrc} alt="my image" />
                    </div>
                </div>
            </div>
        </div>
    );
}
