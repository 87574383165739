let linkObjectIdCounter = 0;

function createLinkObject( paragraph, subParagraph, ctaText, ctaLink ) {
	const id = String( ++linkObjectIdCounter );

	return {
		id,
		paragraph,
		isParagraphBold: true,
		subParagraph,
		cta: {
			text: ctaText,
			link: ctaLink,
		},
	};
}

export const raLinks = [
	createLinkObject( 'Rare Apepes Discord', 'Official Rare Apepes Discord', 'Go to link', 'https://discord.com/invite/FN3GZqJ9w9' ),
	createLinkObject( 'Rare Apepes Medium', 'Official Rare Apepes Medium Publication', 'Go to link', 'https://rareapepes.medium.com/' ),
	createLinkObject( 'Rare Apepes Youtube', 'Official Rare Apepes Youtube Channel', 'Go to link', 'https://www.youtube.com/@RareApepes' ),
	createLinkObject( 'Rare Apepes Instagram', 'Official Rare Apepes Instagram', 'Go to link', 'https://www.instagram.com/rareapepesnft' ),
];

export const web3SafetyLinks = [
	createLinkObject( 'Delegation', 'Delegate.cash', 'Go to link', 'https://delegate.cash' ),
];
