import React from 'react';

export default function FolderIcon() {
	return (
		<svg
			width="31"
			height="30"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.5 7.5H18.5125C18.1846 7.49862 17.8703 7.36843 17.6375 7.1375L15.8625 5.3625C15.6297 5.13157 15.3154 5.00138 14.9875 5H5.5C4.11929 5 3 6.11929 3 7.5V22.5C3 23.8807 4.11929 25 5.5 25H25.5C26.8807 25 28 23.8807 28 22.5V10C28 8.61929 26.8807 7.5 25.5 7.5ZM12.375 8.75C13.4105 8.75 14.25 9.58947 14.25 10.625C14.25 11.6605 13.4105 12.5 12.375 12.5C11.3395 12.5 10.5 11.6605 10.5 10.625C10.5 9.58947 11.3395 8.75 12.375 8.75ZM23.625 21.25C23.9702 21.25 24.25 20.9702 24.25 20.625V20.2C24.2491 20.0649 24.2053 19.9336 24.125 19.825L18.8125 12.7375C18.6916 12.5846 18.5074 12.4955 18.3125 12.4955C18.1176 12.4955 17.9334 12.5846 17.8125 12.7375L14.2125 17.3875C14.0932 17.5434 13.9088 17.6356 13.7125 17.6375C13.5284 17.6377 13.354 17.5551 13.2375 17.4125L11.425 15.225C11.3046 15.0805 11.1256 14.9978 10.9375 15C10.7659 15.018 10.6085 15.1034 10.5 15.2375L6.8875 19.825C6.79833 19.9296 6.74955 20.0626 6.75 20.2V20.625C6.75 20.9702 7.02982 21.25 7.375 21.25H23.625Z"
			/>
		</svg>
	);
}
