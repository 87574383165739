import React from 'react';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

// hides children unless address is team address
export default function FeatureFlagGuardComponent({ featureFlag, address, children }) {
	const show = useFeatureFlag( featureFlag, address );

	return (
		<>
			{show && children}
		</>
	);
}


