

export interface Council {
	imageSrc: string;
	name: string;
	headline: string;
	paragraph: any;
	cta: {
		text: string;
		link: string;
		isExternal: boolean;
	};
}

export const councils: Council[] = [
	{
		imageSrc: 'https://data.rareapepes.com/6512.png',
		name: '@Rudazzle',
		headline: 'The Apepe Alchemist',
		paragraph:  <span>
			"In the depths of the ApepeVerse, where the water is as clear as mud, emerges Rudazzle. Not just any frog in the pond, but the mastermind behind the legendary <a href='https://nftrefinery.xyz/project/RareApepes'>@NFTRefineryXYZ</a> tool. This isn't just a tool; it's the Swiss Army knife for every Apepe connoisseur, crafted by the webbed hands of Rudazzle himself. His dedication to our swampy utopia is nothing short of heroic, a beacon of innovation and cooperation. When he's not refining tools, he's probably refining his collection of Rare Apepes, each more dazzling than the last." </span>,
		cta: {
			text: 'Visit Twitter',
			link: 'https://twitter.com/RudazzleNFT',
			isExternal: true,
		}
	},
	{
		imageSrc: 'https://data.zombieapepes.com/10007.jpg',
		name: '@Marco_Tassotti aka PATZO',
		headline: 'The OG Apepe Guardian',
		paragraph: "Hop into the time machine and set the dials to March 2022, and you might catch a glimpse of PATZO minting his way into Apepe history. A true OG, PATZO's heart is as big as his collection, launching the PATZO GIVES BACK initiative and raining Rare Apepe NFTs like flies at a frog fest. He's the Robin Frog of the ApepeVerse, rescuing stolen Apepes with a flick of his tongue and a dash of daring. A valued member? More like one of the MVP’s of the pond!",
		cta: {
			text: 'Visit Twitter',
			link: 'https://twitter.com/Marco_Tassotti',
			isExternal: true,
		}
	},

	{
		imageSrc: 'https://data.rareapepes.com/652.png',
		name: '@ImWobbie',
		headline: 'The Meme Maestro',
		paragraph: "If laughter is the best medicine, then ImWobbie is the ApepeVerse's chief pharmacist, dispensing memes that cure the blues faster than a fly can buzz. A memetic aficionado, his creations embody the spirit of Rare Apepes, spreading joy and good vibes across the web3 waters. His alpha calls? Legendary. His financial advice? Degen style. Follow at your own risk, but remember, in the ApepeVerse, risk comes with a side of hilarity.",
		cta: {
			text: 'Visit Twitter',
			link: 'https://twitter.com/ImWobbie',
			isExternal: true,
		}
	},
	{
		imageSrc: 'https://data.rareapepes.com/9703.png',
		name: '@4LCLockharT4LC',
		headline: 'The Apepe Evangelist',
		paragraph: "In every community, there's that one member whose positivity is infectious, whose support lifts the spirits of all around them. In the ApepeVerse, that's 4LCLockharT4LC. Sharing her Apepe collection with passion and positivity, she's a true representative of the heartbeat of our community, proving that sometimes, a little cheer goes a long way. Her unwavering support? Priceless. Her impact? Immeasurable.",
		cta: {
			text: 'Visit Twitter',
			link: 'https://twitter.com/4LCLockharT4LC',
			isExternal: true,
		}
	},
	{
		imageSrc: 'https://data.rareapepes.com/5500.png',
		name: '@DelBroko aka Adzilla',
		headline: 'The Degen Storyteller',
		paragraph: "Last but not least, DelBroko, the most degen of all motherfroggers. A prolific collector and an even more prolific giver, his contributions to the ApepeVerse are the stuff of legend. Creating personas for his Apepes with more flair than a peacock, DelBroko's creativity knows no bounds. His initiatives keep our community bonded, spirited, and always hopping forward.",
		cta: {
			text: 'Visit Twitter',
			link: 'https://twitter.com/delbroko',
			isExternal: true,
		}
	},
	{
		imageSrc: 'https://data.rareapepes.com/8211.png',
		name: '@Primalkey',
		headline: 'The Apepe Alchemist',
		paragraph: "From the dawn of the Apepe excitement, Primalkey has been there, a grail collector with a mind as sharp as a heron's beak. A cryptocurrency OG, his insights into the industry are as deep as the pond itself. Always curious, always learning, Primalkey's wisdom benefits not just his portfolio, but the entire ApepeVerse. His presence? A treasure. His advice? Invaluable.",
		cta: {
			text: 'Visit Twitter',
			link: 'https://twitter.com/primalkey',
			isExternal: true,
		}
	}
];