function convertToBoolean( str ) {
	if ( str === 'true' ) {
		return true;
	} else if ( str === 'false' || str === '' || str === null || str === undefined ) {
		return false;
	} else {
		throw new Error( `Invalid input of ${str}. Expected 'true' or 'false'. ` );
	}
}

const SHOW_SERUM_SECTION = true;
const SHOW_ZOMBIE_SECTION = true;
const SHOW_APEPE_SECTION = false;
const SHOW_KEY_FORGE = true;
const SHOW_ODYSSEY = true;
const SHOW_CONNECT_WALLET = true;
const WALLET_CONNECT_ENABLED = false;
const SHOW_TOOL_TIP_X_TIMES = 2;
const ODYSSEY_COLLECTION_NAME = 'odyssey-collection-name';
const SHOW_ZOMBIE_PURGE = true;
const SHOW_KEYMASTERS_POT = true;
const SHOW_TERMS = true;
const SHOW_LICENSE = SHOW_TERMS;
const SHOW_WALLET_CONNECT_BUTTON = false;



// note: remember to lowercase both sides when comparing
export const TEAM_ADDRESSES = [
	'0x81332284cc0c502d3b3cd472001b40be37e809d6', // Marwan
	'0x6D0dd406Df66B0da57e0002B7aFD9c54d2d72916', // kountervailor
	'0xc29029DE05bF135a90C19F31F866F8774a39dBB6', // RD
	'0xdef558a341a5b870ae54D2b12adb7A122Ce0e3f9', // CeoTim
];

export {
	SHOW_ZOMBIE_PURGE,
	SHOW_SERUM_SECTION,
	SHOW_ZOMBIE_SECTION,
	SHOW_APEPE_SECTION,
	SHOW_ODYSSEY,
	SHOW_CONNECT_WALLET,
	ODYSSEY_COLLECTION_NAME,
	WALLET_CONNECT_ENABLED,
	SHOW_TOOL_TIP_X_TIMES,
	SHOW_KEY_FORGE,
	SHOW_KEYMASTERS_POT,
	SHOW_TERMS,
	SHOW_LICENSE,
	SHOW_WALLET_CONNECT_BUTTON,
};
