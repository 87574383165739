import { useState, useEffect, useMemo } from 'react';
import useNetwork from 'src/hooks/useNetwork';
import { CONTRACT_NAMES, KEY_ADDRESS_PROD, LORE_ADDRESS_PROD, RAYC_ADDRESS_PROD, ZAYC_ADDRESS_PROD, Z1_ADDRESS_PROD, ZAYC_ADDRESS_TEST, RAYC_ADDRESS_TEST, LORE_ADDRESS_TEST, KEY_ADDRESS_TEST, NETWORKS, LOOT_MAIN_ADDRESS_PROD, LOOT_MAIN_ADDRESS_TEST } from 'src/base/constants';
import getUserBalances from 'src/services/getUserBalances';
import { parseNftObjects } from './utils';

const contractNameToContractAddress = {
	[CONTRACT_NAMES.RAYC]: RAYC_ADDRESS_PROD,
	[CONTRACT_NAMES.ZAYC]: ZAYC_ADDRESS_PROD,
	[CONTRACT_NAMES.Z1]: Z1_ADDRESS_PROD,
	[CONTRACT_NAMES.LORE]: LORE_ADDRESS_PROD,
	[CONTRACT_NAMES.KEY]: KEY_ADDRESS_PROD,
	[CONTRACT_NAMES.LOOT]: LOOT_MAIN_ADDRESS_PROD,
};

const testContractNameToContractAddress = {
	[CONTRACT_NAMES.RAYC]: RAYC_ADDRESS_TEST,
	[CONTRACT_NAMES.ZAYC]: ZAYC_ADDRESS_TEST,
	[CONTRACT_NAMES.Z1]: Z1_ADDRESS_PROD, // TODO: replace with test
	[CONTRACT_NAMES.LORE]: LORE_ADDRESS_TEST,
	[CONTRACT_NAMES.KEY]: KEY_ADDRESS_TEST,
	[CONTRACT_NAMES.LOOT]: LOOT_MAIN_ADDRESS_TEST,
};

const filterUserBalances = ( userBalances, contractName, network ) => {
	if ( !network ) console.warn( ' filterUserBalances: network is undefined' );

	const isTestNetwork = network === NETWORKS.BASE_SEPOLIA.name;
	let contractAddress;
	if ( isTestNetwork ) {
		contractAddress = testContractNameToContractAddress[contractName].toLowerCase();
	} else {
		contractAddress = contractNameToContractAddress[contractName].toLowerCase();
	}
	if ( !contractName ) throw new Error( `filterUserBalances: no map for ${ contractName }` );

	return userBalances.filter( ({ contractAddress: address }) => address.toLowerCase() === contractAddress );
};


const useUserCollections = ( address, collections ) => {
	const { network } = useNetwork();
	const [userBalances, setUserBalances] = useState([]);
	const [loading, setLoading] = useState( true );

	useEffect( () => {
		if ( address && network ) {
			const options = collections ? { collections } : null;
			getUserBalances( network, address, options ).then( res => {
				setUserBalances( res );
				setLoading( false );
			});
		}
	}, [address, network]);

	const numCollectionsTotal = 6; // increase this as we include more collections
	const [ genesis, zombies, odyssey, keys, z1, loot ] = useMemo( () => {
		if ( !userBalances || !network ) {
			return Array( numCollectionsTotal ).fill([]);
		}
		// todo: optimize below
		const genesis = filterUserBalances( userBalances, CONTRACT_NAMES.RAYC, network );
		const zombies = filterUserBalances( userBalances, CONTRACT_NAMES.ZAYC, network );
		const odyssey = filterUserBalances( userBalances, CONTRACT_NAMES.LORE, network );
		const keys = filterUserBalances( userBalances, CONTRACT_NAMES.KEY, network );
		const z1 = filterUserBalances( userBalances, CONTRACT_NAMES.Z1, network );
		const loot = filterUserBalances( userBalances, CONTRACT_NAMES.LOOT, network );

		return [genesis, zombies, odyssey, keys, z1, loot];
	}, [userBalances, network]);

	return ({
		[CONTRACT_NAMES.RAYC]: genesis.map( x => parseNftObjects( x, true ) ),
		[CONTRACT_NAMES.ZAYC]: zombies.map( x => parseNftObjects( x, true ) ),
		[CONTRACT_NAMES.LORE]: odyssey.map( x => parseNftObjects( x, true ) ),
		[CONTRACT_NAMES.KEY]: keys.map( x => parseNftObjects( x, true ) ),
		[CONTRACT_NAMES.Z1]: z1.map( x => parseNftObjects( x, true ) ),
		[CONTRACT_NAMES.LOOT]: loot.map( x => parseNftObjects( x, true ) ),
		loading,
	});
};

export default useUserCollections;
