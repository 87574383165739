function createCommunityToolCard( imageSrc, paragraph, ctaText, ctaLink ) {
	return {
		imageSrc,
		paragraph,
		cta: {
			text: ctaText,
			link: ctaLink,
			isExternal: true,
		},
	};
}


export const communityToolCards = [
	createCommunityToolCard(
		'/dashboard/community-tools/nft-refinery.png',
		'NFT Refinery by @RudazzleNFT is a tool for customizing Apepe NFTs. It lets you show off your Apepes in various styles, with Apepe-themed quotes and changeable background layers for unique NFT display.',
		'Go to NFT Refinery',
		'https://nftrefinery.xyz/?ref=rareapepeswebsite',
	),
	createCommunityToolCard(
		'pond-times.jpg',
		'A Rare Apepe Newsletter — Community Led. Community Driven.',
		'Go to Pond Times',
		'https://twitter.com/RareApepeNews',
	),
];
