import { useState, useMemo } from 'react';
import Icon from '../icon/icon';
import styles from './AddressBox.module.scss';
import clsx from 'clsx';
import useCheckDelegations from 'src/hooks/useCheckDelegations';
import Image from '../image/image';
import useActiveAddress from 'src/hooks/useActiveAddress';
import { abbreviateAddress } from 'src/base/utils';
import { useStore } from 'base/state';


const AddressBox = ({ className, disconnectWallet, address }) => {
	const [showAddressDropDown, setShowAddressDropDown] = useState( false );
	const {
		delegations: { vaults },
		selectVault,
		removeSelectedVault,
		resetDelegations,
	} = useCheckDelegations( address );
	const api = useStore( s => s.api );
	const activeAddress = useActiveAddress( address );


	const dropdownOptions = useMemo( () => {
		const addresses = [];
		if ( !address ) return addresses;

		addresses.push({ value: address, displayValue: abbreviateAddress( address ), isVaultAddress: false });

		if ( vaults.length > 0 ) {
			vaults.forEach( ( vaultAddress ) => {
				addresses.push({ value: vaultAddress, displayValue: abbreviateAddress( vaultAddress ), isVaultAddress: true });
			},
			);
		}
		return addresses;
	}, [address, vaults]);



	const classes = clsx( className, styles['address-box'], 'z-30' );

	const triggerAddressDropDown = () => setShowAddressDropDown( ( prev ) => !prev );

	const handleWalletSelectionClick = ( e, walletSelection ) => {
		e.stopPropagation(); // to avoid automatically toggle off for dropdown by event bubbling
		if ( !walletSelection.isVaultAddress ) {
			removeSelectedVault();
		}
		else { selectVault( walletSelection.value ); }

		setShowAddressDropDown( false );

	};

	const handleLogout = ( e ) => {
		e.stopPropagation(); // to avoid automatically toggle off for dropdown by event bubbling
		disconnectWallet( resetDelegations );
		api.setToolTipTimesShown( 0 );
		api.setToolTipShown( false );
		setShowAddressDropDown( false );
	};

	const AddressDropDownOption = ({ displayValue, isVaultAddress, value }) => {
		return <div className={`${styles['address-box']} ${styles['address-box-variation-drop-down']}`}
			onClick={( e ) => handleWalletSelectionClick( e, { value, isVaultAddress })}>
			{isVaultAddress && <div className={styles['address-box-variation-drop-down-icon']}>
				<Image src="/images/delegateCash.png"
					width={10}
					height={10}
					alt="Delegate.Cash logo" />
			</div>}
			<div className={styles['address-box-text']}>
				{displayValue}
			</div>
		</div>;
	};

	const AddressDropDown = () => {
		return <div id="AddressDropdown"
			className={styles['address-drop-down']} >
			{dropdownOptions.map( ( option, index ) => <AddressDropDownOption key={index}
				{...option} /> )}
			{/* logout btn */}
			<div onClick={handleLogout}
				className={`${styles['address-box']} ${styles['address-box-variation-drop-down']} ${styles['address-box-variation-drop-down-logout']}`}>
				<div className={styles['address-box-text']}>
					Log out
				</div>
				{/* logout btn */}
			</div>
		</div>;
	};

	return (
		<div
			id="AddressBox"
			className={classes}
			onClick={triggerAddressDropDown}>
			{ vaults.includes( activeAddress ) && <div className={styles['address-box-wallet-icon']}>
				<Image src="/images/delegateCash.png"
					width={15}
					height={15}
					alt="Delegate.Cash logo" />
			</div>}
			{showAddressDropDown && <AddressDropDown options={dropdownOptions} />}
			<span className={styles['address-box-green-circle']} />
			<div className={styles['address-box-text']}>
				{activeAddress && abbreviateAddress( activeAddress )}
			</div>
			{!showAddressDropDown && <Icon className={styles['address-box-icon']}
				type="chevronDown" />}
			{showAddressDropDown && <Icon className={styles['address-box-icon-reversed']}
				type="chevronDown" />}
		</div>
	);
};

export default AddressBox;
