import React from 'react';
import clsx from 'clsx';

// App
import { Icon, Link } from '../../components';

// Styles
import styles from './button.module.scss';

interface ButtonGroupProps {
	children: React.ReactNode;
	className?: string;
	Group: React.FC<ButtonGroupProps>; // Define the Group property in ButtonProps
}

interface ButtonProps {
	children: React.ReactNode;
	className?: string;
	ariaLabel?: string;
	format?: string;
	size?: string;
	icon?: string;
	iconSize?: string;
	iconPos?: string;
	iconFormat?: string;
	element?: 'a' | 'button' | 'span';
	type?: 'submit' | 'button';
	href?: string;
	as?: string;
	external?: boolean;
	disabled?: boolean;
	focusable?: boolean;
	onClick?: () => void;
	Group: React.FC<ButtonGroupProps>;
	[key: string]: any;
}


const Button: React.FC<ButtonProps> = ({
	external = false,
	iconPos = 'after',
	element = 'button',
	type = 'button',
	...props
}) => {
	const { children, className, ariaLabel, format, size, icon, iconSize, iconFormat, ...restProps } = props;
	const _isIconButton = icon && !children;
	const _icon = icon ? <Icon className={styles['c-button_icon']} type={icon} format={iconFormat} size={iconSize} /> : <></>;
	const iconContent = typeof icon === 'string' ? _icon : icon;

	const sizeClass = size && styles[`-size-${size}`] || null;
	const formatClass = format && styles[`-format-${format}`] || null;
	const classes = clsx(styles['c-button'], className, formatClass, sizeClass, { [styles['-format-icon']]: _isIconButton });

	return (
		<Link className={classes} ariaLabel={ariaLabel} {...restProps}>
			<span className={styles['c-button_content']}>
				{iconPos === 'before' && iconContent}
				{children && <span className={styles['c-button_text']}>{children}</span>}
				{iconPos === 'after' && iconContent}
			</span>
		</Link>
	);
};


// const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, className, Group }: ButtonGroupProps) => {
// 	return (
// 		<div className={clsx(styles['c-button-group'], className)}>
// 			{children}
// 		</div>
// 	);
// };

// Button.Group = ButtonGroup; // Add the missing 'Group' property


export default Button;
