import React from 'react';

export default function LinkIcon({ className }) {
	return (
		<svg
			width="31"
			height="30"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.75 9.57499V9.99999C26.7511 11.3326 26.2201 12.6105 25.275 13.55L21.75 17.0875C21.0923 17.7389 20.0327 17.7389 19.375 17.0875L19.25 16.95C19.0118 16.707 19.0118 16.318 19.25 16.075L23.55 11.775C24.0088 11.2991 24.2605 10.6609 24.25 9.99999V9.57499C24.2505 8.90868 23.9851 8.26973 23.5125 7.79999L22.7 6.98749C22.2303 6.51494 21.5913 6.24946 20.925 6.24999H20.5C19.8337 6.24946 19.1947 6.51494 18.725 6.98749L14.425 11.25C14.182 11.4882 13.793 11.4882 13.55 11.25L13.4125 11.1125C12.7611 10.4548 12.7611 9.39516 13.4125 8.73749L16.95 5.18749C17.8956 4.25613 19.1728 3.73898 20.5 3.74999H20.925C22.2515 3.74895 23.524 4.27505 24.4625 5.21249L25.2875 6.03749C26.2249 6.97597 26.751 8.24851 26.75 9.57499ZM11.3125 17.425L17.925 10.8125C18.0424 10.6942 18.2021 10.6276 18.3688 10.6276C18.5354 10.6276 18.6951 10.6942 18.8125 10.8125L19.6875 11.6875C19.8058 11.8048 19.8724 11.9646 19.8724 12.1312C19.8724 12.2979 19.8058 12.4576 19.6875 12.575L13.075 19.1875C12.9576 19.3058 12.7979 19.3724 12.6313 19.3724C12.4646 19.3724 12.3049 19.3058 12.1875 19.1875L11.3125 18.3125C11.1942 18.1951 11.1276 18.0354 11.1276 17.8687C11.1276 17.7021 11.1942 17.5423 11.3125 17.425ZM17.45 18.75C17.207 18.5118 16.818 18.5118 16.575 18.75L12.2875 23.0125C11.8147 23.4881 11.1706 23.7539 10.5 23.75H10.075C9.4087 23.7505 8.76975 23.485 8.3 23.0125L7.4875 22.2C7.01495 21.7302 6.74948 21.0913 6.75 20.425V20C6.74948 19.3337 7.01495 18.6947 7.4875 18.225L11.7625 13.925C12.0007 13.682 12.0007 13.293 11.7625 13.05L11.625 12.9125C10.9673 12.2611 9.90768 12.2611 9.25 12.9125L5.725 16.45C4.7799 17.3895 4.24895 18.6674 4.25 20V20.4375C4.25227 21.7596 4.77812 23.0271 5.7125 23.9625L6.5375 24.7875C7.47599 25.7249 8.74852 26.251 10.075 26.25H10.5C11.8168 26.2577 13.0835 25.7456 14.025 24.825L17.5875 21.2625C18.2389 20.6048 18.2389 19.5452 17.5875 18.8875L17.45 18.75Z"
			/>
		</svg>
	);
}
