import axios from 'axios';
import { API_BASE_URI_DEV, API_BASE_URI_LOCAL, API_BASE_URI_PROD } from 'src/base/constants';
// import { NETWORKS } from 'src/base/constants';
// import { isSupportedNetworkName } from 'src/base/utils';

export async function getUserBalances( networkName = 'Ethereum', addresss, options ) {
	if ( !addresss ) {
		console.warn( 'address is falsy' );
		return null;
	}

	let baseUri = networkName === 'Ethereum' ? API_BASE_URI_PROD : API_BASE_URI_DEV;
	if ( window?.location?.hostname === 'localhost' ) {
		baseUri = API_BASE_URI_LOCAL;
	}

	const paramObj = { network: networkName };

	if ( options && options.collections ) {
		paramObj.collections = options.collections;
	}

	const url = `${baseUri}/balances/${addresss}`;

	try { 
		const response = await axios.get( url, { params: paramObj });
		return response.data;
	} catch ( error ) {
		console.error( 'getUserBalances: ', error );
		return null;
	}
}

export default getUserBalances;
