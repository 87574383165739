import { useEffect, useState } from 'react';
import { getLorePieceInfo } from 'src/services/lorePieceInfo';
import { NETWORKS } from 'src/base/constants';

export default function useGetPieceInfo( networkName = NETWORKS.ETH.name, chapterId, pieceId, address ) {
	const [pieceInfo, setPieceInfo] = useState( null );

	useEffect( () => {
		async function fetchPieceInfo() {
			if ( networkName && address || !networkName && !address ) {
				const pieceInfo = await getLorePieceInfo( networkName, chapterId, pieceId );
				setPieceInfo( pieceInfo );
			}
			
		}
		fetchPieceInfo();
	}, [networkName, chapterId, pieceId, address]);

	return pieceInfo;
}
