import { useState, useEffect } from 'react';

export default function useWalletBalance( address ) {
	const [balance, setBalance] = useState( null );
	const [loading, setLoading] = useState( true );
	const [error, setError] = useState( null );

	useEffect( () => {
		const fetchBalance = async () => {
			if ( !address ) {
				setBalance( null );
				setLoading( false );
				return;
			}

			try {
				const response = await window.ethereum.request({
					method: 'eth_getBalance',
					params: [address, 'latest'],
				});
        
				// Convert balance from wei (hex) to ETH
				const balanceInWei = parseInt( response, 16 );
				const balanceInEth = balanceInWei / Math.pow( 10, 18 );
        
				setBalance( balanceInEth );
				setError( null );
			} catch ( err ) {
				setError( err.message );
				setBalance( null );
			} finally {
				setLoading( false );
			}
		};

		fetchBalance();
	}, [address]);

	return [ balance, loading, error ];
}
