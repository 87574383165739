import React from 'react';

export default function SpeakerIcon() {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 19.854V3.854C18 3.30172 17.5523 2.854 17 2.854H16.46C16.1977 2.85511 15.9463 2.95926 15.76 3.144L12.76 6.094C11.6334 7.222 10.1042 7.85526 8.51 7.854H5C3.34315 7.854 2 9.19715 2 10.854V12.854C2 14.5109 3.34315 15.854 5 15.854H8.51C10.0869 15.8659 11.5957 16.4982 12.71 17.614L15.71 20.564C15.8963 20.7487 16.1477 20.8529 16.41 20.854H17C17.5523 20.854 18 20.4063 18 19.854ZM22 11.854C22 12.7195 21.7193 13.5616 21.2 14.254C21.1034 14.3588 20.9675 14.4184 20.825 14.4184C20.6825 14.4184 20.5466 14.3588 20.45 14.254L19.73 13.534C19.5627 13.365 19.5376 13.1016 19.67 12.904C20.1096 12.2365 20.1096 11.3715 19.67 10.704C19.5376 10.5065 19.5627 10.243 19.73 10.074L20.45 9.354C20.5526 9.2506 20.6945 9.19601 20.84 9.204C20.9817 9.2205 21.1111 9.2924 21.2 9.404C21.7297 10.11 22.0109 10.9714 22 11.854ZM12 21.774V22.354C12 22.6301 11.7761 22.854 11.5 22.854H9.57C9.21693 22.8539 8.89006 22.6677 8.71 22.364L6.43 18.564C6.24541 18.2576 6.12308 17.9178 6.07 17.564H8.51C9.04785 17.5611 9.58112 17.663 10.08 17.864L10.5 20.614C10.6392 20.9172 10.9206 21.131 11.25 21.184L11.58 21.244C11.8364 21.2849 12.0188 21.5151 12 21.774Z"
			/>
		</svg>
	);
}
