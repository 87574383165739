
//	Dependencies
import { createContext, useContext, useRef, useEffect, useState, useCallback } from 'react';
import Router from 'next/router';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

//	App
import { useStore } from 'base/state';
import { isClient } from 'base/utils';
import { useIsMobile } from 'src/hooks';


//
//	Frontage / Hooks / useScrollSpring
//


export const ScrollSpringContext = createContext();
gsap.registerPlugin( ScrollToPlugin );

function lerp( start, end, amt ) {
	return ( 1 - amt ) * start + amt * end;
}

export function usePrevious( state ) {
	const ref = useRef();
	useEffect( () => {
		ref.current = state;
	});
	return ref.current;
}

export function useIsRouting( _default ) {
	const [ currValue, setValue ] = useState( true );

	useEffect( () => {
		const handleRouteChange = url => {
			setValue( true );
		};
		const handleRouteChangeComplete = url => {
			setValue( () => _default );
		};
		Router.events.on( 'routeChangeStart', handleRouteChange );
		Router.events.on( 'routeChangeComplete', handleRouteChangeComplete );
		Router.ready( handleRouteChangeComplete );

		return () => {
			Router.events.off( 'routeChangeStart', handleRouteChange );
			Router.events.off( 'routeChangeComplete', handleRouteChangeComplete );
		};
	}, []);

	return currValue;
}

export const ScrollSpringContextProvider = ({ children }) => {
	const api = useStore( s => s.api );

	const scrollState = useRef({
		scrollY: 0,
		scrollYSmooth: 0,
	});

	const [ scrollNode, setScrollNode ] = useState( null );
	const [ mainNode, setMainNode ] = useState( null );
	const [ isReady, setIsReady ] = useState( false );

	const isRouting = useIsRouting( false );
	const isMobile = useIsMobile();
	const isDisabled = isRouting;

	const getScrollY = useCallback( () => {
		const sY = scrollState.current.scrollYSmooth;
		return sY;
	}, []);

	const _updateScroll = useCallback( () => {
		if ( !mainNode ) return;
		if ( !isRouting ) {
			const currY = scrollState.current.scrollYSmooth;
			const y = scrollState.current.scrollY;
			const lerpY = lerp( currY, y, isMobile ? 0.5 : 0.075 ).toFixed( 1 );

			const transform = `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,${-lerpY},0,1)`;

			mainNode.style.transform = transform;
			scrollState.current.scrollYSmooth = +lerpY;

		} else {
			scrollState.current.scrollYSmooth = 0;
			mainNode.style.transform = '';
		}

	}, [ mainNode, isRouting, isMobile ]);

	useEffect( () => {
		if ( !scrollNode ) return;

		gsap.ticker.add( _updateScroll );

		setIsReady( true );

		return () => {
			setIsReady( false );
			gsap.ticker.remove( _updateScroll );
		};

	}, [ scrollNode, isDisabled, _updateScroll ]);

	if ( isClient() ) {
		useEffect( () => {
			const _scrollHandler = () => {
				const scrollY = window.pageYOffset;

				scrollState.current.scrollDelta = scrollY - scrollState.current.scrollY;
				scrollState.current.scrollY = scrollY;
			};
			window.addEventListener( 'scroll', _scrollHandler, { passive: true });

			_scrollHandler();
			return () => {
				window.removeEventListener( 'scroll', _scrollHandler );
			};
		}, [ api ]);
	}

	const value = [ 0, { mainNode, scrollNode, deviceType: 'desktop', isReady, isDisabled, isRouting, setScrollNode, setMainNode, getScrollY, updateScroll: _updateScroll }];
	return <ScrollSpringContext.Provider value={ value }
		children={ children } />;
};

export default function useScrollSpring() {
	const spring = useContext( ScrollSpringContext );
	return spring;
}
