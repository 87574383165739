/* eslint-disable @next/next/no-img-element */

// components
import Ribbon from '../../../components/Ribbon/Ribbon';

// others
import { LORE_PIECE_STATUSES } from 'src/base/constants';
import styles from './Album.module.scss';



function CardHeader({ status, image, id, connected, showRevealedImage }) {
	const isPlaceholder = status === LORE_PIECE_STATUSES.PLACEHOLDER;
	if ( status !== LORE_PIECE_STATUSES.PLACEHOLDER ) {
		// suffix image with _s
		image = image.replace( '.jpeg', '_s.jpeg' );
	}

	if ( showRevealedImage ) {
		// revealed
		return (
			<div
				className={`rounded-t-xl w-full h-[auto] ${styles['oddysey-album_card_img']} ${!connected ? styles['oddysey-album_card_img_light'] : ''
				}`}
				style={{ backgroundImage: `url(${image}` }}
			>
				<img src={image} />
			</div>
		);
	} else {
		
		// unreleased or minting but not immediate reveal
		// note: after token ID 8, we don't have unique pre-reveal images
		if ( id > 8 ) {
			// use the same pre-reveal image for all
			image = '/images/odyssey/card.jpeg';
		}
		return (
			<div
				className={`rounded-t-xl w-full h-[auto] ${styles['oddysey-album_card_img']} ${!connected ? 'opacity-70' : ''}`}
				style={{ opacity: !isPlaceholder ? 'unset' : 0.5, cursor: !isPlaceholder ? 'unset' : 'not-allowed' }}
			>
				<img src={image}/>
			</div>
		);
	}
}



export default function Card({ card, saleStatus, quantityDisplay, onCardClick, connected }) {
	const showRibbon = connected && ( card.status === LORE_PIECE_STATUSES.REVEALED || card.userOwns > 0 );

	const chapterNumber = card.name.split( ' ' )[0];
	const cardTitle = card.name.split( ' ' ).slice( 1 ).join( ' ' );
	const isMinting = card.status === LORE_PIECE_STATUSES.MINTING;
	const showRevealedImage = card.status === LORE_PIECE_STATUSES.REVEALED || card.userOwns > 0;

	return (
		<div
			className={` relative text-center  cursor-pointer mb-[100px] h-auto w-[300px] max-[750px]:w-[300px] max-[650px]:w-[250px]   max-[550px]:mb-[40px] max-[550px]:w-full  ${styles['oddysey-album_card']}`}
			onClick={() => onCardClick( card )}
		>
			{showRibbon && <Ribbon className={`${styles['oddysey-album_card_ribbon']}`}
				ribbonValue={card.userOwns || '0'}
				ribbonLabel="Owned" />}
			<CardHeader status={card.status}
				image={card.image}
				userOwns={card.userOwns}
				id={card.id}
				isMinting={isMinting}
				connected={connected}
				showRevealedImage={showRevealedImage} />
			<div className='w-full h-[190px] px-6 rounded-b-xl bg-black text-start box-border'>
				<p className='m-0 pt-5 mb-3  -f-family-burbank text-[25px] font-bold'>
					<span className='text-[#B3FE00]'>{chapterNumber}</span>
					{' '}
					{cardTitle}
				</p>
				<p className='text-base m-0 mb-2 overflow-ellipsis '>
					{' '}
					{ quantityDisplay }
				</p>
				<p className='text-sm m-0'>
					{/* {isPieceNotFound && ''}
					{isUnreleased && <p> Not released yet</p>} */}
					{saleStatus === 'ENDED' && <span >Mint has ended</span>}
					{saleStatus === 'ACTIVE' && <span className='text-[#B3FE00]'>Available for minting</span>}
					{saleStatus === 'NOT_STARTED' && <span className='text-[#B3FE00]'>Minting has not started</span>}
				</p>
			</div>
		</div>
	);
}

