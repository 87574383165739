import React, { useEffect, useRef, useState } from 'react';
import useWallet from 'src/hooks/useWallet';
import { Image as CustomImage } from 'src/ui/components';
import Image from 'next/image';
import BaseModalStyles from './modal.module.scss';
import WalletModalStyles from './WalletModal.module.scss';
import clsx from 'clsx';
import Link from 'next/link';
import { useStore } from 'base/state';
import walletTypeAtom from 'src/state/walletTypeAtom';
import addressAtom from 'src/state/addressAtom';
import { useRecoilState } from 'recoil';
import gsap from 'gsap';
import { SHOW_WALLET_CONNECT_BUTTON } from 'src/config';
import FeatureFlagGuardComponent from 'src/ui/util/FeatureFlagGuardComponent';
import { WALLET_TYPES } from 'src/base/constants';
// import {
// 	ConnectWallet, useAddress,
// } from '@thirdweb-dev/react';

import { ConnectButton, useActiveAccount } from 'thirdweb/react';
import { createWallet, inAppWallet } from 'thirdweb/wallets';
import { createThirdwebClient } from 'thirdweb';


const wallets = [
	createWallet( 'io.metamask' ),
	createWallet( 'com.coinbase.wallet' ),
	createWallet( 'me.rainbow' ),
];
const client = createThirdwebClient({ clientId: 'ff658125cec41fde327d139891f5045c' });

export default function WalletModal({ delegateCashOnClick, closeModal }) {
	const [connect] = useWallet();
	const api = useStore( s => s.api );
	const [isConnectWalletPopUpOpen, setIsConnectWalletOpen] = useState( false );
	const connectWalletRef = useRef();
	const [walletType, setWalletType] = useRecoilState( walletTypeAtom ); // metamaskAddress
	const [address, setAddress] = useRecoilState( addressAtom ); // metamaskAddress


	const account = useActiveAccount();


	useEffect( () => {
		if ( account && account.address ) {
			setWalletType( 'walletconnect' );
			setAddress( account?.address );
		}
	}, [account]);

	const walletOptions = [
		{
			image: {
				src: 'MetaMaskLarge.png',
				width: 52,
				height: 48,
			},
			header: 'METAMASK',
			onClick: async () => {
				// await setWalletType(walletTypes.METAMASK);
				api.setIsModalOpen( false );
				connect( closeModal, WALLET_TYPES.METAMASK );
			},
		},
		{
			image: {
				src: 'connectWalletBlue.png',
				width: 67,
				height: 42,
			},
			header: 'WALLET CONNECT',
			subHeader: 'COMING SOON',
			isProtected: true,
			onClick: async () => {
				connectWalletRef.current.firstElementChild.click();
				setTimeout( () => {
					setIsConnectWalletOpen( true );
				}, 500 );
			},
		},
		{
			image: {
				src: 'delegateCash.png',
				width: 42,
				height: 44,
			},
			header: 'DELEGATE .CASH',
			onClick: () => delegateCashOnClick(),
		},
	];



	const WalletOptionItem = ({
		image,
		header,
		subHeader,
		disabled,
		onClick,
		isDoubleClick,
	}) => {

		return (
			<>
				<div
					className={`${WalletModalStyles['wallet-modal-options-item']} ${disabled === true &&
						WalletModalStyles['wallet-modal-options-item-disabled']
					}`}
					onClick={onClick}
				>
					<CustomImage
						src={`/images/${image.src}`}
						width={image.width || 55}
						height={image.height || 45}
						className={WalletModalStyles['wallet-modal-options-item-icon']}
					/>
					<div className={WalletModalStyles['wallet-modal-options-item-text']}>
						<div
							className={clsx(
								'-f-family-burbank',
								WalletModalStyles['wallet-modal-options-item-text-header'],
							)}
						>
							{header}
						</div>
						{subHeader && disabled && (
							<div
								className={
									WalletModalStyles['wallet-modal-options-item-text-sub-header']
								}
							>
								{subHeader}
							</div>
						)}
					</div>
				</div>
			</>
		);
	};
	const WalletOptions = () =>
		walletOptions.map( ( walletOption, index ) => {
			if ( walletOption.isProtected ) {
				return <FeatureFlagGuardComponent address={address}
					featureFlag={SHOW_WALLET_CONNECT_BUTTON}
					children={<WalletOptionItem key={index}
						{...walletOption} />} />;

			}
			return <WalletOptionItem key={index}
				{...walletOption} />;
		});

	const WalletInfo = ({ vartiantClassName }) => {
		return (
			<div
				className={`${WalletModalStyles['wallet-modal-info']} ${vartiantClassName}`}
			>
				<p className={BaseModalStyles['modal-content-main-paragraph']}>
					We trust and recommend using
					<Link href="https://delegate.cash"> Delegate.cash</Link>
					{' '}
					for extra
					enhanced asset protection and piece of mind.
				</p>
				<button className={BaseModalStyles['modal-content-main-learn-more']}
					onClick={() => delegateCashOnClick()}>
					Learn more
				</button>
			</div>
		);
	};

	useEffect( () => {
		gsap.to( window, { duration: 1, scrollTo: { y: '#wallet-modal-container', offsetY: 200 }});
	}, []);

	return (
		<div id='wallet-modal-container'
			className={BaseModalStyles['modal-content-main-flex']}>
			<span ref={connectWalletRef}
				className={`${!isConnectWalletPopUpOpen ? 'opacity-0' : 'opacity-100'}`}>
				<ConnectButton
					client={client}
					wallets={wallets}
					appMetadata={{
						name: 'Rare Apepes',
						description: 'Life is a meme',
						logoUrl: 'https://imgtr.ee/images/2023/11/10/c1e5055f27886c50d372b8d0f44e2485.png',
						url: 'https://www.rareapepes.com/',
					}}
					detailsButton={{
						style: { background: 'transparent', color: 'transparent', position: 'absolute', opacity: '0' },
					}}
					connectButton={{
						style: { background: 'transparent', color: 'transparent', position: 'absolute', opacity: '0' },
					}} />
			</span>

			<div className={BaseModalStyles['modal-content-main-side']}>
				<h2
					className={clsx(
						'-f-family-burbank',
						BaseModalStyles['modal-content-main-header'],
						WalletModalStyles['wallet-modal-header'],
					)}
				>
					Log in
				</h2>
				{/* regular next image as other one was causing weird issues */}
				<div className={BaseModalStyles['modal-content-main-img']}>
					<Image
						src="/images/RareApepes_Delegatecash 3.png"
						width={108}
						height={91}
					/>
				</div>
				<WalletInfo
					vartiantClassName={WalletModalStyles['wallet-modal-info-desktop']}
				/>
			</div>
			<div
				className={`${BaseModalStyles['modal-content-main-side']} ${WalletModalStyles['wallet-selections']}`}
			>
				<span className={WalletModalStyles['wallet-modal-options-title']}>
					SUPPORTED WALLETS
				</span>
				<div className={BaseModalStyles['wallet-modal-options']}>
					<WalletOptions />
					<WalletInfo
						vartiantClassName={WalletModalStyles['wallet-modal-info-mobile']}
					/>
				</div>
			</div>
		</div >
	);
}
