// Dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// App
import { Image, Link } from 'ui/components';

//	Styles
import styles from './rare-labs.module.scss';


//
//	RAYC / UI / Sections / Rare Labs
//


export default function RareLabs({ className, id }) {
	const [ language, setLanguage ] = useState( 'en' );

	return (
		<section className={ clsx( styles[ 's-rarelabs' ], className ) }
			id={ id }>
			<div className={ styles[ 's-rarelabs-bg' ] }>
				<Image src="/images/collage-bg.webp"
					alt=""
					layout="fill"
					objectFit="cover" />
			</div>
			<div className={ clsx( 'section', styles[ 's-rarelabs-content' ]) }>
				<div className="grid -g-cols-1 -a-center -va-c">
					<div className={ styles[ 's-rarelabs-logo' ] }>
						<Image src="/images/rare-labs-logo.webp"
							width={ 450 }
							height={ 468 }
							alt="Rare Labs Logo" />
					</div>
					<h2 className="f1 -mb0 -f-caps -color-text-mid">Rare Labs</h2>
					<div className={ clsx( styles[ 's-rarelabs-title' ], '-ha-center' ) }>
						<div className={ clsx( styles[ 's-rarelabs-title_language' ], '-f-family-burbank -color-base' ) }>
							<button
								className={ clsx({ [ styles[ '-is-active' ] ]: language === 'en' }) }
								onClick={ () => setLanguage( 'en' ) }
							>
								EN
							</button>
							<button
								className={ clsx({ [ styles[ '-is-active' ] ]: language === 'es' }) }
								onClick={ () => setLanguage( 'es' ) }
							>
								ES
							</button>
						</div>
						<p className={ clsx( 'f3 -f-family-burbank -color-text-mid' ) }>
							Rare&nbsp;Designer
							<Link href="https://twitter.com/pepe_designer"
								external>
								{' '}
@pepe_designer
							</Link>
						</p>
					</div>
				</div>
				<div className="grid -g-cols-1">
					{ language === 'en' && (
						<div className={ clsx( styles[ 's-rarelabs_description'], '-mt2' ) }>
							<p className="-my0">Rare Labs is an agency dedicated to nft digital art design. is founded by the OG artist of the crypto community Rare Designer. This agency intends to offer the design / illustration service for clients who seek seriousness and commitment in the final product.</p>
							<p className="-mb0">Rare Designer is one of the original creators of art for Rare Pepe’s a cultural phenomenon which has seduced the crypto and NFT world at large.</p>
							<p className="-mb0">Rare Designer is an Artist born in Venezuela, dedicated to drawing from an early age, with 15 years of experience in this field. In 2017 from a very hard crisis in his country, under a famine, he decided to find ways to take advantage of his talent, however it was not until a foreigner of French origin decided to offer him an opportunity, but said payment would be in bitcoin. Not having many alternatives, this young artist accepted the proposal, becoming one of the early artists of the Rare Pepe project calling himself "Pepe Designer". Later he would participate in other projects based on crypto art, becoming a Rare Designer.</p>
						</div>
					)}
					{ language === 'es' && (
						<div className={ clsx( styles[ 's-rarelabs_description'], '-mt2' ) }>
							<p className="-my0">Rare Labs es una agencia dedicada al diseño de arte digital nft. es fundado por el artista OG de la comunidad criptográfica Rare Designer. Esta agencia pretende ofrecer el servicio de diseño/ilustración para clientes que buscan seriedad y compromiso en el producto final.</p>
							<p className="-mb0">Rare Designer es uno de los creadores originales de arte de Rare Pepe, un fenómeno cultural que ha seducido al mundo de las criptomonedas y las NFT en general.</p>
							<p className="-mb0">Rare Designer es un Artista nacido en Venezuela, dedicado al dibujo desde temprana edad, con 15 años de experiencia en este campo. En el 2017 a partir de una crisis muy dura en su país, bajo una hambruna, decidió buscar la forma de aprovechar su talento, sin embargo no fue hasta que un extranjero de origen Italiano decidió ofrecerle una oportunidad, pero dicho pago sería en bitcoin. Al no tener muchas alternativas, este joven artista aceptó la propuesta, convirtiéndose en uno de los primeros artistas del proyecto Rare Pepe llamándose a sí mismo "Pepe Designer". Posteriormente participaría en otros proyectos basados en el criptoarte, convirtiéndose en Rare Designer.</p>
						</div>
					)}
				</div>
			</div>
		</section>
	);
}

RareLabs.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
};
