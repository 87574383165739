export default function CarretRight( props ) {

	return <svg version="1.1"
		width="64px"
		height="64px"
		{...props}>
		<g>
			<path style={{ opacity: '0.874' }}
				fill="#ffffff"
				d="M 15.5,-0.5 C 17.5,-0.5 19.5,-0.5 21.5,-0.5C 30.7978,9.29838 40.2978,18.965 50,28.5C 50.6667,30.5 50.6667,32.5 50,34.5C 40.2978,44.035 30.7978,53.7016 21.5,63.5C 19.5,63.5 17.5,63.5 15.5,63.5C 12.8515,61.4414 12.0182,58.7747 13,55.5C 21.0145,47.6524 28.8479,39.6524 36.5,31.5C 28.8479,23.3476 21.0145,15.3476 13,7.5C 12.0182,4.2253 12.8515,1.55863 15.5,-0.5 Z" />
		</g>
	</svg>;
}
