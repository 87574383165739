import React, { useEffect, useRef } from 'react';
import styles from './MemeModal.module.scss';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { ActionButton } from 'src/ui/components';
import { gsap } from 'gsap';
import CrossIcon from 'src/icons/Cross';
import { toPng } from 'html-to-image';
import LoadingOverlay from 'src/ui/components/LoadingOverlay';

// artificial delay function
const delay = ( ms ) => new Promise( resolve => setTimeout( resolve, ms ) );

export default function MemeModal({ onClose, ModalMemeImageElement, memeDimensions }) {
	const memeImageRef = useRef();
	const [showLoadingOverlay, setShowLoadingOverlay] = React.useState( false );
	// lock body scroll when modal is enabled
	useLockBodyScroll();

	useEffect( () => {
		gsap.to( window, {
			duration: .5,
			scrollTo: { y: '#meme-modal-container', offsetY: 0 },
		});
	}, [ModalMemeImageElement]);

	const onDownlaodImage = () => {
		downloadElementAsImage();
	};
	const downloadElementAsImage = async () => {
		setShowLoadingOverlay( true );
		// canvas width and height will return image to its original dimensions
		let dataUrl;
		const imageSettings = { cacheBust: true, quality: 1,
			// canvasWidth: memeDimensions.width, canvasHeight: memeDimensions.height, // this line caused issues for ios
			skipFonts: true };
		try {
			dataUrl = await toPng( memeImageRef.current, imageSettings );
			let i = 0;
			while ( ( dataUrl.length < 100000 ) && ( i < 10 ) ) {
				dataUrl = await toPng( memeImageRef.current, imageSettings );
				i++;
			}
			const link = document.createElement( 'a' );
			link.download = 'Rare-Apepes-Generated-Meme.png';
			link.href = dataUrl;
			// Note: this is a hack to fix the issue of the buggy scroll behavior as some images were being downlowded quite fast and some others were not, so i set a base of 500ms which is enough to scroll to loading animation then actually download the image. That was the only way to provide decent UX otherwise it will look very buggy
			setTimeout( ()=>{
				link.click();
				setShowLoadingOverlay( false );
				onClose();
			}, 500 );
		} catch ( e ) {
			console.error( e );
		}
	};

	const Socials = [{
		imageSrc: '/Twitter.png',
		link: 'https://twitter.com/intent/tweet?text=I have created a cool meme using Rare Apepe Meme generator',
	}];


	return (
		<div className={styles['meme-modal-container']}
			id="meme-modal-container">
			<LoadingOverlay show={showLoadingOverlay}
				scrollDuration={.1}/>
			<div className={styles['meme-modal-overlay']}></div>
			<div className={styles['meme-modal-content']}>
				<h3 className={styles['meme-modal-content-title']}>
					Your Meme
					<CrossIcon onClick={onClose}
						className={styles['meme-modal-content-close-icon']} />
				</h3>
				<div className={styles['meme-modal-content-meme-image']}
					 ref={memeImageRef}>
					{ModalMemeImageElement && ModalMemeImageElement}
				</div>
				{/* Note: temporarily disabling this. Fix the modal height issue first */}
				{/* <div className={styles['meme-modal-content-socials']}>
					<span className={styles['meme-modal-content-socials-header']}>Share to </span>
					<div className={styles['meme-modal-content-socials-items']}>
						{Socials.map( ( item )=>{
							return <Link external
								href={item.link}> 
								{' '}
								<Image width={32}
									height={32}
									src={`/images/${item.imageSrc}`}
									className={styles['meme-modal-content-socials-item']} />
							</Link>;
						})}
					</div>
				</div> */}
				<div className={styles['meme-modal-content-buttons']}>
					<ActionButton className={styles['meme-modal-content-buttons-download']}
						onClick={onDownlaodImage}
						textContent="Download Meme" />
					<ActionButton className={styles['meme-modal-content-buttons-done']}
						onClick={onClose}
						textContent="Done" />
				</div>
			</div>
		</div>
	);
}
