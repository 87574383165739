import React from 'react';
import styles from './MemeCard.module.scss';

export default function MemeCard({ imageSrc, title, onClick, isSelected }) {
	return (
		<div className={`${styles['meme-card']} ${isSelected ? styles['meme-card-selected'] : ''}`}
			onClick={onClick}>
			<div className={styles['meme-card-image']}
				style={{ backgroundImage:`url(${imageSrc})` }} />
			<div className={styles['meme-card-text']}>{title}</div>
		</div>
	);
}
