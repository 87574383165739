import React from 'react';
import styles from './YourCollectionCard.module.scss';
import { Icon, Link, Ribbon } from 'src/ui/components';

// TODO: ERC1155 version

function parseCollectionName( collectionName ) {
	let result = collectionName;
	switch ( collectionName ) {
		case 'RareApepeYachtClub':
			result = 'Rare Apepes';
			break;
	}
	return result;
}

export default function YourCollectionCard( props ) {
	const { title, id, link, image, collectionName, balance, tokenType } = props;
	const isErcCard = tokenType === 'ERC1155' ? true : false;
	if ( !id ) console.warn( 'Please provide the id' );
	if ( !title ) console.warn( `${id} has no title` );
	if ( !link ) console.warn( `${id} has no link` );
	if ( !image ) console.warn( `${id} has no image` );

	return (
		<Link external
			href={link && link}>
			<div className={styles['your-collection-card']}
			>
				{isErcCard && <Ribbon ribbonValue={balance}
					ribbonLabel="owned"
					className={styles['your-collection-card-ribbon']}
					numberFontSize="20"
					wordFontSize="10"
					variatiaton="secondary" />}

				<div className={styles['your-collection-card-image-container']}
					style={{ backgroundImage:`url(${image})`, height:isErcCard ? '225px' : 'auto', backgroundSize:isErcCard ? 'cover' : 'contain' }}
				>
				 	{/* added to trick parent into having auto height and width */}
					{/* eslint-disable-next-line @next/next/no-img-element  */}
					<img src={image}/>
				</div>
				<div className={styles['your-collection-card-text-container']}>
					<span className={styles['your-collection-card-title']}>{parseCollectionName( collectionName )}</span>
					<span className={styles['your-collection-card-sub-title']}>{title}</span>
					<span
						className={`${'-f-family-burbank'} ${styles['your-collection-card-cta']}`}>
						<Icon className={styles['your-collection-card-cta-icon']}
							type="rarible" />
						View on Rarible
					</span>

				</div>
			</div>
		</Link>
	);
}
