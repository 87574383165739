import React from 'react';

interface CouncilCardProps {
	imageUrl: string;
	name: string;
	headline: string;
	bio: string | JSX.Element;
	onLearnMoreClick: () => void;
}

const CouncilCard: React.FC<CouncilCardProps> = ({ imageUrl, name, headline, bio, onLearnMoreClick }) => {
	return (
		<div className="max-w-sm overflow-hidden shadow-lg rounded-lg bg-[#313131] flex flex-col">
			<img className="w-full h-auto mx-auto rounded-lg" src={imageUrl} alt="Profile" />

			<div className="px-6 py-2 flex-grow">
				<div className="font-bold text-l my-4">{name} <br /> <span className='text-[12px]'>({headline})</span></div>
				<p className="text-white opacity-70 text-sm">{bio}</p>
			</div>

			<div className="px-6 py-4">
				<button
					onClick={onLearnMoreClick}
					className="bg-transparent uppercase text-[#b3fe00] font-bold px-[10px] py-[4px] rounded-[31px] text-center text-[12px] border-solid border border-green-300"
				>
					Twitter
				</button>
			</div>
		</div>
	);
};

export default CouncilCard;
